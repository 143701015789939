import { FC } from 'react'
// Components
import { CustomModal } from 'components/molecules/Modal/Modal'

// Types
import { AddMissingFieldsModalProps } from './AddMissingFieldsModal.types'

// Logic
import AddMissingFieldsModalLogic from './AddMissingFieldsModal.logic'

// Style
import './AddMissingFieldsModal.scss'

const AddMissingFieldsModal: FC<AddMissingFieldsModalProps> = (props) => {
  const { fields, open, onClose } = props
  const { renderField, isLoading, handleOnSubmit } =
    AddMissingFieldsModalLogic(props)

  return (
    <CustomModal
      open={open}
      header="Add Missing Information"
      isLoadingState={isLoading}
      secondaryButtonAction={() => onClose?.({}, 'backdropClick')}
      handleOnSubmit={handleOnSubmit}
      icon={null}
    >
      <form onSubmit={handleOnSubmit} className="add-missing-fields-container">
        {fields?.map((field) => renderField(field))}
      </form>
    </CustomModal>
  )
}

export default AddMissingFieldsModal
