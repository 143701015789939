import { CustomModal } from 'components/molecules/Modal/Modal'
import { FC } from 'react'
import { TSelectOldExplantsModalProps } from './SelectOldExplantsModal.types'
import { UndocumentedScanCard } from 'components/UndocumentedScanCard'
import { useSelectOldImplantsModalLogic } from './SelectOldExplantsModal.logic'
import ToastProvider from 'common/ToastProvider'

export const SelectOldExplantsModal: FC<TSelectOldExplantsModalProps> = (
  props
) => {
  const { open, isLoading } = props
  const {
    oldImplants,
    areAllSelected,
    primaryButtonText,
    isLoadingUndocumentedList,
    cardStyles,
    handleClose,
    handleSubmit,
    handleSelectAll,
    handleUndocumentedScanCardClick,
  } = useSelectOldImplantsModalLogic(props)

  return (
    <ToastProvider>
      <CustomModal
        open={open}
        isLoadingState={isLoading}
        handleOnSubmit={handleSubmit}
        secondaryButtonAction={handleClose}
        primaryButtonText={primaryButtonText}
        primaryButtonDisabled={isLoadingUndocumentedList}
        secondaryButtonDisabled={isLoadingUndocumentedList}
        header="Implant Record"
        withSelectAll
        onSelectAll={handleSelectAll}
        areAllSelected={areAllSelected}
      >
        {oldImplants.map((oldImplant, index) => {
          return (
            <UndocumentedScanCard
              scan={oldImplant}
              key={oldImplant._id}
              cardStyles={cardStyles(index)}
              implantSite={oldImplant.implantSite}
              siteLocation={oldImplant.siteLocation}
              dataTestId={`${oldImplant._id}-select-auto-explant`}
              onClick={handleUndocumentedScanCardClick(index)}
              isSelected={oldImplants[index].isSelected}
            />
          )
        })}
      </CustomModal>
    </ToastProvider>
  )
}
