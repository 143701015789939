import { useEffect, useMemo, useState, useRef, FC, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  FormControl,
  Paper,
  Typography,
  LinearProgress,
} from '@mui/material'
import { ArrowBack, Check, QrCode, Search } from '@mui/icons-material'

import { ConsumableModal } from '../../../components/Consumables/ConsumableModal'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import SelectInput from 'components/SelectInput/SelectInput'
import { AssetDetailsCard } from '../AssetDetailsCard/AssetDetailsCard'
import TextInput from 'components/molecules/TextInput/TextInput'

import { useUser } from 'app/User'
import { useCortexDecoderContext } from 'lib/cortex'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { BarcodeIcon } from 'assets/icons/BarcodeIcon'
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { addToAssetListValidationSchema } from './form/addToAssetListValidationSchema'
import { getEnumKeyByKey } from 'lib/utils/enumUtils'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { getAssetTypeCaps } from 'lib/utils/getAssetType'

import {
  AssetGoodConditionCheck,
  ManualInputFormData,
  NewAsset,
  Option,
  SamplePreparationType,
} from 'common/types'
import styles from './result.module.css'
import {
  ASSET_TYPES,
  DISPOSITIONS,
  EXPLANTED_REASON_LIST,
} from 'common/disposition'
import MediaUpload from 'components/organisms/MediaUpload/MediaUpload'
import { z } from 'zod'
import { getZodError } from 'common/utils'
import {
  finalDispositionCaster,
  samplePreparationCaster,
  wastedReasonCaster,
  explantedReasonCaster,
  assetTypes,
  samplePreparationTypeOptions,
  wastedReasons,
} from './constants'
import { IResult } from './Result.types'

const Result: FC<IResult> = ({
  addSurgeryAssetScanMutation,
  assetFormData,
  handleAddToAssetList,
  handleChangeAssetType,
  handleChangeTray,
  handleChangeSerialNumber,
  createDispositionsChangeHandler,
  resetAssetFormData,
  assetGroups,
  surgery,
  setUserCanScan,
  scanDetails: nonStateScanDetails,
  mainIconFlowState,
  onMediaFilesChange,
}) => {
  const navigate = useNavigate()
  const { isRep } = useUser()
  const { currentScan, setCurrentScan, trayScannerState, isActive } =
    useCortexDecoderContext()
  const { implantSites } = useImplantSites()

  const [consumableModalOpen, setConsumableModalOpen] = useState(false)
  const [multipackItemsUsed, setMultipackItemsUsed] = useState(0)
  const [isAssetAdded, setIsAssetAdded] = useState(false)
  const [validationErrors, setValidationErrors] = useState<Record<
    string,
    string
  > | null>(null)

  const inputRefs = useRef<{ [key: string]: HTMLElement | null }>({})
  const prevScanRef = useRef(currentScan)

  const state = useLocation().state as
    | { selectedMultipackScan: ManualInputFormData; selectedScanId: string[] }
    | undefined
  const selectedMultipackScan = { selectedResult: state?.selectedMultipackScan }
  const selectedScanId = state?.selectedScanId

  const implantSiteList = implantSites.map((implantSite) => implantSite.name)
  const { selectedResult } = nonStateScanDetails.selectedResult
    ? nonStateScanDetails
    : selectedMultipackScan

  const result = (
    nonStateScanDetails.selectedResult
      ? nonStateScanDetails
      : selectedMultipackScan
  ).selectedResult as ManualInputFormData

  const isManualAddition = result?.isManualAddition
  const isDisposition = selectedMultipackScan.selectedResult ? true : false

  const {
    deviceDescription,
    deviceId,
    deviceCount,
    sizeText,
    expirationDate,
    secondaryDeviceIdType,
    lotBatch,
    catalogNumber,
    versionModelNumber,
    serialNumber,
    companyName: manualCompanyName,
    parentCompany,
  } = (selectedResult as ManualInputFormData) ?? {
    deviceCount: 1,
    expirationDate: null,
  }
  const companyName =
    (selectedResult as NewAsset)?.company?.name || manualCompanyName
  const groupId =
    selectedResult && 'id' in selectedResult ? selectedResult.id : undefined

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackNonConsumable,
  } = parseAssetIdentifiers({
    deviceDescription,
    deviceCount,
    idType: secondaryDeviceIdType,
    assetType: assetFormData.assetType || '',
  })

  const matchedAssetGroup = useMemo(
    () => assetGroups.find((group) => group.bidAssetId === groupId),
    [assetGroups, groupId]
  )

  // BEGIN form validation / alerts
  const {
    isFormSubmitted,
    handleValidation,
    FormValidationAlertToasters,
    isFormValidated,
  } = useFormValidation({
    validationSchema: addToAssetListValidationSchema,
  })

  const isAssetAddedToList =
    addSurgeryAssetScanMutation.data && !addSurgeryAssetScanMutation.error

  const isAssetFieldDisabled =
    addSurgeryAssetScanMutation.loading ||
    addSurgeryAssetScanMutation.data !== undefined

  const isSiteLocationEnabled = (index: number) =>
    (assetFormData.dispositions[index]?.finalDisposition === 'IMPLANTED' ||
      assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED') &&
    (assetFormData.dispositions[index]?.implantSite === 'Other' ||
      assetFormData.dispositions[index]?.implantSite === 'Mouth')

  const isManualAndExplanted = (index: number) =>
    assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED' &&
    isManualAddition

  const isExplanted = (index: number) =>
    assetFormData.dispositions[index]?.finalDisposition === 'EXPLANTED'

  // Final disposition logic
  const finalDisposition = [
    { id: 'IMPLANTED', name: 'Implanted' },
    ...(assetFormData.assetType === 'non-biological'
      ? [{ id: 'TEMPORARY_IN_OUT', name: 'Temporary In/Out' }]
      : []),
    { id: 'WASTED', name: 'Wasted' },
  ]

  if (
    assetFormData.assetType === 'non-biological' &&
    !isMultipackNonConsumable
  ) {
    finalDisposition.splice(1, 0, {
      id: 'ASSOCIATED_ASSET',
      name: 'Utilized associated product',
    })
  }

  if (isMultipackNonConsumable) {
    finalDisposition.push({
      id: 'UNUSED',
      name: 'Unused Product',
    })
  }

  if (assetFormData.assetType === 'non-biological') {
    finalDisposition.push({
      id: 'NOT_PRESENT',
      name: 'Not Present',
    })
  }

  // Handlers
  const assetFormDataValidationSchema = z
    .object({
      assetData: z.enum(ASSET_TYPES).optional(),
      finalDisposition: z.enum(DISPOSITIONS).optional(),
      implantSite: z.string().optional(),
      siteLocation: z.string().optional(),
      samplePreparation: z.string().optional(),
      samplePreparationNote: z.string().optional(),
      wastedReason: z.string().optional(),
      explantedReason: z.string().optional(),
      explantedReasonNote: z.string().optional(),
      serialNumber: z.string().optional(),
    })
    .superRefine((data, ctx) => {
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'EXPLANTED' &&
        !data.explantedReason &&
        isManualAddition
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explanted reason cannot be empty',
          path: ['explantedReason'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'EXPLANTED' &&
        data.explantedReason === 'OTHER' &&
        !data.explantedReasonNote
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Other reason cannot be empty',
          path: ['explantedReasonNote'],
        })
      }
      if (!data.finalDisposition) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Final Disposition cannot be empty',
          path: ['finalDisposition'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'IMPLANTED' &&
        !data.samplePreparation
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Sample preparation cannot be empty',
          path: ['samplePreparation'],
        })
      }
      if (
        data.finalDisposition === 'IMPLANTED' ||
        data.finalDisposition === 'EXPLANTED'
      ) {
        if (!data.implantSite) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              data.finalDisposition === 'IMPLANTED'
                ? 'Implant site cannot be empty'
                : 'Explant site cannot be empty',
            path: ['implantSite'],
          })
        } else if (
          (data.implantSite === 'Other' || data.implantSite === 'Mouth') &&
          !data.siteLocation
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              data.finalDisposition === 'IMPLANTED'
                ? 'Implant location cannot be empty'
                : 'Explant location cannot be empty',
            path: ['siteLocation'],
          })
        }
      }
      if (data.finalDisposition === 'WASTED' && !data.wastedReason) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Wasted Reason cannot be empty',
          path: ['wastedReason'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.samplePreparation === 'OTHER' &&
        !data.samplePreparationNote
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Sample preparation note cannot be empty',
          path: ['samplePreparationNote'],
        })
      }
      if (
        assetFormData.assetType === 'biological' &&
        data.finalDisposition === 'IMPLANTED' &&
        !assetFormData.serialNumber
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Serial Number cannot be empty',
          path: ['serialNumber'],
        })
      }
    })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      Object.keys(assetFormData.dispositions).length <= 1 ||
      !assetFormData.dispositions
    ) {
      if (assetFormData.assetType !== 'consumable') {
        if (!assetFormData.dispositions[0]) {
          setValidationErrors({
            finalDisposition: 'Disposition cannot be empty',
          })
          return
        } else {
          const result = assetFormDataValidationSchema.safeParse(
            assetFormData.dispositions[0]
          )
          if (!result.success) {
            setValidationErrors(getZodError(result.error))
            return
          }
        }
      }
    }

    handleValidation(e)
    if (isFormValidated) {
      handleAddToAssetList({
        selectedResultParam: selectedResult as ManualInputFormData,
        isDisposition,
      }).then((response) => {
        if (isDisposition && response === true)
          navigate('../batch/undocumented', { state: { selectedScanId } })
      })
    }
  }

  const handleConsumableModalClose = () => {
    setConsumableModalOpen(false)
    createDispositionsChangeHandler(0)({
      target: {
        name: 'consumableCount',
        value: multipackItemsUsed,
      },
    })
  }

  const getAutoFocusForDisposition = useCallback(
    (index: number) => {
      const autoFocus = {
        implantSite: false,
        wastedReason: false,
        implantedReason: false,
        implantedReasonNote: false,
        explantedReason: false,
        siteLocation: false,
        samplePreparation: false,
        finalDisposition: false,
        serialNumber: false,
        samplePreparationNote: false,
      }

      const disposition = assetFormData.dispositions[index]

      // Check if all required fields are filled for this disposition
      const areAllFieldsFilled = () => {
        if (!disposition?.finalDisposition) return false

        switch (disposition.finalDisposition) {
          case 'WASTED':
            return Boolean(disposition.wastedReason)
          case 'EXPLANTED':
            if (isManualAddition) {
              return (
                Boolean(disposition.implantSite) &&
                Boolean(disposition.explantedReason) &&
                (disposition.explantedReason !== 'OTHER' ||
                  Boolean(disposition.explantedReasonNote))
              )
            }
            return Boolean(disposition.implantSite)
          case 'IMPLANTED':
            const biologicalFields =
              assetFormData.assetType === 'biological'
                ? Boolean(disposition.samplePreparation) &&
                  (disposition.samplePreparation !==
                    SamplePreparationType.OTHER ||
                    Boolean(disposition.samplePreparationNote)) &&
                  Boolean(assetFormData.serialNumber)
                : true
            return Boolean(disposition.implantSite) && biologicalFields
          default:
            return true
        }
      }

      // If all fields are filled, don't set any autofocus
      if (areAllFieldsFilled()) {
        return autoFocus
      }

      // Original autofocus logic
      if (!disposition?.finalDisposition) {
        autoFocus.finalDisposition = true
      } else if (
        disposition.finalDisposition === 'WASTED' &&
        !disposition.wastedReason
      ) {
        autoFocus.wastedReason = true
      } else if (
        (disposition.finalDisposition === 'EXPLANTED' ||
          disposition.finalDisposition === 'IMPLANTED') &&
        !disposition.implantSite
      ) {
        autoFocus.implantSite = true
      } else if (
        (disposition.finalDisposition === 'IMPLANTED' ||
          disposition.finalDisposition === 'EXPLANTED') &&
        (disposition.implantSite === 'Mouth' ||
          disposition.implantSite === 'Other') &&
        !disposition.siteLocation
      ) {
        autoFocus.siteLocation = true
        autoFocus.samplePreparation = false
        autoFocus.samplePreparationNote = false
        autoFocus.serialNumber = false
        autoFocus.implantSite = false
      } else if (
        (disposition.finalDisposition === 'IMPLANTED' ||
          disposition.finalDisposition === 'EXPLANTED') &&
        (disposition.implantSite === 'Mouth' ||
          disposition.implantSite === 'Other') &&
        disposition.siteLocation &&
        disposition.samplePreparation === SamplePreparationType.OTHER &&
        !disposition.samplePreparationNote
      ) {
        autoFocus.samplePreparationNote = true
        autoFocus.samplePreparation = false
        autoFocus.siteLocation = false
        autoFocus.serialNumber = false
      } else if (
        disposition.finalDisposition === 'IMPLANTED' &&
        disposition.implantSite &&
        disposition.samplePreparation &&
        !disposition.serialNumber
      ) {
        autoFocus.serialNumber = true
      } else if (
        disposition.finalDisposition === 'IMPLANTED' &&
        (disposition.implantSite === 'Mouth' ||
          disposition.implantSite === 'Other') &&
        disposition.siteLocation &&
        disposition.samplePreparation &&
        disposition.samplePreparation !== SamplePreparationType.OTHER &&
        !disposition.serialNumber
      ) {
        autoFocus.serialNumber = true
      } else if (
        disposition.finalDisposition === 'IMPLANTED' &&
        disposition.implantSite &&
        !(
          disposition.implantSite === 'Mouth' ||
          disposition.implantSite === 'Other'
        ) &&
        !disposition.samplePreparation
      ) {
        autoFocus.samplePreparation = true
      } else if (
        disposition.finalDisposition === 'EXPLANTED' &&
        !disposition.explantedReason &&
        isManualAddition
      ) {
        autoFocus.explantedReason = true
        autoFocus.implantedReason = false
        autoFocus.implantedReasonNote = false
        autoFocus.wastedReason = false
        autoFocus.siteLocation = false
        autoFocus.implantSite = false
      }

      const fieldToFocus = Object.entries(autoFocus).find(
        ([_, shouldFocus]) => shouldFocus
      )?.[0]

      if (fieldToFocus) {
        const refKey = `${fieldToFocus}-${index}`
        const element = inputRefs.current[refKey]
        if (element) {
          element.focus()
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
      return autoFocus
    },
    [
      assetFormData.dispositions,
      assetFormData.assetType,
      assetFormData.serialNumber,
      isManualAddition,
    ]
  )

  // BEGIN Tray Scanner Logic
  const maybeSetTrayScanner = trayScannerState?.[1]

  // TODO Temporary solution for ticket MGAT-734
  // The problem is that after successful scanning of an implantable biologic, the user is
  // redirected to this component /result.
  // When clicking back, the user is going back to /scan, then again to /result,
  // but this time without the scanDetails, so the user sees the component with undefined values.
  // The temp solution is to take the user back to /scan if scanDetails.isComplete is false

  // if (
  //   !scanDetails.selectedResult &&
  //   searchParams.get('src') !== 'manual-asset-search'
  // ) {
  //   navigate('../scan')
  // }

  // TODO
  // I had to use type any here to resolve the error type.
  // Will look into that later.
  const isTrayScannerOpen: any = trayScannerState?.[0] ?? false
  const setIsTrayScannerOpen: any = useMemo(
    () => maybeSetTrayScanner ?? (() => {}),
    [maybeSetTrayScanner]
  )

  useEffect(() => {
    setValidationErrors(null)
  }, [assetFormData])

  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isAssetAdded) {
      handleAddToAssetList({
        selectedResultParam: selectedResult as ManualInputFormData,
        isDisposition,
      }).then((response) => {
        if (isDisposition && response === true)
          navigate('../batch/undocumented', { state: { selectedScanId } })
      })
      setIsAssetAdded(true)
    }
  }, [
    isFormSubmitted,
    handleAddToAssetList,
    isFormValidated,
    isAssetAdded,
    selectedResult,
    isDisposition,
    navigate,
    selectedScanId,
  ])

  // Update asset type field based upon mainIconFlowState
  useEffect(() => {
    const assetType = getAssetTypeCaps(mainIconFlowState.assetTypeLabel)
    const event = {
      target: {
        name: 'assetType',
        value: '',
      },
    }

    event.target.value = assetType

    handleChangeAssetType(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // leave empty to only run once

  useEffect(() => {
    setCurrentScan(undefined)
    return () => {
      setIsTrayScannerOpen(false)
      setUserCanScan(false)
      setCurrentScan(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsTrayScannerOpen, setCurrentScan])

  useEffect(() => {
    if (
      currentScan !== undefined &&
      prevScanRef.current?.barcodeData !== currentScan?.barcodeData
    ) {
      if (isTrayScannerOpen === 'tray') {
        handleChangeTray({
          target: {
            value: currentScan?.barcodeData,
          },
        })
        setIsTrayScannerOpen(false)
        setUserCanScan(false)
        setCurrentScan(undefined)
      } else if (isTrayScannerOpen === 'serial') {
        handleChangeSerialNumber({
          target: {
            value: currentScan?.barcodeData,
          },
        })
        setIsTrayScannerOpen(false)
        setUserCanScan(false)
        setCurrentScan(undefined)
      }
    }
    if (!prevScanRef.current && currentScan) {
      prevScanRef.current = currentScan
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentScan,
    isTrayScannerOpen,
    setIsTrayScannerOpen,
    handleChangeTray,
    handleChangeSerialNumber,
    setCurrentScan,
  ])

  useEffect(() => {
    return () => {
      resetAssetFormData()
      addSurgeryAssetScanMutation.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (surgery.activeAssetTray) {
      handleChangeTray({
        target: {
          name: 'tray',
          value: surgery.activeAssetTray,
        },
      })
    }
  }, [surgery.activeAssetTray, handleChangeTray])

  useEffect(() => {
    if (assetFormData.assetType !== 'non-biological' && isRep) {
      handleChangeAssetType({
        target: {
          name: 'assetType',
          value: 'non-biological',
        },
      })
    }
    if (
      !isRep &&
      matchedAssetGroup &&
      matchedAssetGroup?.scans[0]?.assetType &&
      (!assetFormData.assetType ||
        assetFormData.assetType !== matchedAssetGroup?.scans[0]?.assetType)
    ) {
      handleChangeAssetType({
        target: {
          name: 'assetType',
          value: matchedAssetGroup.scans[0].assetType,
        },
      })
    }
  }, [assetFormData, handleChangeAssetType, matchedAssetGroup, isRep])

  useEffect(() => {
    if (assetFormData.assetType === 'consumable') {
      const handleConsumableChange = () => {
        resetAssetFormData()
        handleChangeAssetType({
          target: {
            name: 'assetType',
            value: 'consumable',
          },
        })
        createDispositionsChangeHandler(0)({
          target: {
            name: 'consumableCount',
            value: 1,
          },
        })
      }

      if (
        isMultipackConsumable &&
        !isRfMultipackConsumable &&
        !isSpongeConsumable
      ) {
        setConsumableModalOpen(true)
        handleConsumableChange()
      } else if (
        isRfMultipackConsumable ||
        assetFormData.assetType === 'consumable' ||
        isSpongeConsumable
      ) {
        handleConsumableChange()
      }
    }
    if (deviceCount > 1 && assetFormData.assetType !== 'consumable') {
      Array.from({ length: deviceCount }).forEach((_, i) => {
        createDispositionsChangeHandler(i)({
          target: {
            name: 'finalDisposition',
            value: 'UNUSED',
          },
        })
      })
    }
  }, [
    deviceCount,
    createDispositionsChangeHandler,
    assetFormData.assetType,
    isMultipackConsumable,
    isRfMultipackConsumable,
    resetAssetFormData,
    handleChangeAssetType,
    isSpongeConsumable,
  ])

  if (isTrayScannerOpen) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        {!isActive && (
          <Box
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            position="absolute"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="h3"
                color="grey.800"
                my={6}
                letterSpacing={1}
                fontWeight={500}
                fontSize={18}
              >
                {`Preparing ${'Tray'} Smart Vision`}
              </Typography>
              <LinearProgress />
            </Box>
          </Box>
        )}
        <Button
          sx={{ bottom: '72px', position: 'fixed', zIndex: 999999999999999 }}
          variant="contained"
          onClick={() => {
            setIsTrayScannerOpen(false)
            setUserCanScan(false)
          }}
          disabled={!isActive}
        >
          Close Image Capture
        </Button>
      </div>
    )
  }

  const renderSiteLocation = (index: number) =>
    isSiteLocationEnabled(index) && (
      <TextInput
        autoFocus={getAutoFocusForDisposition(index).siteLocation}
        ref={(el) => (inputRefs.current[`siteLocation-${index}`] = el)}
        focused={getAutoFocusForDisposition(index).siteLocation}
        sx={{ mt: 0, mb: 1 }}
        id={`implant-site-${index}`}
        label={
          isExplanted(index) && 'IMPLANTED'
            ? 'Explant Location*'
            : 'Implant Location*'
        }
        name={`siteLocation-${index}`}
        disabled={isAssetFieldDisabled}
        value={assetFormData.dispositions[index].siteLocation}
        placeholder={
          isExplanted(index) && 'IMPLANTED'
            ? 'Explant Location*'
            : 'Implant Location*'
        }
        inputMode="text"
        handleChangeFormData={(e) => {
          const { name, value } = e.target
          const parsedName = name.split('-').shift()
          if (parsedName) {
            if (assetFormData.dispositions[index]?.implantSite === 'Mouth') {
              if (parsedName === 'siteLocation' && value.length <= 4) {
                createDispositionsChangeHandler(index)(e)
              }
            } else {
              createDispositionsChangeHandler(index)(e)
            }
          }
        }}
        errorMessage={validationErrors?.siteLocation}
        shrink={true}
      />
    )

  const renderExplantedReasonNote = (index: number) =>
    assetFormData.dispositions[index]?.explantedReason === 'OTHER' && (
      <TextInput
        id={`explanted-reason-note-${index}`}
        label="Other Reason*"
        name={`explantedReasonNote-${index}`}
        disabled={isAssetFieldDisabled}
        value={assetFormData.dispositions[index].explantedReasonNote}
        placeholder="Other Reason"
        inputMode="text"
        handleChangeFormData={createDispositionsChangeHandler(index)}
        errorMessage={validationErrors?.explantedReasonNote}
        shrink
        ref={(el) => (inputRefs.current[`explantedReasonNote-${index}`] = el)}
      />
    )

  return (
    <>
      <Helmet>
        <title>Result</title>
      </Helmet>

      {FormValidationAlertToasters()}

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        px={1.5}
        pt={1.5}
        mb="56px"
        // sx={{
        //   overflowX: 'hidden',
        //   overflowY: 'auto',
        //   height: '100vh',
        // }}
      >
        <Box>
          <Button
            sx={{ mb: 2, width: 'fit' }}
            startIcon={<ArrowBack />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'space-between',
          }}
        >
          {/* hidden field(s) necessary for form schema validation */}
          <input
            type="hidden"
            name="expirationDate"
            value={expirationDate ?? ''}
          />
          <Box>
            <Typography variant="h1">
              {isManualAddition ? 'Add product manually' : 'Captured product'}
            </Typography>

            <Paper className={styles.assetFound} sx={{ my: 2 }} elevation={0}>
              {true && (
                <AssetDetailsCard
                  startComponent={
                    <Box className={styles.icon} sx={{ mr: 2 }}>
                      {isManualAddition ? (
                        <Search fontSize="large" />
                      ) : (
                        <QrCode fontSize="large" />
                      )}
                    </Box>
                  }
                  assetDetails={{
                    assetTitle: {
                      value: deviceDescription,
                    },
                    assetSizeText: {
                      value: sizeText,
                    },
                    assetTypeLabel: {
                      value: getAssetTypeLabel(
                        getAssetTypeCaps(mainIconFlowState.assetTypeLabel)
                      ),
                    },
                    assetManufacturer: {
                      value: parentCompany
                        ? `${companyName} (${parentCompany})`
                        : companyName || '',
                    },
                    deviceId: {
                      value: deviceId,
                    },
                    assetTrayId: {
                      value: assetFormData.assetTray,
                    },
                    modelNumber: {
                      value: versionModelNumber,
                    },
                    catalogNumber: {
                      value: catalogNumber,
                    },
                    lotNumber: {
                      value: lotBatch,
                    },
                    serialNumber: {
                      value: assetFormData.serialNumber ?? serialNumber,
                    },
                    expirationDate: {
                      value: expirationDate,
                    },
                    deviceCount: {
                      value: deviceCount,
                    },
                    usedCount: {
                      value: null,
                    },
                  }}
                  isExpireAccepted={false}
                  assetType={getAssetTypeCaps(mainIconFlowState.assetTypeLabel)}
                />
              )}
            </Paper>

            <FormControl fullWidth>
              <Box display="flex" alignItems="center" gap={2}>
                <Box flexGrow="1">
                  <AutocompleteInput
                    id={'tray'}
                    label={'Inventory Location'}
                    name={'tray'}
                    placeholder="None"
                    value={assetFormData.assetTray ?? ''}
                    handleChangeFormData={handleChangeTray}
                    onTextInputChange={handleChangeTray}
                    options={surgery.assetTrays.filter(Boolean)}
                    disabled={isAssetFieldDisabled}
                    autoSelect={false}
                    autoHighlight={false}
                  />
                </Box>
                <Button
                  sx={{
                    mt: 1.5,
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={isAssetAddedToList}
                  onClick={() => {
                    setIsTrayScannerOpen('tray')
                    setUserCanScan(true)
                  }}
                  className="barcode-button"
                >
                  <BarcodeIcon
                    active
                    color={
                      !isAssetAddedToList
                        ? 'var(--primary)'
                        : 'var(--grayscale)'
                    }
                  />
                </Button>
              </Box>
            </FormControl>
            <FormControl fullWidth>
              <SelectInput
                id="asset-type"
                label="Product type"
                name="assetType"
                disabled={
                  isAssetFieldDisabled ||
                  matchedAssetGroup !== undefined ||
                  isRep
                }
                value={assetFormData?.assetType as string}
                handleChangeFormData={handleChangeAssetType}
                options={assetTypes}
              />
            </FormControl>
            {assetFormData.assetType
              ? Array.from({
                  length: isMultipackNonConsumable ? deviceCount : 1,
                }).map((_, index) => {
                  const disposition =
                    assetFormData.dispositions[index]?.finalDisposition || ''
                  const siteLocationRenderConditions = [
                    'EXPLANTED',
                    'IMPLANTED',
                  ]

                  return (
                    <Box
                      key={`disposition-${index}`}
                      sx={
                        isMultipackNonConsumable
                          ? {
                              mt: 2,
                              pl: 1.5,
                              mb: 4,
                              borderLeft: '2px solid #eee',
                            }
                          : {
                              mt: 0.5,
                            }
                      }
                    >
                      {isMultipackNonConsumable ? (
                        <Typography variant="h5" color="primary" mb={1}>
                          Disposition #
                          {isMultipackNonConsumable ? index + 1 : ''} of{' '}
                          {deviceCount}
                        </Typography>
                      ) : null}

                      {assetFormData.assetType === 'consumable' && (
                        <FormControl fullWidth>
                          <ConsumableModal
                            consumableModalOpen={consumableModalOpen}
                            deviceCount={deviceCount}
                            handleConsumableModalClose={
                              handleConsumableModalClose
                            }
                            index={index}
                            setConsumableModalOpen={setConsumableModalOpen}
                            createDispositionsChangeHandler={
                              createDispositionsChangeHandler
                            }
                            isAssetFieldDisabled={isAssetFieldDisabled}
                            assetFormData={assetFormData}
                            multipackItemsUsed={multipackItemsUsed}
                            handleSetMultipackItemsUsed={setMultipackItemsUsed}
                            isRfMultipackConsumable={isRfMultipackConsumable}
                            isSpongeConsumable={isSpongeConsumable}
                            isLoading={addSurgeryAssetScanMutation.loading}
                          />
                        </FormControl>
                      )}

                      {assetFormData.assetType !== 'consumable' && (
                        <FormControl fullWidth>
                          {assetFormData.assetType && (
                            <AutocompleteInput
                              autoFocus={
                                getAutoFocusForDisposition(index)
                                  .finalDisposition
                              }
                              id={`final-disposition-${index}`}
                              label="Final disposition*"
                              name={`finalDisposition-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={finalDispositionCaster(
                                assetFormData.dispositions[index]
                                  ?.finalDisposition as string
                              )}
                              handleChangeFormData={(e) => {
                                createDispositionsChangeHandler(index)({
                                  target: {
                                    name: `finalDisposition-${index}`,
                                    value: (e.target.value as Option).id,
                                  },
                                })
                              }}
                              options={finalDisposition.map((item) => ({
                                id: item.id,
                                name: item.name,
                              }))}
                              sx={{ my: 1 }}
                              errorMessage={validationErrors?.finalDisposition}
                              ref={(el) =>
                                (inputRefs.current[
                                  `finalDisposition-${index}`
                                ] = el)
                              }
                            />
                          )}
                          {disposition === 'IMPLANTED' && (
                            <AutocompleteInput
                              autoFocus={
                                getAutoFocusForDisposition(index).implantSite
                              }
                              id={`implant-site-${index}`}
                              label="Implant site*"
                              name={`implantSite-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.implantSite as string
                              }
                              handleChangeFormData={(e) => {
                                createDispositionsChangeHandler(index)({
                                  target: {
                                    name: `implantSite-${index}`,
                                    value: (e.target.value as Option).id,
                                  },
                                })
                              }}
                              options={implantSiteList.map((item) => ({
                                id: item,
                                name: item,
                              }))}
                              errorMessage={validationErrors?.implantSite}
                              ref={(el) =>
                                (inputRefs.current[`implantSite-${index}`] = el)
                              }
                            />
                          )}
                          {disposition === 'EXPLANTED' && (
                            <AutocompleteInput
                              autoFocus={
                                getAutoFocusForDisposition(index).implantSite
                              }
                              autoSelect={false}
                              id={`implant-site-${index}`}
                              label="Explant site*"
                              name={`implantSite-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={
                                assetFormData.dispositions[index]
                                  ?.implantSite as string
                              }
                              handleChangeFormData={(e) => {
                                createDispositionsChangeHandler(index)({
                                  target: {
                                    name: `implantSite-${index}`,
                                    value: (e.target.value as Option).id,
                                  },
                                })
                              }}
                              options={implantSiteList.map((item) => ({
                                id: item,
                                name: item,
                              }))}
                              errorMessage={validationErrors?.implantSite}
                              ref={(el) =>
                                (inputRefs.current[`implantSite-${index}`] = el)
                              }
                            />
                          )}
                          {siteLocationRenderConditions.includes(disposition) &&
                            renderSiteLocation(index)}
                          {disposition === 'IMPLANTED' &&
                            assetFormData.assetType === 'biological' && (
                              <>
                                <AutocompleteInput
                                  id={`packaging-ok-${index}`}
                                  label="Packaging Intact"
                                  name={`packagingOk-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    (assetFormData.dispositions[index]
                                      ?.packagingOk as AssetGoodConditionCheck) ??
                                    getEnumKeyByKey(
                                      'NA',
                                      Object.entries(AssetGoodConditionCheck)
                                    )
                                  }
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  options={Object.entries(
                                    AssetGoodConditionCheck
                                  ).map(([key]) => key)}
                                  sx={{ my: 1 }}
                                />

                                <AutocompleteInput
                                  id={`temperature-ok-${index}`}
                                  label="Temperature Ok"
                                  name={`temperatureOk-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    (assetFormData.dispositions[index]
                                      ?.temperatureOk as AssetGoodConditionCheck) ??
                                    getEnumKeyByKey(
                                      'NA',
                                      Object.entries(AssetGoodConditionCheck)
                                    )
                                  }
                                  handleChangeFormData={(e) => {
                                    createDispositionsChangeHandler(index)({
                                      target: {
                                        name: `temperatureOk-${index}`,
                                        value: (e.target.value as Option).id,
                                      },
                                    })
                                  }}
                                  options={Object.entries(
                                    AssetGoodConditionCheck
                                  ).map(([key]) => key)}
                                  sx={{ my: 1 }}
                                />

                                <AutocompleteInput
                                  id={`sample-preparation-${index}`}
                                  label="Sample Preparation*"
                                  name={`samplePreparation-${index}`}
                                  autoFocus={
                                    getAutoFocusForDisposition(index)
                                      .samplePreparation
                                  }
                                  autoSelect={false}
                                  disabled={isAssetFieldDisabled}
                                  value={samplePreparationCaster(
                                    assetFormData.dispositions[index]
                                      ?.samplePreparation as string
                                  )}
                                  handleChangeFormData={(e) => {
                                    createDispositionsChangeHandler(index)({
                                      target: {
                                        name: `samplePreparation-${index}`,
                                        value: (e.target.value as Option).id,
                                      },
                                    })
                                  }}
                                  options={samplePreparationTypeOptions}
                                  sx={{ my: 1 }}
                                  errorMessage={
                                    validationErrors?.samplePreparation
                                  }
                                  ref={(el) =>
                                    (inputRefs.current[
                                      `samplePreparation-${index}`
                                    ] = el)
                                  }
                                />

                                {assetFormData.dispositions[index]
                                  ?.samplePreparation ===
                                  getEnumKeyByKey(
                                    'OTHER',
                                    Object.entries(SamplePreparationType)
                                  ) && (
                                  <TextInput
                                    id={`sample-preparation-note-${index}`}
                                    label="Sample Preparation Note*"
                                    name={`samplePreparationNote-${index}`}
                                    autoFocus={
                                      getAutoFocusForDisposition(index)
                                        .samplePreparationNote
                                    }
                                    focused={
                                      getAutoFocusForDisposition(index)
                                        .samplePreparationNote
                                    }
                                    disabled={isAssetFieldDisabled}
                                    value={
                                      assetFormData.dispositions[index]
                                        .samplePreparationNote
                                    }
                                    placeholder={'Sample Preparation Note*'}
                                    inputMode="text"
                                    handleChangeFormData={createDispositionsChangeHandler(
                                      index
                                    )}
                                    errorMessage={
                                      validationErrors?.samplePreparationNote
                                    }
                                    shrink
                                  />
                                )}

                                <TextInput
                                  id={`implant-memo-${index}`}
                                  label="Scrub Tech / Note"
                                  name={`implantMemo-${index}`}
                                  disabled={isAssetFieldDisabled}
                                  value={
                                    assetFormData.dispositions[index]
                                      .implantMemo
                                  }
                                  placeholder={'Note'}
                                  inputMode="text"
                                  handleChangeFormData={createDispositionsChangeHandler(
                                    index
                                  )}
                                  shrink={true}
                                />

                                <FormControl fullWidth>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <Box flexGrow="1">
                                      <TextInput
                                        id={'serialNumber'}
                                        label={'Serial Number*'}
                                        name={'serialNumber'}
                                        placeholder="None*"
                                        value={
                                          assetFormData.serialNumber ??
                                          serialNumber
                                        }
                                        handleChangeFormData={
                                          handleChangeSerialNumber
                                        }
                                        disabled={isAssetFieldDisabled}
                                        errorMessage={
                                          validationErrors?.serialNumber
                                        }
                                        autoFocus={
                                          getAutoFocusForDisposition(index)
                                            .serialNumber
                                        }
                                        ref={(el) =>
                                          (inputRefs.current[
                                            `serialNumber-${index}`
                                          ] = el)
                                        }
                                      />
                                    </Box>

                                    <Button
                                      sx={{
                                        mt: 1.5,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={isAssetFieldDisabled}
                                      onClick={() => {
                                        setIsTrayScannerOpen('serial')
                                        setUserCanScan(true)
                                      }}
                                      className="barcode-button"
                                    >
                                      <BarcodeIcon
                                        active
                                        color={
                                          isAssetFieldDisabled
                                            ? 'var(--grayscale)'
                                            : 'var(--primary)'
                                        }
                                      />
                                    </Button>
                                  </Box>
                                </FormControl>
                              </>
                            )}

                          {isManualAndExplanted(index) && (
                            <AutocompleteInput
                              disabled={isAssetFieldDisabled}
                              id={`explanted-reason-${index}`}
                              label="Explanted Reason*"
                              name={`explantedReason-${index}`}
                              value={explantedReasonCaster(
                                assetFormData.dispositions[index]
                                  ?.explantedReason as string
                              )}
                              handleChangeFormData={(e) => {
                                createDispositionsChangeHandler(index)({
                                  target: {
                                    name: `explantedReason-${index}`,
                                    value: (e.target.value as Option).id,
                                  },
                                })
                              }}
                              options={EXPLANTED_REASON_LIST}
                              sx={{ marginBottom: '8px' }}
                              errorMessage={validationErrors?.explantedReason}
                              autoFocus={
                                getAutoFocusForDisposition(index)
                                  .explantedReason
                              }
                              ref={(el) =>
                                (inputRefs.current[`explantedReason-${index}`] =
                                  el)
                              }
                            />
                          )}

                          {isManualAndExplanted(index) &&
                            renderExplantedReasonNote(index)}

                          {disposition === 'WASTED' && (
                            <AutocompleteInput
                              autoFocus={
                                getAutoFocusForDisposition(index).wastedReason
                              }
                              required={false}
                              id={`wasted-reason-${index}`}
                              label="Wasted reason*"
                              name={`wastedReason-${index}`}
                              disabled={isAssetFieldDisabled}
                              value={wastedReasonCaster(
                                assetFormData.dispositions[index]
                                  ?.wastedReason as string
                              )}
                              handleChangeFormData={(e) => {
                                createDispositionsChangeHandler(index)({
                                  target: {
                                    name: `wastedReason-${index}`,
                                    value: (e.target.value as Option).id,
                                  },
                                })
                              }}
                              options={wastedReasons.map((reason) => ({
                                id: reason.value,
                                name: reason.label,
                              }))}
                              sx={{ marginBottom: '8px' }}
                              errorMessage={validationErrors?.wastedReason}
                              ref={(el) =>
                                (inputRefs.current[`wastedReason-${index}`] =
                                  el)
                              }
                            />
                          )}
                        </FormControl>
                      )}
                    </Box>
                  )
                })
              : null}
          </Box>

          <Box sx={{ mt: 2 }}>
            <MediaUpload
              disableActions={isAssetFieldDisabled}
              isLocal
              hasActions
              onLocalMediaFilesChange={onMediaFilesChange}
            />
          </Box>

          {isAssetAddedToList && (
            <Typography
              variant="h4"
              sx={{ mb: 1 }}
              className={styles.addedToList}
            >
              <Check sx={{ mr: 1 }} />
              Added to documented list
            </Typography>
          )}

          <Box sx={{ width: 0.9, mx: 'auto', mb: 2.5 }}>
            {!isAssetAddedToList && (
              <>
                <Typography
                  variant="h6"
                  className={styles.secondaryText}
                  sx={{ my: 1.5 }}
                >
                  Make sure that the information entered above is correct. Once
                  the product has been added to the product list, it cannot be
                  changed.
                </Typography>
                <LoadingButton
                  loading={addSurgeryAssetScanMutation.loading}
                  loadingText="Adding to documented list"
                  type="submit"
                >
                  Add to documented list
                </LoadingButton>
              </>
            )}
          </Box>
        </form>
      </Box>
    </>
  )
}

export default Result
