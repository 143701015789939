import React, { createContext, useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainIconFlowState } from 'common/types'
import { AssetType, AssetTypeContextProps } from './AssetTypeContext.types'
import { debounce } from 'lodash'
import { closeSnackbar } from 'notistack'

// Create the context
const AssetTypeContext = createContext<AssetTypeContextProps | undefined>(
  undefined
)

// Create a provider component
export const AssetTypeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [assetType, setAssetType] = useState<AssetType>(
    'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT'
  )
  const [mainIconFlowState, setMainIconFlowState] = useState<MainIconFlowState>(
    {
      isBatchModeEnabled: true,
      isMultipackHardware: false,
      multipackHardwareSelectedResult: undefined,
    }
  )
  const [isSalesRepQrOpen, setIsSalesRepQrOpen] = useState(false)
  const [isBatchMode, setIsBatchMode] = useState(false)
  const [onMainIconLanding, setOnMainIconLanding] = useState(true)
  const [onTrayIconLanding, setOnTrayIconLanding] = useState(false)

  const navigate = useNavigate()

  const immediateChangeAssetType = (type: AssetType) => {
    setAssetType(type)
    closeSnackbar()

    if (type === 'ADD SALES REP') {
      setIsSalesRepQrOpen(true)
      return
    }

    const setToBatchScan =
      (type === 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT' &&
        mainIconFlowState.isMultipackHardware === false) ||
      type === 'CONSUMABLE PRODUCT' ||
      type === 'IMPLANTABLE OTHER' ||
      type === 'EXPLANTED'
        ? true
        : false

    setMainIconFlowState({
      assetTypeLabel: type,
      isBatchModeEnabled: setToBatchScan,
      isMultipackHardware: false,
      multipackHardwareSelectedResult: undefined,
    })

    if (setToBatchScan) {
      setIsBatchMode(true)
    }

    if (type === 'PREFERENCE CARD') {
      navigate(`${window.location.pathname}/preference-card`)
      setOnMainIconLanding(false)
      return
    }

    if (type === 'NOTIFICATIONS') {
      navigate(`${window.location.pathname}/notifications`)
      setOnMainIconLanding(false)
      return
    }

    setOnMainIconLanding(false)
    if (type === 'TRAYS') {
      setOnTrayIconLanding(true)
    } else {
      const currentPath = window.location.pathname
      const basePath = currentPath.includes('/asset')
        ? currentPath.split('/asset')[0]
        : currentPath

      if (setToBatchScan) {
        navigate(`${basePath}/asset/batch`)
      } else {
        navigate(`${basePath}/asset/scan`)
      }
    }
  }

  const debouncedChangeAssetType = useCallback(
    debounce(immediateChangeAssetType, 500),
    []
  )

  const handleChangeAssetType = (
    type: AssetType,
    useDebounce: boolean = false
  ) => {
    if (useDebounce) {
      debouncedChangeAssetType(type)
    } else {
      debouncedChangeAssetType.cancel()
      immediateChangeAssetType(type)
    }
  }

  return (
    <AssetTypeContext.Provider
      value={{
        assetType,
        setAssetType,
        handleChangeAssetType,
        mainIconFlowState,
        isSalesRepQrOpen,
        isBatchMode,
        onMainIconLanding,
        onTrayIconLanding,
        setMainIconFlowState,
        setIsSalesRepQrOpen,
        setIsBatchMode,
        setOnMainIconLanding,
        setOnTrayIconLanding,
      }}
    >
      {children}
    </AssetTypeContext.Provider>
  )
}

// Create a hook to use the context
export const useAssetType = () => {
  const context = useContext(AssetTypeContext)
  if (!context) {
    throw new Error('useAssetType must be used within an AssetTypeProvider')
  }
  return context
}
