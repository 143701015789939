import { useState } from 'react'
import {
  ScrewAnnotation,
  PlateAnnotation,
  DrillAnnotation,
  TrayData,
} from '../GrayScale.types'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'

export const useAnnotations = () => {
  const [screwAnnotations, setScrewAnnotations] = useState<ScrewAnnotation[]>(
    []
  )
  const [plateAnnotations, setPlateAnnotations] = useState<PlateAnnotation[]>(
    []
  )
  const [drillAnnotations, setDrillAnnotations] = useState<DrillAnnotation[]>(
    []
  )
  const [selectedAnnotation, setSelectedAnnotation] = useState<string | null>(
    null
  )

  const initializeAnnotations = (
    trayData: {
      [key: string]: {
        screw?: ScrewAnnotation[]
        plate?: PlateAnnotation[]
        drill?: DrillAnnotation[]
      }
    },
    trayScrews: any[],
    usageScrews: BetterIDTrayScrew[]
  ) => {
    const firstKey = Object.keys(trayData)[0] as keyof TrayData
    const firstTrayData = trayData[firstKey]

    if (firstTrayData?.screw) {
      const mappedAnnotations = firstTrayData.screw.map((screw) => ({
        ...screw,
        count:
          trayScrews.some(
            (ts) =>
              ts.row === screw.row_label &&
              ts.column === screw.column_label &&
              ts.x === screw.column_index &&
              ts.label === screw.label
          ) &&
          !usageScrews.some(
            (us) =>
              us.row === screw.row_label &&
              us.column === screw.column_label &&
              us.x === screw.column_index &&
              us.label === screw.label
          )
            ? 1
            : 0,
      }))
      setScrewAnnotations(mappedAnnotations)
    }

    if (firstTrayData?.plate) setPlateAnnotations(firstTrayData.plate)
    if (firstTrayData?.drill) setDrillAnnotations(firstTrayData.drill)
  }

  return {
    screwAnnotations,
    plateAnnotations,
    drillAnnotations,
    selectedAnnotation,
    setScrewAnnotations,
    setSelectedAnnotation,
    initializeAnnotations,
  }
}
