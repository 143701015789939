import { Navigate, useParams } from 'react-router-dom'
import { DerivedSurgeryStatus } from 'common/types'

interface Props {
  children: JSX.Element
  permittedStatus: DerivedSurgeryStatus['name'][]
  status: DerivedSurgeryStatus['name']
}

const ProtectedStatusRoute = ({ children, permittedStatus, status }: Props) => {
  const { surgeryId } = useParams()

  const statusIsPermitted = permittedStatus.includes(status)

  if (!statusIsPermitted) {
    return <Navigate to={`/surgeries/${surgeryId}`} />
  }

  return children
}

export default ProtectedStatusRoute
