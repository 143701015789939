import { AssetData, AssetTypeLabel, Scan } from 'common/types'
import { TableRowProps } from '@mui/material'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { HeadCell } from 'components/molecules/DataTable/TableHeader'
import { SurgeryDetailsOutput } from 'views/PatientReport/PatientReport.types'

interface AssetDetailsTableProps {
  assetGroups: SurgeryDetailsOutput['assetGroups']
  isLoading: boolean
  isErrored: boolean
}

export const AssetDetailsTable = ({
  assetGroups,
  isLoading,
  isErrored,
}: AssetDetailsTableProps) => {
  const HEAD_CELLS: HeadCell[] = [
    { id: 'assetType', label: 'Product Type', numeric: false },
    {
      id: 'deviceDescription',
      label: 'Device Details',
      numeric: false,
      sx: { width: '200px' },
    },
    { id: 'companyName', label: 'Manufacturer', numeric: false },
    {
      id: 'udi',
      label: 'UDI',
      numeric: false,
      sx: { width: '160px' },
    },
    {
      id: 'catalogNumber',
      label: 'Catalog No.',
      numeric: false,
      sx: { width: '120px' },
    },
    {
      id: 'serialNumber',
      label: 'Serial No',
      numeric: false,
      sx: { width: '120px' },
    },
    {
      id: 'lotNumber',
      label: 'Lot No.',
      numeric: false,
      sx: { width: '100px' },
    },
    { id: 'implantSite', label: 'Implant Site', numeric: false },
    {
      id: 'quantity',
      label: 'Quantity',
      numeric: false,
      sx: { width: '80px' },
    },
  ]

  interface AssetDetailsRowsData extends TableRowProps {
    serialNumber: string
    udi: string
    lotNumber: string
    catalogNumber: string
    deviceDescription: Scan['deviceDescription']
    companyName: Scan['companyName']
    quantity: Scan['count']
    assetType: AssetTypeLabel
    implantSite: string
  }

  const mappedData: AssetDetailsRowsData[] = assetGroups
    .map((assetGroup: AssetData) => {
      return assetGroup.scans.map((scan: Scan) => {
        return {
          assetType: getAssetTypeLabel(scan.assetType),
          deviceDescription: scan.deviceDescription || '-',
          companyName: scan.companyName,
          udi: scan.udi || '-',
          catalogNumber: scan.catalogNumber || '-',
          serialNumber: scan.serialNumber || '-',
          lotNumber: scan.lotBatch || '-',
          implantSite: scan.implantSite || '-',
          quantity: scan.count,
        }
      })
    })
    .flat()

  return (
    <DataTable
      tableHeaders={HEAD_CELLS}
      tableRows={mappedData || []}
      isLoading={isLoading}
      selectable={false}
      isErrored={isErrored}
    />
  )
}
