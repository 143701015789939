import { CustomModal } from 'components/molecules/Modal/Modal'
import { PDFSettingsModalProps } from './PDFSettingsModal.types'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import './PDFSettingsModal.scss'
import { AssetTypeLabel } from 'common/types'
const PDFSettingsModal = ({
  open,
  onClose,
  selectedAssetTypes,
  setSelectedAssetTypes,
}: PDFSettingsModalProps) => {
  const [localSelectedTypes, setLocalSelectedTypes] = useState<
    AssetTypeLabel[]
  >(selectedAssetTypes || [])

  const handleAssetTypeChange = (
    assetTypes: AssetTypeLabel | AssetTypeLabel[]
  ) => {
    const typesToProcess = Array.isArray(assetTypes) ? assetTypes : [assetTypes]

    const allTypesSelected = typesToProcess.every((type) =>
      localSelectedTypes.includes(type)
    )

    if (allTypesSelected) {
      const remainingTypes = localSelectedTypes.filter(
        (type) => !typesToProcess.includes(type)
      )
      if (remainingTypes.length === 0) return

      setLocalSelectedTypes(remainingTypes)
    } else {
      const newTypes = typesToProcess.filter(
        (type) => !localSelectedTypes.includes(type)
      )
      setLocalSelectedTypes([...localSelectedTypes, ...newTypes])
    }
  }

  const handleSave = () => {
    setSelectedAssetTypes?.(localSelectedTypes)
    onClose()
  }

  return (
    <CustomModal
      open={open}
      secondaryButtonAction={onClose}
      header="PDF Settings"
      primaryButtonText="Save"
      isLoadingState={false}
      handleOnSubmit={handleSave}
      icon={null}
    >
      <div className="pdf-settings-container">
        <Typography variant="h3" className="pdf-settings-title">
          Choose the product types that will appear in the pdf report
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  localSelectedTypes.includes(
                    'Implantable Hardware/Associated Product'
                  ) || localSelectedTypes.includes('Implantable Other')
                }
                onChange={() => {
                  handleAssetTypeChange([
                    'Implantable Hardware/Associated Product',
                    'Implantable Other',
                  ])
                }}
              />
            }
            label="Implants"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={localSelectedTypes.includes('Implantable Biologic')}
                onChange={() => handleAssetTypeChange('Implantable Biologic')}
              />
            }
            label="Biologic"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={localSelectedTypes.includes('Consumable')}
                onChange={() => handleAssetTypeChange('Consumable')}
              />
            }
            label="Consumable"
          />
        </FormGroup>
      </div>
    </CustomModal>
  )
}

export default PDFSettingsModal
