import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'

export const getTrayTypeFromJSONFileName = (fileName: string): TrayType => {
  switch (fileName) {
    case 'Stryker_1.7_Middle_Face.json':
      return 'stryker middle face'
    case 'Stryker_1.2_Upper_Face.json':
      return 'stryker upper face'
    case 'Zimmer_4.json':
      return 'zimmer curved rods'
    default:
      return 'stryker screw caddy'
  }
}
