import { MenuItem, TextField, Typography } from '@mui/material'
import { ISelectInput } from './SelectInput.types'
import { makeStyles } from '@material-ui/core/styles'
import { OptionObjectPair, Option } from 'common/types'
import { useEffect, useState, forwardRef } from 'react'

const SelectInput = forwardRef<HTMLDivElement, ISelectInput>(
  (
    {
      id,
      label,
      name,
      disabled = false,
      handleChangeFormData,
      options,
      placeholder = 'Select',
      required = false,
      value = '',
      itemStyles = {},
      sx = {},
      errorMessage,
      shrink = true,
      autoFocus = false,
      variant = 'standard',
    },
    ref
  ) => {
    const useStyles = makeStyles({
      select: {
        '& ul': {
          backgroundColor: 'white',
        },
        '& li': {
          position: 'relative',
        },
      },
    })

    const classes = useStyles()
    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (autoFocus) {
        const timer = setTimeout(() => {
          setOpen(true)
        }, 100)
        return () => clearTimeout(timer)
      } else {
        setOpen(false)
      }
    }, [autoFocus])

    return (
      <div className="select-input-container" ref={ref}>
        <TextField
          autoFocus={autoFocus}
          select
          fullWidth
          disabled={disabled}
          required={required}
          id={id}
          label={label}
          classes={{
            root: `select {
              background-color: "red"
            }`,
          }}
          name={name}
          value={value}
          onChange={handleChangeFormData}
          SelectProps={{
            open,
            onClose: () => setOpen(false),
            onOpen: () => setOpen(true),
            displayEmpty: true,
            renderValue: value
              ? undefined
              : () => <Typography color={'gray'}>{placeholder}</Typography>,
            MenuProps: {
              classes: { paper: classes.select },
            },
          }}
          sx={{
            my: 2,
            textTransform: 'capitalize',
            ...sx,
          }}
          variant={variant}
          InputLabelProps={{
            shrink: value ? true : shrink,
          }}
        >
          {options.map((option, i) => (
            <MenuItem
              autoFocus={autoFocus}
              key={i}
              value={
                (option as OptionObjectPair).value
                  ? (option as OptionObjectPair).value
                  : (option as Option).id
              }
              sx={{
                textTransform: 'capitalize',
                position: 'absolute',
                ...itemStyles,
              }}
            >
              {(option as OptionObjectPair).label
                ? (option as OptionObjectPair).label
                : (option as Option).name}
            </MenuItem>
          ))}
        </TextField>
        {errorMessage && (
          <Typography fontSize={12} color="red" ml={0.5} mt={-1}>
            {errorMessage}
          </Typography>
        )}
      </div>
    )
  }
)

export default SelectInput
