import { useState, useMemo, useEffect } from 'react'
import { useUser } from 'app/User'
import { RfSpongeCountPanel } from 'components/Consumables/RfSpongeCountPanel'
import {
  useDeleteSurgeryAssetsByIds,
  useDeleteConsumableAssetsByIds,
  useGetSurgeryQuery,
} from 'lib/apollo/hooks'
import {
  deriveAssetDetailsFromAssetData,
  groupScanStatuses,
  getCountFromScans,
} from 'lib/utils/data'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { DispositionedAssetDetails } from '../DispositionedAssetDetails/DispositionedAssetDetails'
import { AddMissingFieldsProps, StoredAssetProps } from './StoredAsset.types'
import { EditAssetButton } from '../EditAssetButton/EditAssetButton'
import { useEditDocumentedListContext } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import { isDatePast } from 'lib/utils/isDatePast'
import { Grid, Typography } from '@mui/material'
import { WASTED_REASON_LIST } from 'common/disposition'
import { Surgery } from 'common/types'
import { AssetData } from 'common/types'
import { v4 as uuid } from 'uuid'

export const useStoredAssetLogic = ({
  assetData,
  surgeryId,
  isProcedureSubmitted,
  groupedAssetData,
  printMode,
}: StoredAssetProps) => {
  // hooks
  const { user, isNurseAdmin, isNurse } = useUser()
  const { isSavingEditedAsset } = useEditDocumentedListContext()

  const { loading: surgeryLoading, data: surgeryData } = useGetSurgeryQuery(
    surgeryId,
    {
      disablePolling: true,
    }
  )

  const surgery: Surgery = surgeryData?.getSurgery

  const isAutopopulated = surgeryLoading
    ? undefined
    : surgery?.autoPopulatedConsumables?.some(
        (item) => item.bidProductId === assetData.bidAssetId
      ) || false

  const assetDetails = deriveAssetDetailsFromAssetData(assetData)
  const {
    assetDescription,
    sizeText,
    deviceCount,
    scans,
    secondaryDeviceIdType,
    accurateDeviceCount,
    deviceCountCorrectiveActionTaken,
    expirationDate,
    serialNumber,
    catalogNumber,
    lotBatch,
    deviceId,
    assetTray,
    versionModelNumber,
    isManualAddition,
    _id,
    bidAssetId,
    parentCompany,
  } = assetDetails

  const [deleteSurgeryAssetsByIds, deleteSurgeryAssetsByIdsMutation] =
    useDeleteSurgeryAssetsByIds({ surgeryId })
  const [deleteConsumableAssetsByIds, deleteConsumableAssetsByIdsMutation] =
    useDeleteConsumableAssetsByIds({
      surgeryId,
    })

  // states
  const [toBeDeleted, setToBeDeleted] = useState<string[]>([])
  const [reviewingMedia, setReviewingMedia] = useState(false)
  const [mediaAssetId] = useState(assetData?.scans[0]._id)
  const [longestLabel, setLongestLabel] = useState(0)

  // constants
  const groupedStatuses = useMemo(() => groupScanStatuses(scans), [scans])
  const assetType = assetData.scans[0].assetType
  const fileNames = scans
    ?.flatMap((scan) => scan.media)
    .filter((media) => media !== undefined) as string[]

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isMultipackHardware,
    isSpongeConsumable,
  } = parseAssetIdentifiers({
    deviceDescription: assetDescription,
    deviceCount,
    idType: secondaryDeviceIdType,
    assetType: assetType,
  })

  const isSingleConsumable =
    assetData.scans[0].assetType === 'consumable' && assetData.deviceCount === 1

  const consumableScans = useMemo(
    () => scans.filter((scan) => scan.assetType === 'consumable'),
    [scans]
  )

  const consumablesPacksCount = assetData.packsUsed

  const associatedScans = useMemo(
    () => scans.filter((scan) => scan.implantStatus === 'ASSOCIATED_ASSET'),
    [scans]
  )
  const associatedCount = getCountFromScans(associatedScans)

  const hasApproved = useMemo(
    () => 'APPROVED' in groupedStatuses,
    [groupedStatuses]
  )

  const isApproved = useMemo(
    () => hasApproved && Object.keys(groupedStatuses).length === 1,
    [groupedStatuses, hasApproved]
  )

  const status = assetData.scans[0].status
  const isRepScan = Boolean(status.bidCompanyId)
  const canDelete =
    !isProcedureSubmitted &&
    !isApproved &&
    (isNurse || isNurseAdmin || (isRepScan && status.userId === user?.id))

  const isConsumable = assetType === 'consumable'
  const packCount = isMultipackHardware ? assetData.packsUsed ?? 1 : 1
  let unusedCount = isMultipackHardware
    ? packCount * deviceCount - scans.length
    : 0

  const explantedScansCount = groupedAssetData?.explanted[0]?.scans?.length ?? 0
  unusedCount = unusedCount - explantedScansCount

  const isDTMScrew = scans.some(
    (scan) =>
      scan.isDTMScrew &&
      assetData.scans.some((assetScan) => assetScan._id === scan._id)
  )

  const isExpired = isDatePast({ date: expirationDate })?.isDateExpired

  // handles
  const handleDelete = async (deleteCount: number) => {
    if (isConsumable) {
      try {
        const result = await deleteConsumableAssetsByIds({
          variables: {
            count: deleteCount,
            assetIds: toBeDeleted.slice(0, deleteCount),
            isMultipack: isMultipackConsumable,
          },
        })

        if (result.data?.deleteConsumableAssetsByIds?.success) {
          setToBeDeleted([])
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      await deleteSurgeryAssetsByIds({
        variables: {
          surgeryId,
          assetIds: toBeDeleted.slice(0, deleteCount),
        },
      }).finally(() => {
        setToBeDeleted([])
      })
    }
  }

  const consumablesCountLabel = () => {
    const singleConsumablesCount = isSingleConsumable ? assetData.packsUsed : 0

    const multipackConsumablesCount = isMultipackConsumable
      ? assetData.total
      : 0

    const spongeCount = isSpongeConsumable ? assetData.total : 0

    const RFSpongeCount = isRfMultipackConsumable ? assetData.total : 0

    let label = ''

    if (isMultipackConsumable) {
      label =
        assetData.packsUsed === 1 ? 'Multipack Opened' : 'Multipacks Opened'
    } else if (
      isSpongeConsumable ||
      isRfMultipackConsumable ||
      singleConsumablesCount > 0
    ) {
      label = 'Used'
    } else if (RFSpongeCount >= 1) {
      label = 'Used'
    }

    const handleDelete = canDelete
      ? () => {
          const ids = consumableScans.map((scan) => scan._id)
          setToBeDeleted(ids)
        }
      : undefined

    if (RFSpongeCount > 0) {
      return (
        <RfSpongeCountPanel
          isAutopopulated={isAutopopulated}
          printMode={printMode}
          label={label}
          spongeCount={RFSpongeCount}
          value={assetData.packsUsed}
          isProcedureSubmitted={isProcedureSubmitted}
          handleDelete={handleDelete}
          isLoading={
            deleteConsumableAssetsByIdsMutation.loading ||
            isSavingEditedAsset(_id)
          }
          isManualAddition={isManualAddition}
          assetGroup={assetData}
          assetTray={assetTray}
          surgeryId={surgeryId}
        />
      )
    } else if (spongeCount > 0) {
      return (
        <DispositionedAssetDetails
          value={assetData.packsUsed}
          label={label}
          additionalLabels={[`Total: ${spongeCount} sponges`]}
          handleDelete={handleDelete}
          isLoading={deleteConsumableAssetsByIdsMutation.loading}
          editComponent={
            !printMode ? (
              <EditAssetButton
                isSponge={true}
                isManualAddition={isManualAddition}
                bidAssetId={bidAssetId}
                assetgroupId={_id}
                isProcedureSubmitted={isProcedureSubmitted}
                surgeryId={surgeryId}
                assetTray={assetTray}
                consumableAssetGroup={assetData}
                isMultipack={isSpongeConsumable}
              />
            ) : null
          }
        />
      )
    } else if (isMultipackConsumable || isSingleConsumable) {
      return (
        <DispositionedAssetDetails
          value={assetData.packsUsed}
          label={label}
          handleDelete={handleDelete}
          isLoading={
            deleteConsumableAssetsByIdsMutation.loading ||
            isSavingEditedAsset(_id)
          }
          editComponent={
            !printMode ? (
              <EditAssetButton
                isSponge={false}
                singleConsumablesCount={
                  multipackConsumablesCount || singleConsumablesCount
                }
                isManualAddition={isManualAddition}
                bidAssetId={bidAssetId}
                assetgroupId={_id}
                isProcedureSubmitted={isProcedureSubmitted}
                surgeryId={surgeryId}
                assetTray={assetTray}
                consumableAssetGroup={assetData}
                isMultipack={isMultipackConsumable}
              />
            ) : null
          }
        >
          {((isSingleConsumable && assetData.wastedReason) ||
            isMultipackConsumable) && (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ mt: 0 }}
            >
              {assetData.total ? (
                <Grid item sx={{ mt: 1 }}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'black',
                        ml: 1,
                        mr: 1,
                        textTransform: 'capitalize',
                        opacity: 0.8,
                      }}
                    >
                      {`Items used: ${assetData.total}`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {assetData.wastedReason && (
                <Grid item sx={{ mt: 1 }}>
                  <Grid item>
                    <Typography
                      variant="h6"
                      sx={{
                        color: 'black',
                        ml: 1,
                        mr: 1,
                        textTransform: 'capitalize',
                        opacity: 0.8,
                      }}
                    >
                      {`Items wasted: ${assetData.wasted} - ${
                        WASTED_REASON_LIST.find(
                          (reason) => reason.id === assetData.wastedReason
                        )?.name
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </DispositionedAssetDetails>
      )
    } else {
      return null
    }
  }

  useMemo(() => {
    setLongestLabel(0)
  }, [assetData.scans])

  const [addMissingFields, setAddMissingFields] =
    useState<AddMissingFieldsProps>({
      id: uuid(),
      open: false,
    })
  const handleAddMissingFields = (
    fields: string[],
    assetId?: AssetData['_id']
  ) => {
    setAddMissingFields({
      open: true,
      assetId,
      fields,
    })
  }

  const handleCloseMissingFieldsModal = () => {
    setAddMissingFields({
      open: false,
      id: uuid(),
    })
  }

  useEffect(() => {
    if (!addMissingFields.open) {
      setAddMissingFields({
        open: false,
        id: uuid(),
      })
    }
  }, [addMissingFields.open])

  return {
    assetData,
    assetType,
    assetTray,
    associatedCount,
    canDelete,
    consumablesCountLabel,
    deleteSurgeryAssetsByIdsMutation,
    deviceCountCorrectiveActionTaken,
    expirationDate,
    groupedStatuses,
    isConsumable,
    isDTMScrew,
    isMultipackConsumable,
    isMultipackHardware,
    isRfMultipackConsumable,
    isSpongeConsumable,
    mediaAssetId,
    reviewingMedia,
    scans,
    serialNumber,
    setReviewingMedia,
    sizeText,
    toBeDeleted,
    unusedCount,
    versionModelNumber,
    handleDelete,
    deleteConsumableAssetsByIdsMutation,
    setToBeDeleted,
    isApproved,
    accurateDeviceCount,
    assetDescription,
    deviceId,
    catalogNumber,
    lotBatch,
    deviceCount,
    associatedScans,
    hasApproved,
    fileNames,
    isExpired,
    parentCompany,
    longestLabel,
    setLongestLabel,
    isAutopopulated,
    consumablesPacksCount,
    handleAddMissingFields,
    addMissingFields,
    handleCloseMissingFieldsModal,
  }
}
