import { useEffect, useState } from 'react'
import { TShareProductProps, TReceivedContacts } from './ShareProduct.types'
import { useGetSurgeryQuery } from 'lib/apollo/hooks'
import { useParams } from 'react-router-dom'
import { Surgery } from 'common/types'

export const useShareProductLogic = (props: TShareProductProps) => {
  const {
    assetDetails: { isExplanted, assetId },
  } = props

  const { surgeryId } = useParams()

  const [receivedReps, setReceivedReps] = useState<TReceivedContacts>({
    emails: [],
    mobiles: [],
  })

  const { data } = useGetSurgeryQuery(surgeryId, {
    disablePolling: true,
  })

  const surgery: Surgery = data?.getSurgery

  const assetReconciliation = isExplanted
    ? surgery?.explantedRepReconciliation?.find(
        (reconciliation) => reconciliation.assetId === assetId
      )
    : surgery?.implantableOtherRepReconciliation?.find(
        (reconciliation) => reconciliation.assetId === assetId
      )

  useEffect(() => {
    if (assetReconciliation) {
      setReceivedReps({
        emails: assetReconciliation?.emails,
        mobiles: assetReconciliation?.mobiles,
      })
    }
  }, [assetReconciliation, isExplanted, surgery])

  return {
    receivedReps,
  }
}
