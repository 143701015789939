import { TransformComponent } from 'react-zoom-pan-pinch'
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowLeft,
  ArrowRight,
  KeyboardBackspace,
  Replay,
  PhotoLibrary,
  ChevronLeft,
  ChevronRight,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material'
import classNames from 'classnames'

import useTrayProcedureEditModeLogic from './TrayProcedureEditMode.logic'
import getIsMobileOrTablet from 'lib/utils/getIsMobileOrTablet'

import { TrayProcedureEditModeProps } from './TrayProcedureEditMode.types'
import './TrayProcedureEditMode.scss'
import TrayMapSelector from '../TrayMapSelector/TrayMapSelector'
import ViewImageDialog from '../ViewImageDialog/ViewImageDialog'

const TrayProcedureEditMode = ({
  mapContent,
  trayType,
  onClose,
  onClickActions,
  isScrewEnabled,
  isScrewImplanted,
  isScrewWasted,
  handlePlateUsage,
  getPlateCount,
  setSelectedPlate,
  setModalOpen,
  setSelectedScrew,
  trayImage,
}: TrayProcedureEditModeProps) => {
  const {
    handlePan,
    zoomIn,
    zoomOut,
    resetTransform,
    handleZoomIn,
    handleZoomOut,
    isReferenceOpen,
    setIsReferenceOpen,
    imageScale,
    isImagePreviewOpen,
    setIsImagePreviewOpen,
    selectedScrewsCount,
  } = useTrayProcedureEditModeLogic()

  return (
    <div
      className={classNames('tray-procedure-edit-mode-organism_wrapper', {
        'is-mobile': getIsMobileOrTablet(),
        'reference-panel-open': isReferenceOpen,
      })}
    >
      <button className="back-button" onClick={onClose}>
        <KeyboardBackspace />
      </button>

      <div className="control-buttons">
        <button
          className={classNames('reference-panel-toggle', {
            'is-open': isReferenceOpen,
          })}
          onClick={() => setIsReferenceOpen(!isReferenceOpen)}
          aria-label={
            isReferenceOpen ? 'Hide reference image' : 'Show reference image'
          }
          aria-expanded={isReferenceOpen}
        >
          {isReferenceOpen ? <ChevronRight /> : <ChevronLeft />}
          <PhotoLibrary />
        </button>
      </div>

      <div className="joystick-container">
        <button onClick={() => handlePan('right')}>
          <ArrowDropUp />
        </button>
        <div>
          <button onClick={() => handlePan('up')}>
            <ArrowLeft />
          </button>
          <button onClick={() => handlePan('down')}>
            <ArrowRight />
          </button>
        </div>
        <button onClick={() => handlePan('left')}>
          <ArrowDropDown />
        </button>
      </div>

      <div className="tray-map_wrapper">
        <TransformComponent
          contentStyle={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          wrapperStyle={{
            width: '100%',
            height: '100%',
          }}
        >
          <TrayMapSelector
            trayType={trayType}
            isInOR
            mapContent={mapContent}
            onClickActions={onClickActions}
            isScrewEnabled={isScrewEnabled}
            isScrewImplanted={isScrewImplanted}
            isScrewWasted={isScrewWasted}
            handlePlateUsage={handlePlateUsage}
            getPlateCount={getPlateCount}
            setSelectedPlate={setSelectedPlate}
            setModalOpen={setModalOpen}
            setSelectedScrew={setSelectedScrew}
          />
        </TransformComponent>
      </div>

      {selectedScrewsCount > 0 && (
        <div className="selection-actions">
          <button className="toggle-button" onClick={() => setModalOpen(true)}>
            Confirm Selection ({selectedScrewsCount})
          </button>
        </div>
      )}

      <div className="zoom-controls">
        <button onClick={() => zoomOut(0.5)} aria-label="Zoom Out (Ctrl+-)">
          <ZoomOut />
        </button>
        <button onClick={() => zoomIn(0.5)} aria-label="Zoom In (Ctrl++)">
          <ZoomIn />
        </button>
        <button
          onClick={() => resetTransform()}
          aria-label="Reset View (Ctrl+0)"
        >
          <Replay />
        </button>
      </div>

      <div
        className={classNames('reference-panel', {
          'is-open': isReferenceOpen,
        })}
      >
        <div className="reference-image-container">
          {trayImage && (
            <img
              src={trayImage}
              alt="Tray reference"
              className="reference-image"
              style={{
                transform: `scale(${imageScale})`,
              }}
            />
          )}
        </div>
        <div className="reference-panel-controls">
          <button
            onClick={handleZoomOut}
            disabled={imageScale <= 0.5}
            aria-label="Zoom out"
          >
            <ZoomOut />
          </button>
          <button
            onClick={handleZoomIn}
            disabled={imageScale >= 3}
            aria-label="Zoom in"
          >
            <ZoomIn />
          </button>
        </div>
      </div>

      <ViewImageDialog
        open={isImagePreviewOpen}
        handleClose={() => setIsImagePreviewOpen(false)}
        trayImage={trayImage || ''}
      />
    </div>
  )
}

export default TrayProcedureEditMode
