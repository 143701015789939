import { ZodError } from 'zod'
import * as pdfjsLib from 'pdfjs-dist'

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js'

export const getZodError = (error: ZodError) => {
  const errorObject = error.issues.reduce((acc, issue) => {
    const path = issue.path.join('.')
    acc[path] = issue.message
    return acc
  }, {} as Record<string, string>)
  return errorObject
}

export const formatUSPhoneNumber = (value: string) => {
  if (!value) {
    return value
  }

  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) {
    return phoneNumber
  }

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export const formatUSPhoneNumberForSubmission = (phone: string) => {
  const cleaned = phone.replace(/[()\s-]/g, '')

  return `+1${cleaned}`
}

export const formatUSPhoneNumberForDisplay = (
  phone: string
): string | undefined => {
  const cleaned = phone.replace(/^\+1/, '')

  if (cleaned.length !== 10) {
    return undefined
  }

  const areaCode = cleaned.slice(0, 3)
  const centralOfficeCode = cleaned.slice(3, 6)
  const lineNumber = cleaned.slice(6)

  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`
}

export const pdfToBase64Image = (pdfData: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const loadingTask = pdfjsLib.getDocument({ data: atob(pdfData) })

    loadingTask.promise
      .then((pdf) => {
        pdf.getPage(1).then((page) => {
          const scale = 1.5
          const viewport = page.getViewport({ scale })

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          if (!context) {
            reject('Failed to get canvas context')
            return
          }

          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          }

          page
            .render(renderContext)
            .promise.then(() => {
              const base64Image = canvas.toDataURL()
              resolve(base64Image)
            })
            .catch((err) => reject('Error rendering page to canvas: ' + err))
        })
      })
      .catch((err) => reject('Error loading PDF: ' + err))
  })
}

export function transformDate(dateString: string) {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

  if (iso8601Regex.test(dateString)) {
    const date = new Date(dateString)

    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0')
    const year = date.getUTCFullYear()

    return `${month}/${day}/${year}`
  } else {
    return dateString
  }
}
