import { StyleSheet } from '@react-pdf/renderer'

const mainStyles = StyleSheet.create({
  page: {
    padding: 20,
  },
  headerContainer: {
    marginTop: 16,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  headerCaseWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCaseLabel: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  headerCaseValue: {
    fontSize: 12,
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  detailsGrid: {
    flexDirection: 'row',
    gap: 20,
    marginBottom: 20,
  },
  procedureDetailsSection: {
    flex: 1,
  },
  patientDetailsSection: {
    flex: 1,
  },
})

const cardStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F5F5F5',
    padding: 16,
    height: 100,
    borderRadius: 5,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  label: {
    fontSize: 10,
    width: 120,
  },
  value: {
    fontSize: 10,
  },
})

const tableStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F5F5F5',
    marginBottom: 30,
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#E0E0E0',
    padding: 8,
  },
  rowEven: {
    flexDirection: 'row',
    padding: 8,
    backgroundColor: '#F5F5F5',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    height: 50,
    maxHeight: 50,
  },
  rowOdd: {
    flexDirection: 'row',
    padding: 8,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    height: 50,
    maxHeight: 50,
  },
  cell: {
    fontSize: 9,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  cellDefault: {
    fontSize: 9,
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
})

export { mainStyles, cardStyles, tableStyles }
