import { FC } from 'react'
import { Surgery } from 'common/types'
import EventStatus from './EventStatus'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material'
import { useProcedureListTableLogic } from './ProcedureListTable.logic'
import './ProcedureListTable.scss'
import { ProcedureListTableProps } from './ProcedureList.types'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { CustomModal } from 'components/molecules/Modal/Modal'
import Notifications from 'views/Notifications/Notifications'
import BellIcon from 'assets/icons/BellIcon'

export const ProcedureListTable: FC<ProcedureListTableProps> = ({
  events,
  loading,
  showDate,
}: ProcedureListTableProps) => {
  const {
    flags,
    isRep,
    isNurse,
    hasItems,
    isMobile,
    caseInformation,
    sortedSurgeries,
    handleStartSurgery,
    hasNotifications,
    isNotificationsOpen,
    setIsNotificationsOpen,
  } = useProcedureListTableLogic({ events, loading, showDate })
  let testIndex = 0

  // ignores '<a> appearing as a child of <tbody>' warning in console to allow link component in table row
  console.error = (message) => {
    if (
      typeof message === 'string' &&
      message.startsWith('Warning: validateDOMNesting')
    ) {
      return
    }
  }

  return (
    <TableContainer className="table-container">
      <Table>
        <TableHead className="table-head">
          <TableRow>
            <TableCell className="table-cell-head">Case Information</TableCell>
            <TableCell className="table-cell-head">Record</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow className="table-row-initial-state">
              <TableCell
                className="table-cell-loading"
                data-testid="table-cell-loading"
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : !hasItems ? (
            <TableRow className="table-row-initial-state">
              <TableCell className="table-cell-empty">
                <Typography variant="subtitle2">
                  There are no procedures currently scheduled that match your
                  filter criteria
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            sortedSurgeries?.map((surgery: Surgery) => {
              if (surgery?.isTestProcedure) {
                testIndex += 1
              }

              return (
                <TableRow
                  key={surgery?._id}
                  className="table-row"
                  data-testid={
                    surgery?.isTestProcedure
                      ? `test-procedure-${testIndex}`
                      : 'table-row'
                  }
                  component={'div'}
                  onClick={() => handleStartSurgery(surgery)}
                >
                  <TableCell
                    className={`table-cell-case-info ${
                      isMobile ? 'is-mobile' : ''
                    }`}
                  >
                    {surgery &&
                      caseInformation({
                        surgery: surgery,
                        isNurse: isNurse || false,
                      })}
                  </TableCell>
                  <TableCell
                    className="table-cell-event-status"
                    data-testid="event-status"
                  >
                    {isRep ? null : flags.caseHomeNotificationsButton ? (
                      <IconButton
                        className="bell-icon"
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!hasNotifications(surgery)) return
                          setIsNotificationsOpen({
                            isOpen: true,
                            surgery,
                          })
                        }}
                      >
                        {hasNotifications(surgery) ? (
                          <BellIcon />
                        ) : (
                          <NotificationsIcon color="disabled" />
                        )}
                      </IconButton>
                    ) : null}

                    <EventStatus
                      isStarted={surgery.isStarted}
                      isTestProcedure={surgery?.isTestProcedure}
                      isScans={surgery?.assetGroups.length !== 0}
                      surgeryStatus={surgery?.status.name}
                      hasUndocumentedScans={surgery?.undocumentedScansCount > 0}
                      implantReportStatus={surgery?.implantReportStatus}
                      addendumStatus={surgery?.addendums?.length > 0}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>

      <CustomModal
        fitContentWidth={!isMobile}
        open={isNotificationsOpen.isOpen}
        header="Notifications"
        isLoadingState={false}
        icon={<></>}
        secondaryButtonAction={() => setIsNotificationsOpen({ isOpen: false })}
        primaryButtonDisabled
        closeButtonOnly
      >
        <Notifications
          surgery={isNotificationsOpen?.surgery as Surgery}
          isMobile={isMobile}
        />
      </CustomModal>
    </TableContainer>
  )
}
