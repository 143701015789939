import { AssetType } from 'common/disposition'
import { AssetTypeLabel, MainIconFlowState } from 'common/types'

// converts assettype label (ex: hardware) to assettype (ex: non-biological)

export const getAssetTypeCaps = (
  assetTypeLabel?: MainIconFlowState['assetTypeLabel']
): AssetType => {
  switch (assetTypeLabel) {
    case 'CONSUMABLE PRODUCT':
      return 'consumable'
    case undefined:
    case 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT':
    case 'TRAYS':
    case 'IMPLANTABLE':
      return 'non-biological'
    case 'IMPLANTABLE BIOLOGIC':
      return 'biological'
    case 'IMPLANTABLE OTHER':
      return 'other-non-biological'
    case 'EXPLANTED':
      return 'explanted'
    default:
      // Handle the case when no match is found
      throw new Error(`Unexpected product type label: ${assetTypeLabel}`)
  }
}

export const getAssetType = (
  assetTypeLabel: AssetTypeLabel
): AssetType | null => {
  switch (assetTypeLabel) {
    case 'Consumable':
      return 'consumable'
    case 'Implantable Hardware/Associated Product':
      return 'non-biological'
    case 'Implantable Biologic':
      return 'biological'
    case 'Implantable Other':
      return 'other-non-biological'
    case 'Explanted':
      return 'explanted'
    default:
      return null
  }
}
