import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ApolloCache,
  ApolloError,
  MutationFunctionOptions,
} from '@apollo/client'
import { UndocumentedScan } from 'common/types'
import {
  useAddUndocumentedScanMutation,
  useDeleteUndocumentedScanMutation,
  useGetUndocumentedScansQuery,
} from 'lib/apollo/hooks'
import { QueryResult, OperationVariables } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useUser } from 'app/User'

interface UndocumentedAssetsContextProps {
  undocumentedScans: UndocumentedScan[]
  setUndocumentedScans: React.Dispatch<React.SetStateAction<UndocumentedScan[]>>
  isLoadingUndocumentedList: boolean
  isError: ApolloError | undefined
  undocumentedScanCount: number
  addUndocumentedScan: (
    options?:
      | MutationFunctionOptions<
          {
            surgeryId: string
            userId: string
          },
          any,
          OperationVariables,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>
  undocumentedScansQuery: QueryResult<
    {
      getUndocumentedScans: UndocumentedScan[]
    },
    OperationVariables
  >
  resetAddMutation: () => void
  deleteScans: (scanIds: string[]) => void
  setStopGetUndocumentedScansPolling: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

export const UndocumentedAssetsContext =
  React.createContext<UndocumentedAssetsContextProps>({
    undocumentedScans: [] as UndocumentedScan[],
    setUndocumentedScans: () => {},
    isLoadingUndocumentedList: false,
    isError: undefined,
    undocumentedScanCount: 0,
    addUndocumentedScan: async () => {},
    undocumentedScansQuery: {
      data: { getUndocumentedScans: [] as UndocumentedScan[] },
    } as QueryResult<
      { getUndocumentedScans: UndocumentedScan[] },
      OperationVariables
    >,
    resetAddMutation: () => {},
    deleteScans: async () => {},
    setStopGetUndocumentedScansPolling: () => {},
  })

interface UndocumentedAssetsProviderProps {
  children: React.ReactNode
}

export function UndocumentedAssetsProvider({
  children,
}: UndocumentedAssetsProviderProps) {
  const { surgeryId } = useParams()
  const user = useUser()

  const [stopGetUndocumentedScansPolling, setStopGetUndocumentedScansPolling] =
    useState(false)

  const undocumentedScansQuery = useGetUndocumentedScansQuery(
    surgeryId,
    stopGetUndocumentedScansPolling
  )
  const [undocumentedScans, setUndocumentedScans] = useState<
    UndocumentedScan[]
  >([])

  useEffect(() => {
    if (
      undocumentedScansQuery?.data?.getUndocumentedScans &&
      !undocumentedScansQuery?.loading &&
      !undocumentedScansQuery?.error
    ) {
      setUndocumentedScans(
        undocumentedScansQuery?.data?.getUndocumentedScans.map((item) => {
          if (item.isExplanted)
            return {
              ...item,
              assetType: 'explanted',
              ...(item.isExplanted && { originalAssetType: item.assetType }),
            }
          else return item
        })
      )
    }
  }, [
    undocumentedScansQuery?.loading,
    undocumentedScansQuery?.error,
    undocumentedScansQuery?.refetch,
    undocumentedScansQuery?.data?.getUndocumentedScans,
  ])

  const [addUndocumentedScan, { reset: resetAddMutation, loading, error }] =
    useAddUndocumentedScanMutation(surgeryId ?? '', user.user?.id!)

  const [deleteUndocumentedScan] = useDeleteUndocumentedScanMutation(surgeryId)

  const deleteScans = useCallback((scanIds: string[]) => {
    deleteUndocumentedScan({
      variables: {
        scanIds: scanIds,
      },
    })
  }, [])

  const value = useMemo(
    () => ({
      undocumentedScans,
      isLoadingUndocumentedList: loading,
      isError: error,
      undocumentedScanCount: undocumentedScans.length,
      addUndocumentedScan,
      undocumentedScansQuery,
      resetAddMutation,
      deleteScans,
      setUndocumentedScans,
      setStopGetUndocumentedScansPolling,
    }),
    [
      addUndocumentedScan,
      error,
      loading,
      resetAddMutation,
      undocumentedScans,
      undocumentedScansQuery,
      deleteScans,
      setUndocumentedScans,
    ]
  )

  return (
    <UndocumentedAssetsContext.Provider value={value}>
      {children}
    </UndocumentedAssetsContext.Provider>
  )
}

export const useUndocumentedAssetsContext = () => {
  const context = React.useContext(UndocumentedAssetsContext)
  if (context === undefined) {
    throw new Error(
      'useUndocumentedAssetsContext must be used within a UndocumentedAssetsProvider'
    )
  }
  return context
}
