import { CircularProgress, Typography } from '@mui/material'
import { FlipCameraIos } from '@mui/icons-material'

import { LoadingButton } from 'components/mui'

import { useSPDCortexScannerContext } from '../SPDCortexScanner.context'

import './SPDCortexVideo.scss'
import { useSPDCortexVideo } from './SPDCortexVideo.logic'
import classNames from 'classnames'

const SPDCortexVideo = ({
  onScan,
  isEnabled = true,
}: {
  onScan: (newScan: CortexDecoder.CDResult) => void
  isEnabled?: boolean
}) => {
  const { handleToggleCameraMenu, isCameraMenuOpen, handleCameraChange } =
    useSPDCortexVideo({
      onScan,
      isEnabled,
    })
  const { cameras, selectedCamera, videoRef } = useSPDCortexScannerContext()

  return (
    <>
      {cameras.length === 0 || !selectedCamera ? (
        <CircularProgress />
      ) : (
        <div className="spd-cortex-video_wrapper">
          <div className="camera-select_container">
            <LoadingButton
              variant="contained"
              loading={false}
              onClick={handleToggleCameraMenu}
              startIcon={<FlipCameraIos />}
              className="camera-switch"
            >
              Switch Camera
            </LoadingButton>

            {isCameraMenuOpen && (
              <div className="camera-options">
                {cameras.map((camera: any) => (
                  <div
                    key={camera.deviceId}
                    className={classNames('camera-option', {
                      active: camera.deviceId === selectedCamera?.deviceId,
                    })}
                    onClick={() => handleCameraChange(camera)}
                  >
                    <Typography className="label">{camera.label}</Typography>
                    <Typography className="type">
                      {camera?.cameraType}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="scanner_outline rectangle" />

          <video
            ref={videoRef}
            id="video"
            width={window.innerWidth}
            height={window.innerHeight}
            playsInline
            autoPlay
          />
        </div>
      )}
    </>
  )
}

export default SPDCortexVideo
