import { HeadCell } from 'components/molecules/DataTable/TableHeader'

export const headCells: HeadCell[] = [
  {
    id: 'date',
    label: 'Date',
    numeric: true,
    sx: {
      width: '120px',
    },
  },
  {
    id: 'patient',
    label: 'Patient',
    numeric: false,
  },
  {
    id: 'caseId',
    label: 'Case ID',
    numeric: false,
  },
  {
    id: 'mrn',
    label: 'MRN',
    numeric: true,
  },
  {
    id: 'surgeon',
    label: 'Surgeon',
    numeric: false,
  },
  {
    id: 'procedureType',
    label: 'Procedure',
    numeric: false,
  },
  {
    id: 'totalSurgeryCost',
    label: 'Total Cost',
    numeric: true,
  },
  {
    id: 'download',
    label: 'Download',
    numeric: false,
  },
  {
    id: 'caseDetails',
    label: 'Case Details',
    numeric: false,
  },
]
