import {
  useEditSubTrayItemMutation,
  useGetSubTrayItemById,
} from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { Typography } from '@mui/material'
import { QrCode2 } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { SubTrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useEditTray = () => {
  const navigate = useNavigate()
  const { trayId, subTrayItemId } = useParams()
  const { pathname } = useLocation()

  const {
    setTrayScrews,
    trayScrews,
    isLoadingWithUDIs,
    setIsLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
    trayPlates,
    setTrayPlates,
  } = useTrayMap()
  const { isLoadMultipleBoxVisible } = useSPDScan()
  const { setParLevel } = useSPD()
  const { loading, error, subTrayItem, getSubTrayItem, refetch } =
    useGetSubTrayItemById()
  const { editSubTrayItem } = useEditSubTrayItemMutation()

  const [isInEditMode, setIsInEditMode] = useState<boolean>(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [trayDetails, setTrayDetails] = useState<
    SubTrayItemProductDetails | undefined
  >(undefined)

  useEffect(() => {
    getSubTrayItem({ variables: { subTrayItemId: Number(subTrayItemId) } })
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTrayItemId, pathname])

  useEffect(() => {
    if (subTrayItem) {
      setTrayDetails(extractSubTrayJSONData(subTrayItem))
    }
  }, [subTrayItem])

  useEffect(() => {
    if (subTrayItemId && !loading && !error && subTrayItem && trayDetails) {
      setTrayScrews(trayDetails.screws || [])
      setTrayPlates(trayDetails.plates || [])

      setParLevel(trayDetails.parLevel || 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, loading, subTrayItemId, error, subTrayItem, trayDetails])

  const handleEditModeChange = () => {
    setIsInEditMode((prevMode) => !prevMode)
  }

  const handleViewDetailsClick = () => {
    navigate(`../${trayId}/${subTrayItemId}`)
  }

  const handleConfirmationClick = (isRejecting?: boolean) => {
    if (isRejecting) {
      setIsRejecting(isRejecting)
    }

    setIsConfirmationOpen(true)
  }

  const handleLoadWithUDIsClick = () => {
    setIsLoadingWithUDIs((prev) => !prev)
  }

  const handleConfirmationClose = () => {
    setIsRejecting(false)
    setIsConfirmationOpen(false)
  }

  const handleUserConfirmation = async () => {
    if (!subTrayItem) {
      return
    }
    setIsUpdating(true)

    try {
      const newProductDetails = JSON.stringify({
        ...trayDetails,
        screws: trayScrews,
        plates: trayPlates,
      })

      const editResult = await editSubTrayItem({
        id: subTrayItem.id,
        productDetails: newProductDetails,
      })

      if (editResult) {
        handleViewDetailsClick()
        setTrayDetails((prev) =>
          prev
            ? {
                ...prev,
                screws: trayScrews,
                plates: trayPlates,
              }
            : undefined
        )
        enqueueSnackbar('Sub tray updated successfully', { variant: 'success' })
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Failed to update sub tray', { variant: 'error' })
    } finally {
      setIsUpdating(false)
    }

    setIsConfirmationOpen(false)
  }

  const renderSubTrayPlates = () => {
    const plates = trayDetails?.plates || []

    return (
      <>
        {plates.map((trayPlate) => (
          <div key={trayPlate.plateName} className="sub-tray-plate_container">
            <QrCode2 className="qr-code" />
            <div className="content">
              <Typography variant="subtitle2">
                Plate Name: {trayPlate?.plateName}
              </Typography>
              <Typography variant="subtitle2">
                Device ID: {trayPlate.deviceId}
              </Typography>
            </div>
          </div>
        ))}
      </>
    )
  }

  return {
    isInEditMode,
    setIsInEditMode,
    handleEditModeChange,
    loading,
    error,
    subTrayItem,
    trayDetails,
    handleViewDetailsClick,
    isConfirmationOpen,
    setIsConfirmationOpen,
    handleConfirmationClick,
    handleLoadWithUDIsClick,
    isLoadingWithUDIs,
    isAdditionalDeviceCountModalOpen,
    isRejecting,
    handleConfirmationClose,
    handleUserConfirmation,
    isUpdating,
    renderSubTrayPlates,
    trayScrews,
    trayPlates,
    isLoadMultipleBoxVisible,
  }
}

export default useEditTray
