import { matchMiddleFaceProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import { matchUpperFaceProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'
import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { matchProduct } from 'views/DigitalTrayMapping/AssignedDigitalTrays/TrayProducts/TrayProduct/ProductData/ProductData'

export const getProductMatchingFunction = (trayType: TrayType) => {
  switch (trayType) {
    case 'stryker upper face':
      return matchUpperFaceProduct
    case 'stryker middle face':
      return matchMiddleFaceProduct
    default:
      return matchProduct
  }
}
