import { createContext, useContext, useEffect, useState } from 'react'
import { AlertColor, AlertPropsColorOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { CheckCircle } from '@mui/icons-material'

import {
  BetterIDTrayScrew,
  SPDContextProps,
  SPDTray,
  TrayPlate,
} from './SPD.types'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { ItemResponse } from 'common/types'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'

const SPDContext = createContext<SPDContextProps | undefined>(undefined)

const SPDProvider = ({ children }: { children: JSX.Element }) => {
  const [trayData, setTrayData] = useState<SPDTray | null>(null)
  const [isLg, setIsLg] = useState(window.innerWidth >= 600)
  const [trayScrews, setTrayScrews] = useState<BetterIDTrayScrew[]>([])
  const [trayPlates, setTrayPlates] = useState<TrayPlate[]>([])
  const [selectedScrew, setSelectedScrew] = useState<BetterIDTrayScrew | null>(
    null
  )
  const [selectedScrews, setSelectedScrews] = useState<BetterIDTrayScrew[]>([])
  const [selectedPlate, setSelectedPlate] = useState<TrayPlate | null>(null)
  const [screwLoadModalOpen, setScrewLoadModalOpen] = useState<boolean>(false)
  const [confirmDurationModalOpen, setConfirmDurationModalOpen] =
    useState<boolean>(true)
  const [count, setCount] = useState<number>(45)
  const [displayCount, setDisplayCount] = useState<string>('00:45')
  const [chosenCount, setChosenCount] = useState<number>(45)
  const [chosenDisplayCount, setChosenDisplayCount] = useState<string>('00:45')
  const [showTrayDetails, setShowTrayDetails] = useState<boolean>(false)
  const [showSurgeryChangeModal, setShowSurgeryChangeModal] =
    useState<boolean>(false)
  const [showChooseTrayLocationModal, setShowChooseTrayLocationModal] =
    useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [snackbarState, setSnackbarState] = useState<
    OverridableStringUnion<AlertColor, AlertPropsColorOverrides> | undefined
  >('success')
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarIcon, setSnackbarIcon] = useState<JSX.Element>(
    <CheckCircle color="success" />
  )
  const [isLoadingWithUDIs, setIsLoadingWithUDIs] = useState<boolean>(true)
  const [newSubTrayImg, setNewSubTrayImg] = useState<string | null>(null)
  const [onlyUseManualWorkflow, setOnlyUseManualWorkflow] =
    useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >(undefined)
  const [selectedScrewIndices, setSelectedScrewIndices] = useState<number[]>([])
  const [parLevel, setParLevel] = useState<number>(1)

  const trayDetails = extractTrayJSONData(trayData?.trayItem as ItemResponse)
  const subTrayDetails = extractSubTrayJSONData(
    trayData?.trayItem?.subItems?.[0]
  )
  const navbarHeight = isLg ? 64 : 56

  useEffect(() => {
    const handleResize = () => {
      setIsLg(window.innerWidth >= 600)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackbarOpen(false)
  }

  return (
    <SPDContext.Provider
      value={{
        isLg,
        setIsLg,
        navbarHeight,
        trayScrews,
        setTrayScrews,
        trayPlates,
        setTrayPlates,
        selectedScrew,
        setSelectedScrew,
        selectedPlate,
        setSelectedPlate,
        selectedScrews,
        setSelectedScrews,
        screwLoadModalOpen,
        setScrewLoadModalOpen,
        count,
        setCount,
        displayCount,
        setDisplayCount,
        confirmDurationModalOpen,
        setConfirmDurationModalOpen,
        trayData,
        setTrayData,
        showTrayDetails,
        setShowTrayDetails,
        showSurgeryChangeModal,
        setShowSurgeryChangeModal,
        showChooseTrayLocationModal,
        setShowChooseTrayLocationModal,
        chosenCount,
        setChosenCount,
        chosenDisplayCount,
        setChosenDisplayCount,
        snackbarOpen,
        setSnackbarOpen,
        snackbarState,
        setSnackbarState,
        snackbarMessage,
        setSnackbarMessage,
        handleSnackbarClose,
        snackbarIcon,
        setSnackbarIcon,
        isLoadingWithUDIs,
        setIsLoadingWithUDIs,
        trayDetails,
        subTrayDetails,
        newSubTrayImg,
        setNewSubTrayImg,
        onlyUseManualWorkflow,
        setOnlyUseManualWorkflow,
        activeStep,
        setActiveStep,
        selectedLocationId,
        setSelectedLocationId,
        selectedScrewIndices,
        setSelectedScrewIndices,
        parLevel,
        setParLevel,
      }}
    >
      {children}
    </SPDContext.Provider>
  )
}

const useSPD = () => {
  const context = useContext(SPDContext)

  if (context === undefined) {
    throw new Error('useSPD must be used with SPDProvider')
  }

  return context
}

export { useSPD, SPDProvider }
