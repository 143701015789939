import { Grid, Button } from '@mui/material'
import { FilterButtonBox } from 'components/molecules/FilterButtonBox/FilterButtonBox'
import './FilterBar.scss'
import { DateRangePicker } from 'components/molecules/DateRangePicker/DateRangePicker'
import { FilterProps } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { PictureAsPdfOutlined } from '@mui/icons-material'

interface FilterBarProps extends FilterProps {
  renderDatePicker?: boolean
  onPDFSettingsClick?: () => void
}

export const FilterBar = ({
  condenseButtons,
  renderAssetTypeFilter,
  renderMrnFilter,
  renderPatientNameFilter,
  renderSurgeonFilter,
  renderProcedureTypeFilter,
  renderDepartmentFilter,
  renderDatePicker,
  renderDeviceDescription,
  renderDisposition,
  isDisabled,
  isLoading,
  hasPDFSettings = false,
  onPDFSettingsClick,
}: FilterBarProps) => {
  const isMobile = useIsMobile()

  return (
    <Grid
      container
      className={`filter-bar-container ${isMobile ? 'is-mobile' : ''}`}
      rowSpacing={2}
    >
      <div className="filter-bar-container-left">
        {hasPDFSettings && (
          <Button
            onClick={onPDFSettingsClick}
            startIcon={<PictureAsPdfOutlined />}
            className="pdf-settings-button"
            sx={{ whiteSpace: 'nowrap' }}
            fullWidth
            disabled={isLoading}
          >
            PDF Settings
          </Button>
        )}
        <FilterButtonBox
          condenseButtons={condenseButtons}
          renderAssetTypeFilter={renderAssetTypeFilter}
          renderMrnFilter={renderMrnFilter}
          renderPatientNameFilter={renderPatientNameFilter}
          renderSurgeonFilter={renderSurgeonFilter}
          renderProcedureTypeFilter={renderProcedureTypeFilter}
          renderDepartmentFilter={renderDepartmentFilter}
          renderDeviceDescription={renderDeviceDescription}
          renderDisposition={renderDisposition}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
      {renderDatePicker && (
        <div className="filter-bar-container-right">
          <DateRangePicker />
        </div>
      )}
    </Grid>
  )
}
