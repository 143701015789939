import { CircularProgress, IconButton, Typography } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import CustomDatePicker from 'components/molecules/DatePicker/DatePicker'

import { useListOfSurgeries } from './ListOfSugeries.logic'

import { ListOfSurgeriesProps } from './ListOfSurgeries.types'
import './ListOfSurgeries.scss'
import SurgeryBox from 'components/molecules/SurgeryBox/SurgeryBox'

export const ListOfSurgeries = ({
  traySurgeryId,
  handleSurgeryChange,
  trayData,
}: ListOfSurgeriesProps) => {
  const {
    loading,
    sortedSurgeries,
    caseInformation,
    isNurse,
    handleBackDay,
    handleForwardDay,
    afterDate,
    isSurgerySelected,
  } = useListOfSurgeries({ traySurgeryId })

  return (
    <div
      className="list-of-surgeries_container"
      data-testid="list-of-surgeries_container"
    >
      {loading && <CircularProgress />}

      <div className={`date-picker_container ${loading ? 'hidden' : ''}`}>
        <IconButton
          data-testid="list-of-surgeries_back-button"
          onClick={handleBackDay}
          size="small"
        >
          <NavigateBefore fontSize="large" />
        </IconButton>

        <CustomDatePicker
          label="Procedure List Date"
          date={afterDate}
          renderDatePicker={false}
        />

        <IconButton
          data-testid="list-of-surgeries_forward-button"
          onClick={handleForwardDay}
          size="small"
        >
          <NavigateNext fontSize="large" />
        </IconButton>
      </div>

      {sortedSurgeries?.length === 0 && !loading && (
        <Typography variant="body2">
          No surgeries available for this date
        </Typography>
      )}

      {sortedSurgeries?.map((surgery) => {
        const isSelected = isSurgerySelected(surgery._id, trayData)

        return (
          <SurgeryBox
            surgery={surgery}
            caseInformation={caseInformation}
            onClick={() => handleSurgeryChange(surgery)}
            isNurse={isNurse}
            isSelected={isSelected}
          />
        )
      })}
    </div>
  )
}
