import {
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { ErrorOutline, Fingerprint } from '@mui/icons-material'

import SPDCortexScanner from '../../../views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner'
import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import useSelectTrayIDModal from './SelectTrayIDModal.logic'
import { SelectTrayIDModalProps } from './SelectTrayIDModal.types'
import './SelectTrayIDModal.scss'

const SelectTrayIDModal = (props: SelectTrayIDModalProps) => {
  const {
    loading,
    handleTrayIDChange,
    scannedCode,
    setScannedCode,
    error,
    trayItem,
    handleConfirmTray,
    trayCategory,
    shouldBeDisabled,
  } = useSelectTrayIDModal(props)

  const { open, onClose, isAssigningFromSurgery } = props

  return (
    <Modal open={open} onClose={onClose}>
      <div className="select-tray-id-modal">
        <div className="header-box">
          <Fingerprint />

          <Typography variant="h3">
            Please Capture The Bar Code On The Implant/ Instrumentation Tray
          </Typography>
        </div>

        <div className="scanner-container">
          <SPDCortexScanner
            canScan={open}
            onScan={(code) => {
              setScannedCode(code)
            }}
          />

          <Skeleton className="skeleton" variant="rounded" animation="wave" />
        </div>

        <TextField
          id="input-with-icon-textfield"
          autoComplete="off"
          placeholder="Search Tray ID"
          fullWidth
          type="text"
          value={scannedCode}
          onChange={handleTrayIDChange}
          InputProps={{
            endAdornment: loading && (
              <CircularProgress size={22} sx={{ marginRight: 1 }} />
            ),
          }}
          variant="outlined"
        />

        {error && scannedCode.length > 0 && (
          <Typography
            variant="body2"
            sx={{
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ErrorOutline sx={{ width: '20px', height: '20px', mr: '10px' }} />{' '}
            {error.message}
          </Typography>
        )}

        {!loading && scannedCode.length > 0 && !trayItem && (
          <Typography
            variant="body2"
            sx={{
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ErrorOutline sx={{ width: '20px', height: '20px', mr: '10px' }} />{' '}
            No database results found for this tray ID.
          </Typography>
        )}

        {trayItem && scannedCode.length > 0 && !loading && !error && (
          <div className="product-container">
            <Typography variant="body2">
              <b>Tray ID:</b> {trayItem.barcode}
            </Typography>
            <Typography variant="body2">
              <b>Description:</b> {trayItem.description}
            </Typography>
            <Typography variant="body2">
              <b>Tray Category:</b> {trayCategory}
            </Typography>
          </div>
        )}

        {shouldBeDisabled && trayItem && !loading && !error && (
          <Typography
            variant="body2"
            sx={{
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ErrorOutline sx={{ width: '20px', height: '20px', mr: '10px' }} />{' '}
            {isAssigningFromSurgery
              ? 'This tray was not approved by SPD. Loaner and consigned trays need to be approved by SPD to assign them.'
              : 'You cannot use this tray category in this workflow.'}
          </Typography>
        )}

        {trayItem && !loading && (
          <LoadingButton
            disabled={loading || !scannedCode || !trayItem || shouldBeDisabled}
            onClick={handleConfirmTray}
            variant="contained"
            loading={loading}
          >
            Confirm Tray
          </LoadingButton>
        )}
      </div>
    </Modal>
  )
}

export default SelectTrayIDModal
