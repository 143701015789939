import { HeadCell } from 'components/molecules/DataTable/TableHeader'

export const COLUMN_WIDTHS: Record<string, number> = {
  assetType: 140,
  deviceDescription: 200,
  companyName: 120,
  udi: 160,
  catalogNumber: 100,
  serialNumber: 100,
  lotNumber: 100,
  implantSite: 80,
  quantity: 50,
}

export const HEAD_CELLS: HeadCell[] = [
  { id: 'assetType', label: 'Product Type', numeric: false },
  {
    id: 'deviceDescription',
    label: 'Device Details',
    numeric: false,
  },
  { id: 'companyName', label: 'Manufacturer', numeric: false },
  {
    id: 'udi',
    label: 'UDI',
    numeric: false,
  },
  {
    id: 'catalogNumber',
    label: 'Catalog No.',
    numeric: false,
  },
  {
    id: 'serialNumber',
    label: 'Serial No',
    numeric: false,
  },
  {
    id: 'lotNumber',
    label: 'Lot No.',
    numeric: false,
  },
  { id: 'implantSite', label: 'Implant Site', numeric: false },
  {
    id: 'quantity',
    label: 'Quantity',
    numeric: false,
  },
]
