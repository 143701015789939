import { Box, Button, Typography } from '@mui/material'

import { LoadingButton } from 'components/mui'

import TrayMapSelector from 'components/organisms/TrayMapSelector/TrayMapSelector'
import useTrayContentLogic from './TrayContent.logic'
import { useGrayScaleLogic } from 'components/organisms/GrayScale/GrayScale.logic'

import './TrayContent.scss'

interface TrayContentProps {
  trayIndex: number
}

const TrayContent: React.FC<TrayContentProps> = ({ trayIndex }) => {
  const { subTray, saveChanges, navigateBack } = useTrayContentLogic(trayIndex)
  const {
    loadAllScrews,
    increaseAllPlatesBy,
    isAllScrewsLoaded,
    isAllPlatesLoaded,
    screwAnnotations,
    plateAnnotations,
    drillAnnotations,
  } = useGrayScaleLogic({ trayType: subTray.trayType })

  return (
    <div className="loaner-tray-analysis-content_wrapper">
      <div className="header-section">
        <div className="header-content">
          <Box>
            <Typography variant="h4" lineHeight={1.2} className="main-title">
              Edit Tray Content
            </Typography>
            <Typography
              variant="body1"
              fontSize={14}
              textTransform="capitalize"
              className="tray-info"
            >
              Tray Type: <strong>{subTray.trayType}</strong>
            </Typography>
          </Box>
        </div>
      </div>

      <div className="content-section">
        <div className="analysis-panels">
          {subTray.trayImg && (
            <div className="panel image-panel">
              <div className="panel-header">
                <Typography variant="h6">Reference Image</Typography>
              </div>
              <div className="panel-content">
                <img
                  src={subTray.trayImg}
                  alt="Tray Reference"
                  className="tray-image"
                />
              </div>
            </div>
          )}

          <div className="panel map-panel">
            <div className="panel-header tray-map-header">
              <Typography variant="h6">Digital Tray Map</Typography>
              <div className="load-all-container">
                {screwAnnotations.length > 0 && (
                  <Button
                    disabled={isAllScrewsLoaded}
                    onClick={loadAllScrews}
                    variant="outlined"
                  >
                    Load All Screws
                  </Button>
                )}
                {(plateAnnotations.length > 0 ||
                  drillAnnotations.length > 0) && (
                  <Button
                    disabled={isAllPlatesLoaded}
                    onClick={() => increaseAllPlatesBy(1)}
                    variant="outlined"
                  >
                    Increase All Plates
                  </Button>
                )}
              </div>
            </div>
            <div className="panel-content">
              <TrayMapSelector
                trayType={subTray.trayType}
                loadWithUDIs={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="action-buttons">
        <Button
          onClick={navigateBack}
          className="cancel-button"
          variant="text"
          color="primary"
        >
          Go Back
        </Button>
        <LoadingButton
          onClick={saveChanges}
          className="confirm-button"
          variant="contained"
          color="primary"
        >
          Save Changes
        </LoadingButton>
      </div>
    </div>
  )
}

export default TrayContent
