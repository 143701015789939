import { useEffect, useState } from 'react'

import useSubmitTrayCapture from '../SubmitTrayCapture.logic'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'
import { useAssignedDigitalTrays } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'

const useSubmitImageDialog = ({
  trayImage,
  setTrayImage,
}: {
  trayImage: string
  setTrayImage: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { submitImage, cancelSubmit, setAnalysisFileName } =
    useSubmitTrayCapture()
  const { selectedTray } = useAssignedDigitalTrays()
  const subTrayDetails = extractSubTrayJSONData(selectedTray?.subItems?.[0])
  const trayType = subTrayDetails?.trayType || 'stryker screw caddy'

  const [isConfirmBaseOpen, setIsConfirmBaseOpen] = useState<boolean>(false)
  const [scale, setScale] = useState(1)

  useEffect(() => {
    const fileName = getAnalysisJSONFileName(trayType)
    setAnalysisFileName(fileName)
  }, [trayType, setAnalysisFileName])

  const rotateImage = (direction: 'left' | 'right' = 'right') => {
    if (trayImage) {
      return new Promise<void>((resolve) => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

          canvas.width = img.height
          canvas.height = img.width

          ctx.translate(canvas.width / 2, canvas.height / 2)
          ctx.rotate(direction === 'right' ? Math.PI / 2 : -Math.PI / 2)
          ctx.drawImage(img, -img.width / 2, -img.height / 2)

          setTrayImage(canvas.toDataURL())
          resolve()
        }
        img.src = trayImage
      })
    }
  }

  const handleRotate = async (direction: 'left' | 'right') => {
    await rotateImage(direction)
    
    setScale(1)
  }

  const handleConfirmBaseModalClose = () => {
    setIsConfirmBaseOpen(false)
  }

  const handleSubmit = () => {
    handleConfirmBaseModalClose()
    submitImage(trayImage)
  }

  const handleZoomIn = () => setScale(prev => Math.min(prev + 0.25, 3))
  const handleZoomOut = () => setScale(prev => Math.max(prev - 0.25, 0.5))

  return {
    isConfirmBaseOpen,
    setIsConfirmBaseOpen,
    rotateImage,
    handleSubmit,
    handleConfirmBaseModalClose,
    cancelSubmit,
    scale,
    handleZoomIn,
    handleZoomOut,
    handleRotate,
  }
}

export default useSubmitImageDialog
