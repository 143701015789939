import { Drawer } from 'vaul'

import { DialogDrawerProps } from './DialogDrawer.types'
import './DialogDrawer.scss'
import classNames from 'classnames'
import { useIsTablet } from 'lib/utils/mediaQueries'

const DialogDrawer = ({
  children,
  open,
  onClose,
  height,
  isHalfWidth = false,
  overflowY,
  dismissable = true,
  modal = true,
}: DialogDrawerProps) => {
  const isMobile = useIsTablet()
  return (
    <Drawer.Root
      shouldScaleBackground
      open={open}
      onClose={onClose}
      dismissible={dismissable}
      modal={modal}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="drawer-overlay" />
        <Drawer.Content
          className={classNames('drawer-content', {
            'is-half-width': isHalfWidth && !isMobile,
          })}
          style={{
            height: height || 'auto',
          }}
        >
          <div
            className="container"
            style={{ overflowY: overflowY ? 'scroll' : 'hidden' }}
          >
            <div className="drag-bar" />
            <div className="content-wrapper">{children}</div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  )
}

export default DialogDrawer
