import {
  Box,
  Button,
  DialogProps,
  Modal as ModalComponent,
  Typography,
} from '@mui/material'
import './Modal.scss'
import { NotificationsNone } from '@mui/icons-material'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { FC } from 'react'
import { ButtonSet } from 'components/atoms/ButtonSet/ButtonSet'
import { CustomModalProps } from './Modal.types'
import classNames from 'classnames'

const Modal: FC<DialogProps> = ({
  open,
  onClose,
  children,
  className,
  ...props
}) => (
  <ModalComponent
    open={open}
    onClose={onClose}
    className={classNames(`custom-modal ${className}`)}
    {...props}
  >
    <>{children}</>
  </ModalComponent>
)

export default Modal

export const CustomModal = ({
  className,
  open,
  secondaryButtonAction,
  secondaryButtonText = 'Close',
  dataTestId,
  children,
  header,
  primaryButtonDisabled,
  isLoadingState,
  handleOnSubmit,
  subheader,
  subSubHeader,
  useAnalyzerState,
  isSubmitting,
  primaryButtonText = 'Submit',
  analyzingStateNode,
  icon = <NotificationsNone />,
  closeButtonOnly = false,
  fitContentWidth = false,
  onCloseTakeSecondaryButtonAction = true,
  onSelectAll,
  withSelectAll = false,
  areAllSelected = false,
}: CustomModalProps) => {
  const isMobile = useIsMobile()

  return (
    <ModalComponent
      className={className}
      data-testid={`${dataTestId}-modal`}
      open={open}
      onClose={
        onCloseTakeSecondaryButtonAction ? secondaryButtonAction : undefined
      }
      disableRestoreFocus
    >
      <Box
        className={classNames('parent-modal-box', {
          'is-mobile': isMobile,
          'fit-content': fitContentWidth,
        })}
      >
        <Box className="select-all-box-wrapper">
          <Box className="header-box">
            {icon}
            <Typography variant="h3" sx={{ textTransform: 'none' }}>
              {header}
            </Typography>
          </Box>

          {withSelectAll && (
            <Button
              size="small"
              sx={{ mt: 0.25, color: 'var(--primary-contrast-text)' }}
              onClick={() => {
                onSelectAll?.()
              }}
            >
              {areAllSelected ? 'Unselect All' : 'Select All'}
            </Button>
          )}
        </Box>

        {subheader && (
          <Typography
            variant="h6"
            className={`subheader-box ${isMobile ? 'isMobile' : ''}`}
          >
            {subheader}
          </Typography>
        )}

        {subSubHeader && <Box>{subSubHeader}</Box>}

        <Box className={`child-box`} data-testid={`${dataTestId}-children`}>
          {children}
        </Box>
        {useAnalyzerState && isSubmitting ? (
          <Box className={'analyze-state'}>{analyzingStateNode}</Box>
        ) : (
          <ButtonSet
            secondaryButtonText={secondaryButtonText}
            dataTestId={dataTestId}
            isLoadingState={isLoadingState}
            secondaryButtonAction={secondaryButtonAction}
            primaryButtonDisabled={primaryButtonDisabled}
            handleOnSubmit={handleOnSubmit}
            secondaryButtonDisabled={isLoadingState}
            primaryButtonText={primaryButtonText}
            closeButtonOnly={closeButtonOnly}
          />
        )}
      </Box>
    </ModalComponent>
  )
}
