import { useEffect, useState } from 'react'
import { useControls, useTransformContext } from 'react-zoom-pan-pinch'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

const useTrayProcedureEditModeLogic = () => {
  const { zoomIn, zoomOut, setTransform, resetTransform } = useControls()
  const transformContext = useTransformContext()

  const { selectedScrewIndices } = useSPD()

  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState<boolean>(false)
  const [isReferenceOpen, setIsReferenceOpen] = useState<boolean>(false)
  const [imageScale, setImageScale] = useState<number>(1)

  const selectedScrewsCount = selectedScrewIndices.length

  useEffect(() => {
    resetTransform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleKeyboard = (e: KeyboardEvent) => {
      if (e.ctrlKey || e.metaKey) {
        switch (e.key) {
          case '=':
          case '+':
            e.preventDefault()
            zoomIn(0.5)
            break
          case '-':
            e.preventDefault()
            zoomOut(0.5)
            break
          case '0':
            e.preventDefault()
            resetTransform()
            break
          // Arrow keys for panning
          case 'ArrowUp':
            e.preventDefault()
            handlePan('up')
            break
          case 'ArrowDown':
            e.preventDefault()
            handlePan('down')
            break
          case 'ArrowLeft':
            e.preventDefault()
            handlePan('left')
            break
          case 'ArrowRight':
            e.preventDefault()
            handlePan('right')
            break
        }
      }
    }

    window.addEventListener('keydown', handleKeyboard)
    return () => window.removeEventListener('keydown', handleKeyboard)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePan = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (transformContext.mounted) {
      const panStep = 130
      const {
        positionX,
        positionY,
        previousScale: scale,
      } = transformContext.getContext().state

      switch (direction) {
        case 'up':
          setTransform(positionX, positionY - panStep, scale)
          break
        case 'down':
          setTransform(positionX, positionY + panStep, scale)
          break
        case 'left':
          setTransform(positionX - panStep, positionY, scale)
          break
        case 'right':
          setTransform(positionX + panStep, positionY, scale)
          break
      }
    }
  }

  const handleZoomIn = () => {
    setImageScale((prev) => Math.min(prev + 0.5, 3))
  }

  const handleZoomOut = () => {
    setImageScale((prev) => Math.max(prev - 0.5, 0.5))
  }

  return {
    handlePan,
    zoomIn,
    zoomOut,
    resetTransform,
    handleZoomIn,
    handleZoomOut,
    isReferenceOpen,
    setIsReferenceOpen,
    imageScale,
    isImagePreviewOpen,
    setIsImagePreviewOpen,
    selectedScrewsCount,
  }
}

export default useTrayProcedureEditModeLogic
