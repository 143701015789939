import classNames from 'classnames'

import { FC } from 'react'
import { Typography } from '@mui/material'
import { useShareProductLogic } from './ShareProduct.logic'
import { TShareProductProps } from './ShareProduct.types'
import './ShareProduct.scss'
import SenderButton from './SenderButton'

export const ShareProduct: FC<TShareProductProps> = (props) => {
  const { error, loading, reps, assetDetails } = props
  const { receivedReps } = useShareProductLogic(props)

  const repsDataExist = reps.length > 0

  return (
    <div
      className={classNames('share-product-main-container', {
        'reps-data-exist': repsDataExist,
      })}
    >
      {error ? (
        <Typography variant="body2" color="error" fontWeight="medium">
          Error loading reps for {assetDetails.assetManufacturer}
        </Typography>
      ) : loading ? (
        <Typography variant="body2" color="text.secondary">
          Loading reps...
        </Typography>
      ) : reps.length === 0 ? (
        <Typography variant="body2" color="error" fontWeight="medium">
          No Reps found for {assetDetails.assetManufacturer}
        </Typography>
      ) : (
        reps.map((rep) => {
          const isEmailReceived =
            receivedReps?.emails?.includes(rep.email) || false
          const isMobileReceived =
            receivedReps?.mobiles?.includes(rep.mobile) || false

          return (
            <div className="rep-container" key={rep.id}>
              <Typography variant="h4">
                {rep.firstName} {rep.lastName}
              </Typography>
              {rep.email && (
                <div className="info-row">
                  <Typography
                    className={classNames('email-mobile', {
                      'is-received': isEmailReceived,
                    })}
                    variant="subtitle2"
                  >
                    {rep.email}
                    {isEmailReceived ? <span>✅</span> : null}
                  </Typography>
                  <SenderButton
                    aria-label="send record by email"
                    rep={rep}
                    isSms={false}
                    disabled={!rep.email}
                    receivedReps={receivedReps}
                    assetDetails={assetDetails}
                  />
                </div>
              )}
              {rep.mobile && (
                <div className="info-row">
                  <Typography
                    className={classNames('email-mobile', {
                      'is-received': isMobileReceived,
                    })}
                    variant="subtitle2"
                  >
                    {rep.mobile}
                    {isMobileReceived ? <span>✅</span> : null}
                  </Typography>
                  <SenderButton
                    aria-label="send record by text message"
                    rep={rep}
                    isSms={true}
                    disabled={!rep.mobile}
                    receivedReps={receivedReps}
                    assetDetails={assetDetails}
                  />
                </div>
              )}
            </div>
          )
        })
      )}
    </div>
  )
}
