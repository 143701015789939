import { dispositionObject } from 'common/disposition'
import { z } from 'zod'

export const useDispositionValidationSchema = () => {
  const dispositionSchema = z
    .object(dispositionObject)
    .superRefine((data, ctx) => {
      if (
        data.wastedConsumablesCount === null ||
        data.wastedConsumablesCount === undefined
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Items wasted cannot be empty',
          path: ['wastedConsumablesCount'],
        })
      }

      if (
        data.assetType === 'biological' &&
        data.samplePreparation === 'OTHER' &&
        !data.samplePreparationNote
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Sample preperation note cannot be empty',
          path: ['samplePreparationNote'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        data.implantStatus === 'EXPLANTED' &&
        (!data.explantedReason || data.explantedReason?.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explant reason cannot be empty',
          path: ['explantedReason'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        data.implantStatus === 'EXPLANTED' &&
        data.explantedReason === 'OTHER' &&
        (!data.explantedReasonNote || data.explantedReasonNote?.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Other reason cannot be empty',
          path: ['explantedReasonNote'],
        })
      }
      if (
        data.assetType === 'consumable' &&
        (data.count === undefined || data.count < 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.count && data.count < 0
              ? 'Number of Items used cannot be negative'
              : 'Number of Items used cannot be empty',
          path: ['count'],
        })
      }
      if (
        data.assetType === 'consumable' &&
        data.wastedConsumablesCount === 0 &&
        data.count === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Both items Used and items wasted cannot be zero',
          path: ['count'],
        })

        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Both items Used and items wasted cannot be zero',
          path: ['wastedConsumablesCount'],
        })
      }
      if (!data.assetType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Product Type cannot be empty',
          path: ['assetType'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        (!data.implantStatus || data.implantStatus.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Disposition cannot be empty',
          path: ['implantStatus'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        !data.implantStatus &&
        !data.implantSite
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explant site cannot be empty',
          path: ['implantSite'],
        })
      }
      if (
        ((data.assetType !== 'consumable' &&
          data.implantStatus === 'IMPLANTED') ||
          data.implantStatus === 'EXPLANTED') &&
        !data.implantSite
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.implantStatus === 'IMPLANTED'
              ? 'Implant site cannot be empty'
              : 'Explant site cannot be empty',
          path: ['implantSite'],
        })
      }
      if (
        (data.assetType !== 'consumable' &&
          data.implantStatus === 'WASTED' &&
          !data.wastedReason) ||
        (data.assetType === 'consumable' &&
          data.wastedConsumablesCount &&
          data.wastedConsumablesCount > 0 &&
          !data.wastedReason)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Wasted reason cannot be empty',
          path: ['wastedReason'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        (data.implantSite === 'Mouth' || data.implantSite === 'Other') &&
        !data.siteLocation
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.implantStatus === 'IMPLANTED'
              ? 'Implant location cannot be empty'
              : 'Explant location cannot be empty',
          path: ['siteLocation'],
        })
      }
    })

  return {
    dispositionSchema,
  }
}
