import { Box, Typography } from '@mui/material'
import Modal from 'components/molecules/Modal/Modal'
import { Player } from '@lottiefiles/react-lottie-player'
import { LoadingButton } from '@mui/lab'
import { useSearchParams } from 'react-router-dom'

interface AtomLoadingModalProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
}

const AtomLoadingModal = ({
  open,
  onClose,
  onCancel,
}: AtomLoadingModalProps) => {
  const [searchParams] = useSearchParams()
  const imageOnly = searchParams.get('mode') === 'image-only'

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: '95%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'transparent',
          borderRadius: '10px',
          p: '10px 30px',
        }}
      >
        <Player
          autoplay
          loop
          src="https://lottie.host/70fc766e-3d02-4e03-be88-a640ee0daaed/ewUXiG4fm5.json"
          style={{ height: '250px', width: '250px' }}
        />

        <Typography
          fontSize={15}
          fontWeight={300}
          color="#FFFFFF"
          fontStyle="italic"
        >
          {imageOnly ? 'Saving your image...' : 'Analyzing your tray...'}
        </Typography>

        <LoadingButton
          variant="contained"
          disabled={false}
          loading={false}
          onClick={onCancel}
          sx={{
            mt: '20px',
          }}
        >
          Cancel
        </LoadingButton>
      </Box>
    </Modal>
  )
}

export default AtomLoadingModal
