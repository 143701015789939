import { Link, Routes, Route } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import { ArrowBack, ExpandMore } from '@mui/icons-material'

import TrayComponent from './TrayComponent/TrayComponent'
import TrayDetails from '../TrayDetails/TrayDetails'
import useAssignedDigitalTraysLogic from './AssignedDigitalTrays.logic'
import { ItemResponse, SubItem } from 'common/types'
import SelectTrayIDModal from 'components/organisms/SelectTrayIDModal/SelectTrayIDModal'
import InventoryProvider from 'lib/apollo/inventory-config'
import './AssignedDigitalTrays.scss'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { AssignedDigitalTraysProps } from './AssignedDigitalTrays.types'

const AssignedDigitalTraysContent = ({
  surgery,
}: AssignedDigitalTraysProps) => {
  const {
    error,
    loading,
    modalKey,
    filteredTrays,
    selectTrayOpen,
    isAssigningTray,
    setSelectTrayOpen,
    handleAssignDigitalTray,
  } = useAssignedDigitalTraysLogic({ surgery })

  return (
    <div className="assigned-digital-trays-container">
      <Button
        className="header-button"
        startIcon={<ArrowBack />}
        variant="text"
        size="small"
        to=".."
        component={Link}
      >
        BACK
      </Button>

      <div className="header-content">
        <Typography variant="h2">Assigned Digital Trays</Typography>
        <Button variant="contained" onClick={() => setSelectTrayOpen(true)}>
          Assign trays
        </Button>
      </div>

      <div className="trays-content">
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error.message}</Typography>}
        {filteredTrays.length === 0 && !loading && (
          <Typography>No trays assigned to this surgery</Typography>
        )}

        {filteredTrays.map((tray: ItemResponse) => {
          const trayDetails = extractTrayJSONData(tray)
          const hasSubItems = tray.subItems && tray.subItems.length > 1
          const showParenTrayImg = hasSubItems && trayDetails?.trayImage

          return (
            <Accordion key={tray.id} defaultExpanded className="tray-accordion">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordion-summary"
              >
                <div className="summary-content">
                  {showParenTrayImg && (
                    <img
                      src={trayDetails?.trayImage}
                      alt={tray.description}
                      className="parent-tray-image"
                    />
                  )}
                  <div className="tray-info">
                    <Typography className="tray-title">
                      {tray.description}
                    </Typography>
                    <Typography className="tray-barcode">
                      {tray.barcode}
                    </Typography>
                  </div>
                  <Typography className="tray-count">
                    {tray.subItems?.length || 0}
                  </Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails className="accordion-details">
                <div className="sub-trays-grid">
                  {tray.subItems?.map((subTray: SubItem) => (
                    <TrayComponent
                      key={subTray.id}
                      parentTray={tray}
                      subTray={subTray}
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>

      <InventoryProvider>
        <SelectTrayIDModal
          key={modalKey}
          open={selectTrayOpen}
          onClose={() => setSelectTrayOpen(false)}
          isAssigningFromSurgery
          handleAssignDigitalTray={handleAssignDigitalTray}
          isAssigningTray={isAssigningTray}
        />
      </InventoryProvider>
    </div>
  )
}

const AssignedDigitalTrays = ({ surgery }: AssignedDigitalTraysProps) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AssignedDigitalTraysContent surgery={surgery} />}
      />
      <Route path="tray-details/:subTrayId" element={<TrayDetails />} />
    </Routes>
  )
}

export default AssignedDigitalTrays
