import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { ZoomIn, ZoomOut, Close } from '@mui/icons-material'
import './ViewImageDialog.scss'
import { useState } from 'react'

import useSubmitImageDialog from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/SubmitImageDialog/SubmitImageDialog.logic'
import { ViewImageDialogProps } from './ViewImageDialog.types'

const ViewImageDialog = ({
  open,
  handleClose,
  trayImage,
}: ViewImageDialogProps) => {
  const { scale, handleZoomIn, handleZoomOut } = useSubmitImageDialog({
    trayImage,
    setTrayImage: () => {},
  })
  const [imageLoading, setImageLoading] = useState(true)

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className="view-image-dialog_wrapper"
      sx={{
        '& .MuiDialog-paper': {
          height: '100%',
          minHeight: '-webkit-fill-available',
        },
      }}
    >
      <Box className="dialog-content">
        <Box className="header">
          <Box className="header-left">
            <IconButton className="close-button" onClick={handleClose}>
              <Close />
            </IconButton>
            <Typography className="title">View Tray Image</Typography>
          </Box>
        </Box>

        <Box className="main-content">
          <Box className="image-container">
            <Box
              className="image-wrapper"
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={trayImage}
                alt="Tray Preview"
                className={`preview-image ${imageLoading ? 'loading' : ''}`}
                style={{
                  transform: `translate(-50%, -50%) scale(${scale})`,
                }}
                onLoad={() => setImageLoading(false)}
              />
              {imageLoading && <div className="skeleton-loader" />}
            </Box>
          </Box>

          <Box className="bottom-controls">
            <IconButton
              className="control-button"
              onClick={handleZoomOut}
              disabled={scale <= 0.5}
            >
              <ZoomOut />
            </IconButton>
            <IconButton
              className="control-button"
              onClick={handleZoomIn}
              disabled={scale >= 3}
            >
              <ZoomIn />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ViewImageDialog
