import { useUser } from 'app/User'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { AssetType } from 'lib/context/AssetTypeContext/AssetTypeContext.types'
import { useState } from 'react'

const useScannerActionsLogic = () => {
  const { isRep } = useUser()

  const assetTypes = isRep
    ? ['IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT', 'IMPLANTABLE OTHER']
    : [
        'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT',
        'IMPLANTABLE OTHER',
        'CONSUMABLE PRODUCT',
        'IMPLANTABLE BIOLOGIC',
        'EXPLANTED',
      ]

  const { assetType, handleChangeAssetType } = useAssetType()
  const initialIndex = assetTypes.indexOf(assetType || '')

  const [currentIndex, setCurrentIndex] = useState(
    initialIndex >= 0 ? initialIndex : 0
  )

  const handleSwipeChangeIndex = (index: number) => {
    setCurrentIndex(index)
    handleChangeAssetType(assetTypes[index] as AssetType, true)
  }

  const handleChangeIndex = (direction: 'right' | 'left') => {
    setCurrentIndex((prev) => {
      const newIndex =
        direction === 'left'
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, assetTypes.length - 1)
      handleChangeAssetType(assetTypes[newIndex] as AssetType, true)
      return newIndex
    })
  }

  return {
    assetTypes,
    currentIndex,
    handleChangeIndex,
    handleSwipeChangeIndex,
  }
}

export default useScannerActionsLogic
