import React from 'react'

// MUI
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  CircularProgress,
  Typography,
  Box,
  ButtonBase,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

// Components
import useGetInventoryReportDetails from 'lib/services/api/product-service/getInventoryReportDetails'
import Modal from 'components/molecules/Modal/Modal'

// Style
import './DetailsModal.scss'

// Other
import moment from 'moment'

// Context
import { useInventoryReport } from '../InventoryReportProvider'

const DetailsModal = () => {
  const { detailsModal, setDetailsModal } = useInventoryReport()
  const parValueId = detailsModal.data?.parValueId
  const name = detailsModal.data?.name
  const open = detailsModal?.open as boolean

  const { data: rawData, loading: isLoading } = useGetInventoryReportDetails({
    parValueId: Number(parValueId),
    limit: 10,
  })

  const reportData = rawData?.getInventoryReportProductDetails.items || []
  const isBiologic = reportData[0]?.type === 'Biologic'

  const onClose = () => setDetailsModal({ open: false })
  const overViewColumns: string[] = isBiologic
    ? [
        'Product Type',
        'Consigned',
        'Inventory Location',
        'Lot',
        'Serial',
        'Temperature',
        'Package Integrity',
        'Comments',
        'Quantity',
        'Expiration',
        'Created at',
      ]
    : [
        'Product Type',
        'Consigned',
        'Inventory Location',
        'Lot',
        'Serial',
        'Quantity',
        'Expiration',
        'Created at',
      ]

  const disposition = {
    Consigned: 'Yes',
    Purchased: 'No',
    Mixed: 'Mixed',
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="report-details-modal-container">
        <Box className="header">
          <Typography variant="h2">{name}</Typography>
          <ButtonBase onClick={onClose}>
            <CloseIcon />
          </ButtonBase>
        </Box>
        {isLoading ? (
          <Box className="loader">
            <CircularProgress />
          </Box>
        ) : (
          <Box className="body">
            <TableContainer sx={{ borderRadius: '12px' }} component={Paper}>
              <Table aria-label="overview table">
                <TableHead>
                  <TableRow>
                    {overViewColumns.map((col, index) => (
                      <TableCell key={index} size="small">
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>{item.type || '-'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {disposition[item.disposition] || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {item.inventoryLocationName || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.lotNumber || '-'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.serial || '-'}</Typography>
                      </TableCell>
                      {isBiologic && (
                        <TableCell>
                          <Typography>
                            {item.biologicExtraInfo?.isValidTemperature
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      )}
                      {isBiologic && (
                        <TableCell>
                          <Typography>
                            {item.biologicExtraInfo?.packageIntegrity
                              ? 'Yes'
                              : 'No'}
                          </Typography>
                        </TableCell>
                      )}
                      {isBiologic && (
                        <TableCell>
                          <Typography>
                            {item.biologicExtraInfo?.comment || '-'}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography>{item.quantity || '-'}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color={
                            item?.expirationDate &&
                            moment(item.expirationDate).isBefore(
                              moment(),
                              'day'
                            )
                              ? 'red'
                              : 'inherit'
                          }
                        >
                          {moment(item?.expirationDate).format('YYYY-MM-DD') ||
                            '-'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {moment(item.createdAt).format('YYYY-MM-DD') || '-'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default DetailsModal
