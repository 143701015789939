import { Box, FormControl, TextField, Typography } from '@mui/material'
import {
  ASSET_TYPE_LIST,
  ASSET_TYPES,
  DispositionList,
  DISPOSITIONS,
  EXPLANTED_REASON_LIST,
  getDispositionList,
  WASTED_REASON_LIST,
} from 'common/disposition'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { useEditDispositionModalLogic } from './EditDispositionModal.logic'
import MediaUpload from '../MediaUpload/MediaUpload'
import { EditDispositionModalProps } from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'
import {
  assetGoodConditionCheckList,
  samplePreparationTypeList,
} from 'common/types'
import TextInput from 'components/molecules/TextInput/TextInput'
import { MediaFile } from '../MediaUpload/MediaUpload.types'
import SelectInput from 'components/SelectInput/SelectInput'

export const EditDispositionModal = ({
  scans,
  assetTray,
  isMultipack,
  dataTestId,
  isManualAddition,
  isMultipackHardware,
  singleConsumablesCount,
  isSponge,
}: EditDispositionModalProps) => {
  const {
    isLoading,
    formState,
    isConsumable,
    isBiological,
    isEditModalOpen,
    implantSiteList,
    selectedAssetId,
    validationErrors,
    wastedReasonLabel,
    implantStatusLabel,
    areInputsUnchanged,
    renderImplantSites,
    consumableAssetGroup,
    selectedAssetGroupId,
    isSiteLocationEnabled,
    handleReset,
    handleSubmit,
    setIsEditModalOpen,
    isSavingEditedAsset,
    handleChangeFormData,
    setIsMediaActionTriggered,
    handleOnAddMedia,
    handleOnDeleteMedia,
    isLoadingMedia,
    autoFocus,
    samplePrepNoteRef,
  } = useEditDispositionModalLogic({
    assetTray,
    isMultipack,
    isMultipackHardware,
    isManualAddition,
    singleConsumablesCount,
    isSponge,
  })

  return (
    <CustomModal
      open={isEditModalOpen}
      dataTestId={dataTestId}
      secondaryButtonAction={() => {
        handleReset()
        setIsEditModalOpen(false)
      }}
      header={isConsumable ? 'Edit Consumable' : 'Edit Disposition'}
      primaryButtonDisabled={areInputsUnchanged}
      isLoadingState={
        isLoading || isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
      }
      handleOnSubmit={handleSubmit}
      primaryButtonText={'Save'}
    >
      <FormControl fullWidth>
        <Box>
          <SelectInput
            data-testid="asset-type-input"
            id="assetType"
            name="assetType"
            variant="outlined"
            label="Product Type"
            value={formState?.assetType ?? ''}
            handleChangeFormData={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) ||
              isMultipackHardware ||
              isBiological ||
              isLoadingMedia
            }
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
              },
            }}
            options={
              isBiological
                ? [
                    ...ASSET_TYPE_LIST,
                    {
                      id: ASSET_TYPES[1],
                      name: 'Implantable Biological',
                    },
                  ]
                : ASSET_TYPE_LIST
            }
            autoFocus={autoFocus.assetType}
          />
        </Box>
      </FormControl>

      {formState?.assetType && !isConsumable && (
        <AutocompleteInput
          dataTestId="implant-status-input"
          id="implantStatus"
          name="implantStatus"
          variant="outlined"
          label="Disposition*"
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          value={implantStatusLabel}
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          options={
            getDispositionList({
              assetType: formState.assetType,
              isMultipackHardware,
            }) as DispositionList
          }
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          errorMessage={validationErrors.implantStatus}
          disableClearable
          error={!!validationErrors.implantStatus}
          autoFocus={autoFocus.implantStatus}
        />
      )}

      {renderImplantSites && (
        <AutocompleteInput
          key={
            formState.implantSite?.toString() + autoFocus.implantSite.toString()
          }
          dataTestId="implant-site-input"
          id="implantSite"
          name="implantSite"
          label={
            formState.implantStatus === 'IMPLANTED'
              ? 'Implant Site*'
              : 'Explant Site*'
          }
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          value={formState?.implantSite}
          options={implantSiteList}
          handleChangeFormData={handleChangeFormData}
          variant="outlined"
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          disableClearable
          errorMessage={validationErrors.implantSite}
          error={!!validationErrors.implantSite}
          autoFocus={autoFocus.implantSite}
          autoSelect={false}
        />
      )}

      {isSiteLocationEnabled && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            focused={autoFocus.siteLocation}
            autoFocus={autoFocus.siteLocation}
            variant="outlined"
            id={`implant-site`}
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            name={`siteLocation`}
            value={formState.siteLocation ?? ''}
            placeholder={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            inputMode="text"
            error={!!validationErrors.siteLocation}
            sx={{
              backgroundColor: 'grayscale.lightest',
            }}
            onChange={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
          />
          {!!validationErrors.siteLocation && (
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.siteLocation}
            </Typography>
          )}
        </FormControl>
      )}

      {(isConsumable || formState.assetType === 'consumable') && (
        <>
          <TextInput
            handleChangeFormData={handleChangeFormData}
            id={`consumable-count-${consumableAssetGroup?.bidAssetId}`}
            label={'Items Used*'}
            name="count"
            value={formState.count ?? ''}
            inputMode="numeric"
            variant="outlined"
            type="text"
            required={false}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            sx={{ mb: 1, backgroundColor: 'grayscale.lightest' }}
            error={!!validationErrors.count}
            errorMessage={validationErrors.count}
          />
          {(singleConsumablesCount !== undefined ||
            formState.assetType === 'consumable') &&
            !isSponge && (
              <TextInput
                handleChangeFormData={handleChangeFormData}
                id={`wasted-consumable-count-${consumableAssetGroup?.bidAssetId}`}
                label="Items Wasted"
                name="wastedConsumablesCount"
                value={formState.wastedConsumablesCount ?? ''}
                inputMode="numeric"
                variant="outlined"
                type="text"
                required={false}
                disabled={isSavingEditedAsset(selectedAssetGroupId)}
                sx={{ mb: 1, mt: 1, backgroundColor: 'grayscale.lightest' }}
                error={!!validationErrors.wastedConsumablesCount}
                errorMessage={validationErrors.wastedConsumablesCount}
              />
            )}
        </>
      )}

      {(formState?.implantStatus === 'WASTED' && !isConsumable) ||
      (isConsumable &&
        formState.wastedConsumablesCount &&
        formState?.wastedConsumablesCount > 0) ? (
        <AutocompleteInput
          dataTestId="wasted-reason-input"
          id="wastedReason"
          name="wastedReason"
          variant="outlined"
          label="Wasted Reason*"
          value={wastedReasonLabel}
          options={
            isConsumable
              ? WASTED_REASON_LIST.filter(
                  (reason) =>
                    reason.id !== 'INABILITY-TO-ACHIEVE-PRIMARY-STABILITY'
                )
              : WASTED_REASON_LIST
          }
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          errorMessage={validationErrors.wastedReason}
          disableClearable
          error={!!validationErrors.wastedReason}
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          autoFocus={autoFocus.wastedReason}
          autoSelect={false}
        />
      ) : null}

      {formState?.implantStatus === 'EXPLANTED' && (
        <FormControl fullWidth>
          <SelectInput
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            label="Explanted Reason*"
            value={formState.explantedReason ?? ''}
            handleChangeFormData={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
            errorMessage={validationErrors.explantedReason}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
            autoFocus={autoFocus.explantedReason}
            options={EXPLANTED_REASON_LIST}
          />
          <Typography
            variant="caption"
            sx={{ color: 'error.main', ml: '0.5em' }}
          >
            {validationErrors.explantedReason}
          </Typography>
        </FormControl>
      )}

      {formState?.explantedReason === 'OTHER' &&
        formState.implantStatus === 'EXPLANTED' && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              id={`explanted-reason-note`}
              label="Other Reason*"
              placeholder="Other Reason*"
              name={`explantedReasonNote`}
              value={formState.explantedReasonNote ?? ''}
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={
                isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
              }
              error={!!validationErrors.explantedReasonNote}
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.explantedReasonNote}
            </Typography>
          </FormControl>
        )}

      {isBiological && formState.implantStatus === DISPOSITIONS[0] && (
        <>
          <FormControl fullWidth>
            <SelectInput
              id="packagingOk"
              name="packagingOk"
              variant="outlined"
              label="Packaging Intact"
              placeholder="Packaging Intact"
              value={formState.packagingOk ?? ''}
              handleChangeFormData={handleChangeFormData}
              sx={{
                backgroundColor: 'grayscale.lightest',
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                },
              }}
              options={assetGoodConditionCheckList}
            />
          </FormControl>

          <FormControl fullWidth>
            <SelectInput
              id="temperatureOk"
              name="temperatureOk"
              variant="outlined"
              label="Temperature Ok"
              placeholder="Temperature Ok"
              value={formState.temperatureOk ?? ''}
              handleChangeFormData={handleChangeFormData}
              sx={{
                backgroundColor: 'grayscale.lightest',
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                },
              }}
              options={assetGoodConditionCheckList}
            />
          </FormControl>

          <FormControl fullWidth>
            <SelectInput
              id="samplePreparation"
              name="samplePreparation"
              variant="outlined"
              label="Sample Preparation*"
              value={formState.samplePreparation ?? ''}
              handleChangeFormData={handleChangeFormData}
              sx={{
                backgroundColor: 'grayscale.lightest',
                '& .MuiSvgIcon-root': {
                  color: 'primary.main',
                },
              }}
              options={samplePreparationTypeList}
              autoFocus={autoFocus.samplePreparation}
            />
          </FormControl>

          {formState.samplePreparation === 'OTHER' && (
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                id="sample-preparation-note"
                label="Sample Preparation Note*"
                placeholder="Sample Preparation Note*"
                name="samplePreparationNote"
                value={formState.samplePreparationNote ?? ''}
                inputMode="text"
                sx={{
                  backgroundColor: 'grayscale.lightest',
                }}
                onChange={handleChangeFormData}
                disabled={
                  isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
                }
                error={!!validationErrors.samplePreparationNote}
                focused={autoFocus.samplePreparationNote}
                inputRef={samplePrepNoteRef}
              />
              <Typography
                variant="caption"
                sx={{ color: 'error.main', ml: '0.5em' }}
              >
                {validationErrors.samplePreparationNote}
              </Typography>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              id="implant-memo"
              label="Scrub Tech / Note"
              placeholder="Scrub Tech / Note"
              name="implantMemo"
              value={formState.implantMemo ?? ''}
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={
                isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
              }
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            />
          </FormControl>
        </>
      )}

      {!isConsumable && (
        <>
          <MediaUpload
            disableActions={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
            hasActions
            setIsMediaActionTriggered={setIsMediaActionTriggered}
            assetId={selectedAssetId || ''}
            fileNames={
              selectedAssetId
                ? (scans?.filter((item) => item._id === selectedAssetId)[0]
                    .media as string[])
                : []
            }
            isHandledLocally
            onAddMedia={(mediaFiles: MediaFile[]) =>
              handleOnAddMedia(mediaFiles)
            }
            onDeleteMedia={(fileName: string) => handleOnDeleteMedia(fileName)}
          />
        </>
      )}
    </CustomModal>
  )
}
