import { Box, Dialog, IconButton, Typography } from '@mui/material'
import {
  ZoomIn,
  ZoomOut,
  RotateLeft,
  RotateRight,
  Close,
} from '@mui/icons-material'
import './SubmitImageDialog.scss'

import AlertDialog from 'components/AlertDialog/AlertDialog'
import AtomLoadingModal from '../AtomLoadingModal/AtomLoadingModal'

import { useCaptureTrayContext } from '../../CaptureTray.context'
import useSubmitImageDialog from './SubmitImageDialog.logic'
import { TrayFullScreenReloadingProps } from './SubmitImageDialog.types'
import { Button } from '@mui/material'

const SubmitImageDialog = ({
  open,
  handleClose,
  trayImage,
  setTrayImage,
}: TrayFullScreenReloadingProps) => {
  const {
    handleSubmit,
    scale,
    handleZoomIn,
    handleZoomOut,
    handleRotate,
    cancelSubmit,
  } = useSubmitImageDialog({
    trayImage,
    setTrayImage,
  })
  const { isSubmitting, alertData, setAlertData, isAlertOpen, setIsAlertOpen } =
    useCaptureTrayContext()

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className="submit-image-dialog_wrapper"
      sx={{
        '& .MuiDialog-paper': {
          height: '100%',
          minHeight: '-webkit-fill-available',
        },
      }}
    >
      <Box className="dialog-content">
        <Box className="header">
          <Box className="header-left">
            <IconButton className="close-button" onClick={handleClose}>
              <Close />
            </IconButton>
            <Typography className="title">Review Image</Typography>
          </Box>
          <Box className="header-right">
            <Button
              className="submit-button"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Box>
        </Box>

        <Box className="main-content">
          <Box className="image-container">
            <Box
              className="image-wrapper"
              sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={trayImage}
                alt="Tray Preview"
                className="preview-image"
                style={{
                  transform: `translate(-50%, -50%) scale(${scale})`,
                }}
              />
            </Box>
          </Box>

          <Box className="bottom-controls">
            <IconButton
              className="control-button"
              onClick={() => handleRotate('left')}
            >
              <RotateLeft />
            </IconButton>
            <IconButton
              className="control-button"
              onClick={() => handleRotate('right')}
            >
              <RotateRight />
            </IconButton>
            <IconButton
              className="control-button"
              onClick={handleZoomOut}
              disabled={scale <= 0.5}
            >
              <ZoomOut />
            </IconButton>
            <IconButton
              className="control-button"
              onClick={handleZoomIn}
              disabled={scale >= 3}
            >
              <ZoomIn />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isAlertOpen}
        mode={alertData.mode}
        description={alertData.description}
        primaryButtonAction={() => {
          setIsAlertOpen(false)
          setAlertData({
            description: '',
            mode: undefined,
          })
          handleClose()
        }}
        zIndex={9000}
      />

      <AtomLoadingModal
        open={isSubmitting}
        onClose={() => null}
        onCancel={cancelSubmit}
      />
    </Dialog>
  )
}

export default SubmitImageDialog
