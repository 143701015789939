import { Typography, Paper } from '@mui/material'

import './TrayComponent.scss'
import { TrayComponentProps } from './TrayComponent.types'
import useTrayComponentLogic from './TrayComponent.logic'

const TrayComponent = ({ parentTray, subTray }: TrayComponentProps) => {
  const {
    handleTrayClick,
    areThereAssets,
    areThereScrews,
    areTherePlates,
    subTrayDetails,
    screws,
    plates,
  } = useTrayComponentLogic({ parentTray, subTray })

  return (
    <Paper
      className="tray-component"
      onClick={handleTrayClick}
      elevation={1}
      role="button"
      aria-label={`View details for ${subTray.description}`}
      tabIndex={0}
    >
      <div className="tray-image-container">
        <img
          src={subTrayDetails?.trayImg}
          alt={subTray.description}
          className="tray-image"
        />
      </div>
      <div className="status-badge">
        <span className="status-dot" />
        Loaded from SPD
      </div>
      <div className="tray-stats">
        {areThereScrews && (
          <div className="stat-item">
            <span className="stat-value">{screws.length}</span>
            <span className="stat-label">Screws</span>
          </div>
        )}
        {areTherePlates && (
          <div className="stat-item">
            <span className="stat-value">{plates.length}</span>
            <span className="stat-label">Plates</span>
          </div>
        )}
        {!areThereAssets && (
          <div className="stat-item">
            <span className="stat-value">0</span>
            <span className="stat-label">Screws or Plates</span>
          </div>
        )}
      </div>
      <div className="tray-overlay">
        <Typography className="tray-title">{subTray.description}</Typography>
        <div className="total-count">
          <span className="count-label">
            {subTray.companyName || parentTray.companyName}
          </span>
        </div>
      </div>
    </Paper>
  )
}

export default TrayComponent
