import {
  TrayStatus,
  trayStatusDisplayMap,
} from 'components/organisms/EditTrayModal/EditTrayModal.types'

/**
 * Retrieves the display name for a given tray status.
 * @param trayStatus The status of the tray.
 * @returns The display name of the tray status.
 */
export const getTrayStatusDisplay = (trayStatus: TrayStatus): string => {
  if (trayStatus === undefined) {
    return 'Unknown Status'
  }
  return trayStatusDisplayMap[trayStatus] ?? 'Unknown Status'
}
