import { TrayType } from 'components/organisms/EditTrayModal/EditTrayModal.types'

export const DTM_MANUFACTURERS = [
  {
    label: 'Arthrex',
    value: 'arthrex',
  },
  {
    label: 'BIOMET SPINE',
    value: 'biomet-spine',
  },
  {
    label: 'DJO',
    value: 'djo',
  },
  {
    label: 'KLS',
    value: 'kls',
  },
  {
    label: 'KYOCERA',
    value: 'kyocera',
  },
  {
    label: 'Medtronic',
    value: 'medtronic',
  },
  {
    label: 'MOBI-C',
    value: 'mobi-c',
  },
  {
    label: 'Paragon P28',
    value: 'paragon-p28',
  },
  {
    label: 'SKELETAL DYNAMICS',
    value: 'skeletal-dynamics',
  },
  {
    label: 'Smith & Nephew',
    value: 'smith-and-nephew',
  },
  {
    label: 'Stryker',
    value: 'stryker',
  },
  {
    label: 'Synthes',
    value: 'synthes',
  },
  {
    label: 'TITAN SPINE',
    value: 'titan-spine',
  },
  {
    label: 'Wright',
    value: 'wright',
  },
  {
    label: 'ZIMMER',
    value: 'zimmer',
  },
  {
    label: 'Zimmer Biomet',
    value: 'zimmer-biomet',
  },
] as const

export const DTM_MANUFACTURERS_TRAY_TYPES: Record<
  (typeof DTM_MANUFACTURERS)[number]['value'],
  TrayType[] | undefined
> = {
  arthrex: undefined,
  'biomet-spine': undefined,
  djo: undefined,
  kls: undefined,
  kyocera: undefined,
  medtronic: undefined,
  'mobi-c': undefined,
  'paragon-p28': undefined,
  'skeletal-dynamics': undefined,
  'smith-and-nephew': undefined,
  synthes: undefined,
  'titan-spine': undefined,
  wright: undefined,
  stryker: ['stryker screw caddy', 'stryker upper face', 'stryker middle face'],
  zimmer: [
    'zimmer curved rods',
    'zimmer adjustable transverse connector',
    'zimmer standard closure tops',
  ],
  'zimmer-biomet': [
    'zimmer curved rods',
    'zimmer adjustable transverse connector',
    'zimmer standard closure tops',
  ],
}

export const TRAY_PAR_LEVELS: Record<Exclude<TrayType, undefined>, number> = {
  'stryker screw caddy': 1,
  'stryker upper face': 1,
  'stryker middle face': 1,
  'zimmer curved rods': 2,
  'zimmer adjustable transverse connector': 1,
  'zimmer standard closure tops': 1,
  instrument: 1,
}
