import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// Components
import ScannerActions from 'components/organisms/ScannerActions/ScannerActions'
import BetterIdResultsModal from 'components/BetterIdResultsModal'
import ErrorFallback from 'views/ErrorFallback/ErrorFallback'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { Box, Typography, LinearProgress } from '@mui/material'
import { LoadingButton } from 'components/mui'
import { AlternateScanResultModal } from 'components/AlternateScanResultModal'
import { BatchSetCountModal } from 'components/BatchSetCountModal'

// Types
import { MainIconFlowState } from 'common/types'

// Utils
import { getAssetTypeCaps } from 'lib/utils/getAssetType'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'

// Context
import { useUser } from 'app/User'
import { UndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useBatchScanHandler } from 'lib/batch-scan-handler'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { useCortexDecoderContext } from 'lib/batchScanner'

// Router
import { useNavigate } from 'react-router-dom'

// Other
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'

declare global {
  interface Window {
    mockScan: (text: string) => void
  }
}

interface BatchScanProps {
  setMainIconFlowState: (val: MainIconFlowState) => void
  setIsBatchMode: Dispatch<SetStateAction<boolean>>
}

const TOAST_DURATION = 1800

const BatchScan: FC<BatchScanProps> = ({
  setMainIconFlowState,
  setIsBatchMode,
}) => {
  const { isRep, isNurse, user } = useUser()
  const navigate = useNavigate()

  // State
  const [resultCount, setResultCount] = useState<number | undefined>()
  const [isCountModalOpen, setIsCountModalOpen] = useState(false)
  const loadingToastRef = useRef<string | undefined>()
  const isAddingScanRef = useRef(false)
  const [showAlternateModal, setShowAlternateModal] = useState(false)
  const [isRestrictedAsset, setIsRestrictedAsset] = useState<boolean>(false)
  const [restrictedAssetMessage, setRestrictedAssetMessage] = useState<string>()
  const [isExpiredAlert, setIsExpiredAlert] = useState<boolean>()
  const [isCheckedExpired, setIsCheckedExpired] = useState<boolean>()

  // Context
  const { addUndocumentedScan, resetAddMutation } = useContext(
    UndocumentedAssetsContext
  )
  const {
    resetScan,
    setEnabled,
    setCurrentScan,
    isActive,
    error: scannerError,
  } = useCortexDecoderContext()

  const { assetType: rawAssetTypes } = useAssetType()

  const {
    activeValue,
    results,
    selectedResult,
    setSelectedResult,
    details,
    lookupState,
    _lookupQuery,
    reset,
  } = useBatchScanHandler()

  // sponge counts and multipack assets
  const assetType = getAssetTypeCaps(rawAssetTypes)

  const {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isMultipackHardware,
    isMultipackAsset,
  } = parseAssetIdentifiers({
    deviceDescription: selectedResult?.deviceDescription,
    deviceCount: selectedResult?.deviceCount ?? 1,
    idType: selectedResult?.secondaryDeviceIdType,
    assetType: assetType,
  })

  const repCompanyIds = useMemo(
    () =>
      isRep
        ? [
            user?.bidCompanyId as number,
            user?.parentCompanyId as number,
            ...((user?.siblingCompaniesIds as number[]) ?? []),
            ...((user?.subsidiariesCompaniesIds as number[]) ?? []),
          ]
        : [],
    [
      isRep,
      user?.bidCompanyId,
      user?.parentCompanyId,
      user?.siblingCompaniesIds,
      user?.subsidiariesCompaniesIds,
    ]
  )

  // Handlers
  const navigateToManualAdd = () => navigate('../../asset/manual')

  const dismissLoadingToast = useCallback(() => {
    if (loadingToastRef.current) {
      toast.dismiss(loadingToastRef.current)
      loadingToastRef.current = undefined
    }
  }, [])

  const resetScanner = () => {
    setShowAlternateModal(false)
    reset()
    setResultCount(undefined)
    setSelectedResult(undefined)
    setIsExpiredAlert(undefined)
    dismissLoadingToast()
    toast.dismiss(loadingToastRef.current)
    loadingToastRef.current = undefined
  }

  const handleAddExpiredProduct = () => {
    setIsExpiredAlert(false)
    loadingToastRef.current = toast.loading(
      'Adding captured product to undocumented list'
    )
  }

  const handleAddCount = (count: number) => {
    loadingToastRef.current = toast.loading(
      'Adding captured product to undocumented list'
    )
    setResultCount(count)
  }

  // Life cycle
  // Handle pausing batch add for multi-device and expired assets
  useEffect(() => {
    if (selectedResult?.expirationDate === null) {
      setIsExpiredAlert(false)
      setIsCheckedExpired(true)
    } else if (selectedResult?.expirationDate) {
      const isExpired = new Date(selectedResult.expirationDate) < new Date()

      if (isExpired) {
        setIsExpiredAlert(true)
        setIsCheckedExpired(true)
      } else {
        setIsExpiredAlert(false)
        setIsCheckedExpired(true)
      }
    }
  }, [selectedResult])

  useEffect(() => {
    if (
      isRep &&
      selectedResult &&
      !isAddingScanRef.current &&
      !repCompanyIds.includes(selectedResult.companyId as number)
    ) {
      setRestrictedAssetMessage(
        `This product does not belong to ${user?.companyName}, it belongs to ${selectedResult?.company?.name} and can not be added to the procedure.`
      )
      setIsRestrictedAsset(true)
      return
    }
  }, [user, assetType, isRep, repCompanyIds, selectedResult])

  useEffect(() => {
    if (selectedResult && !isMultipackHardware && isMultipackConsumable) {
      setIsCountModalOpen(true)
    }
  }, [selectedResult, isMultipackHardware, isMultipackConsumable])

  useEffect(
    () => {
      if (
        isCheckedExpired === true &&
        isExpiredAlert === false &&
        typeof isExpiredAlert === 'boolean' &&
        selectedResult
      ) {
        if (
          (selectedResult.deviceCount > 1 &&
            !resultCount &&
            !isMultipackAsset) ||
          (isRep && !repCompanyIds.includes(selectedResult.companyId as number))
        ) {
          setTimeout(() => {
            reset()
            resetAddMutation()
            dismissLoadingToast()
          }, TOAST_DURATION)
          return
        }

        if (isNurse && !resultCount && isMultipackHardware) {
          setMainIconFlowState({
            assetTypeLabel: 'IMPLANTABLE HARDWARE / ASSOCIATED PRODUCT',
            isBatchModeEnabled: false,
            isMultipackHardware: true,
            multipackHardwareSelectedResult: {
              ...selectedResult,
            },
          })
          setIsBatchMode(false)
          navigate('../../asset/result')
        }

        if (
          (!isAddingScanRef.current &&
            (!isMultipackHardware || (isMultipackHardware && isRep)) &&
            !isMultipackConsumable) ||
          (isMultipackConsumable && resultCount)
        ) {
          isAddingScanRef.current = true

          // @ts-ignore - being dumb
          const addPromise = addUndocumentedScan({
            variables: {
              // @ts-ignore - being dumb
              data: [
                {
                  bidAssetId: selectedResult.id,
                  bidCompanyId: selectedResult.companyId,
                  // bidAssetInstanceId: selectedResult.fromAssetInstanceId,
                  catalogNumber: selectedResult.catalogNumber,
                  deviceDescription: selectedResult.deviceDescription,
                  deviceId: selectedResult.deviceId,
                  deviceCount: selectedResult.deviceCount,
                  count: resultCount ?? 1,
                  pkgQuantity: selectedResult.pkgQuantity,
                  expirationDate: selectedResult.expirationDate,
                  lotBatch: selectedResult.lotBatch,
                  companyName: selectedResult.company?.name,
                  manufacturingDate: selectedResult.manufacturingDate,
                  versionModelNumber: selectedResult.versionModelNumber,
                  serialNumber: selectedResult.serialNumber,
                  sizeText: selectedResult.sizeText,
                  sizeString: selectedResult.sizeString,
                  udi: selectedResult.udi,
                  issuingAgency: selectedResult.issuingAgency,
                  secondaryDeviceIdType: selectedResult.secondaryDeviceIdType,
                  assetType: assetType,
                  gmdnPTDefinition: selectedResult.gmdnPTDefinition || null,
                  cost: selectedResult.cost,
                  chargeable: selectedResult.chargeable,
                  parentCompany: selectedResult.company?.parentCompany?.name,
                  parentCompanyId: selectedResult.company?.parentCompanyId,
                },
              ],
            },
          })

          addPromise
            .then(() => {
              toast.success(
                `${
                  selectedResult.deviceDescription ?? selectedResult.deviceId
                } - Added and ready for documentation.`,
                {
                  id: loadingToastRef.current,
                }
              )
            })
            .catch((err: any) => {
              console.error(err)
              toast.error('Failed to add image capture to procedure', {
                id: loadingToastRef.current,
              })
            })
            .finally(() => {
              // the reset timeout needs to match the duration of the success toast,
              // because the toast shows data from the selectedResult that gets reset
              // this shouldn't cause a memory leak since the data being reset is not
              // local to this component but in a higher scoped context
              isAddingScanRef.current = false

              setResultCount(undefined)
              if (isCountModalOpen) setIsCountModalOpen(false)

              setTimeout(() => {
                resetScanner()
                resetAddMutation()
                dismissLoadingToast()
              }, TOAST_DURATION)
            })
        }
      }
    },
    // do not add multipack hardware conditions to these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedResult,
      addUndocumentedScan,
      reset,
      resetAddMutation,
      dismissLoadingToast,
      resultCount,
      isMultipackAsset,
      isRfMultipackConsumable,
      isMultipackHardware,
      lookupState,
      isExpiredAlert,
    ]
  )

  // Loading and error toasts
  useEffect(() => {
    // Dismiss any existing toast when the effect runs
    if (loadingToastRef.current) {
      toast.dismiss(loadingToastRef.current)
      loadingToastRef.current = undefined
    }

    if (lookupState.error) {
      setShowAlternateModal(true)
    } else if (lookupState.isLoading && !loadingToastRef.current) {
      loadingToastRef.current = toast.loading(
        'Image captured: Looking up and adding for documentation...'
      )
    }

    // Cleanup function to dismiss toast when component unmounts or dependencies change
    return () => {
      if (loadingToastRef.current) {
        toast.dismiss(loadingToastRef.current)
        loadingToastRef.current = undefined
      }
    }
  }, [lookupState])

  // Enable scanner on mount, disable scanner on unmount (camera still active
  // in the background)
  useEffect(() => {
    setEnabled(true)
    return () => {
      setEnabled(false)
      resetScan()
    }
  }, [setEnabled, resetScan])

  // Add mock scanner for testing
  useEffect(() => {
    if (window && process.env.REACT_APP_NODE_ENV !== 'production') {
      window.mockScan = (text: string) => {
        setCurrentScan({
          barcodeData: text,
          decodeTime: Date.now(),
          symbologyName: 'DataMatrix',
          barcodeCoordinates: {
            TopLeft: { X: '0', Y: '0' },
            TopRight: { X: '0', Y: '0' },
            BottomRight: { X: '0', Y: '0' },
            BottomLeft: { X: '0', Y: '0' },
          },
        })
      }
    }
  }, [setCurrentScan])

  return (
    <>
      <Helmet>
        <title>Batch Image Capture</title>
      </Helmet>
      <BetterIdResultsModal
        currentScanValue={activeValue}
        results={results}
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
        valueType={details.type}
        onClose={resetScanner}
      />
      <AlternateScanResultModal
        show={showAlternateModal}
        currentScanValue={activeValue}
        results={results}
        lookupQuery={_lookupQuery}
        isComplete={lookupState.isComplete}
        valueType={details.type}
        onClose={resetScanner}
      />
      <AlertDialog
        title="Product Expired"
        description="This product has expired and cannot be added to the procedure."
        isOpen={Boolean(isExpiredAlert)}
        secondaryButtonAction={resetScanner}
        secondaryButtonText="Cancel"
        primaryButtonAction={handleAddExpiredProduct}
        primaryButtonText="Add Anyways"
        dismissable={false}
      />
      <AlertDialog
        title="Restricted Product"
        description={restrictedAssetMessage}
        isOpen={isRestrictedAsset ?? false}
        primaryButtonText="OK"
        primaryButtonAction={() => {
          resetScanner()
          setIsRestrictedAsset(false)
        }}
        dismissable={false}
      />
      {selectedResult &&
        !isMultipackHardware &&
        isMultipackConsumable &&
        isCountModalOpen && (
          <BatchSetCountModal
            isOpen={isCountModalOpen}
            scan={selectedResult}
            onClose={() => {
              setIsCountModalOpen(false)
              resetScanner()
            }}
            onSave={handleAddCount}
          />
        )}

      <Box display="inline-flex" flexDirection="column" alignItems="center">
        {scannerError ? (
          <ErrorFallback
            title="Failed to initiate Smart Vision"
            description="Our team has been notified of the issue."
          />
        ) : (
          <>
            {!isActive && (
              <Box
                width="100vw"
                height="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={1}
                position="fixed"
                sx={{
                  bgcolor: 'background.paper',
                }}
              >
                <Box textAlign="center" mb={28}>
                  <Typography
                    variant="h3"
                    color="grey.800"
                    my={6}
                    letterSpacing={1}
                    fontWeight={500}
                    fontSize={18}
                  >
                    Preparing Smart Vision
                  </Typography>
                  <LinearProgress />
                </Box>
              </Box>
            )}
            <ScannerActions />
            <Box
              position="fixed"
              bottom="80px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              columnGap={1}
            >
              {/* {!hideAddManually && ( */}
              <LoadingButton onClick={navigateToManualAdd} variant="contained">
                Add Manually
              </LoadingButton>
              {/* )} */}
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default BatchScan
