// Components
import { Typography } from '@mui/material'

// Types
import { LandingIconProps } from './LandingIcon.types'

// Style
import classNames from 'classnames'
import { theme } from 'assets/styles/customTheme'
import './LandingIcon.scss'

const LandingIcon = ({
  icon,
  label,
  dataTestId,
  isMobile,
  onClick,
  isDisabled,
}: LandingIconProps) => {
  return (
    <div
      data-testid={dataTestId}
      onClick={isDisabled ? undefined : onClick}
      className={classNames('landing-icon-container', {
        'is-mobile': isMobile,
        'is-disabled': isDisabled,
      })}
    >
      <div className="icon">
        <img
          src={icon}
          alt={label}
          className={`landing-icon-img ${
            icon?.includes('Explanted') ? 'explanted' : ''
          }`}
        />
      </div>
      <div className="label">
        <Typography
          color={theme.palette.primary.main}
          sx={{ fontWeight: '500', fontSize: isMobile ? '12px' : '15px' }}
        >
          {label}
        </Typography>
      </div>
    </div>
  )
}

export default LandingIcon
