import { useGrayScaleLogic } from './GrayScale.logic'
import './GrayScale.scss'
import { GrayScaleTrayMapProps } from './GrayScale.types'
import ConfirmScrewLoadModal from 'components/organisms/ConfirmScrewLoadModal/ConfirmScrewLoadModal'
import { BASE_CIRCLE_SIZES } from './constants'
import classNames from 'classnames'
import { SelectionBox } from './SelectionBox'
import ConfirmScrewSelectionsModal from 'components/organisms/ConfirmScrewSelectionsModal/ConfirmScrewSelectionsModal'
import BlockIcon from '@mui/icons-material/Block'
import Tooltip from '@mui/material/Tooltip'

const GrayScaleTrayMap = ({
  isInOR,
  setSelectedPlate,
  setModalOpen,
  trayType,
  setSelectedScrew,
  mapContent,
  isSPD = false,
}: GrayScaleTrayMapProps) => {
  const {
    screwAnnotations,
    plateAnnotations,
    drillAnnotations,
    selectedAnnotation,
    backgroundImage,
    originalWidth,
    originalHeight,
    uiScale,
    containerRef,
    trayPlates,
    handleScrewClick,
    handleCountChange,
    handleCountCircleClick,
    shouldPlateIncreaseBeDisabled,
    screwLoadModalOpen,
    handleCancelSelection,
    pendingScrewAsset,
    isDragging,
    startPoint,
    endPoint,
    selectedScrewIndices,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isMultiSelectConfirmOpen,
    handleMultiSelectConfirm,
    handleMultiSelectCancel,
    isLoadingWithUDIs,
    textScale,
    calculateDynamicScale,
    trayScrews,
    usageScrews,
  } = useGrayScaleLogic({
    isInOR,
    setSelectedPlate,
    setModalOpen,
    trayType,
    setSelectedScrew,
    mapContent,
    isSPD,
  })

  // Update the scaling calculations
  const dynamicScale = calculateDynamicScale(containerRef)
  const finalScale = Math.min(dynamicScale * uiScale, 1.2) // Cap the final scale

  return (
    <div
      data-testid="grayscale-tray-map"
      ref={containerRef}
      className="grayscale-tray-map annotation-container"
      onMouseDown={!isLoadingWithUDIs && !isInOR ? handleMouseDown : undefined}
      onMouseMove={!isLoadingWithUDIs && !isInOR ? handleMouseMove : undefined}
      onMouseUp={!isLoadingWithUDIs && !isInOR ? handleMouseUp : undefined}
      onDragStart={(e) => e.preventDefault()}
      style={{
        aspectRatio:
          originalWidth && originalHeight
            ? `${originalWidth}/${originalHeight}`
            : 'auto',
        cursor: isLoadingWithUDIs || isInOR ? 'default' : 'crosshair',
      }}
    >
      <img
        src={backgroundImage}
        alt="Tray"
        className="gray-scale-tray-image"
        draggable={false}
        onDragStart={(e) => e.preventDefault()}
      />
      {/* Render Screw Annotations */}
      {screwAnnotations.map((annotation, index) => {
        const {
          img_screw_center,
          img_screw_radius,
          label,
          row_index,
          column_index,
        } = annotation

        if (!img_screw_center || img_screw_radius === undefined) return null

        const [cx, cy] = img_screw_center
        const r = img_screw_radius

        const scaledCX = cx * dynamicScale
        const scaledCY = cy * dynamicScale
        const scaledRadius = r * dynamicScale

        const isSelected = selectedScrewIndices.includes(index)
        const notLoadedFromSPD =
          !trayScrews.find(
            (screw) =>
              screw.label === label &&
              screw.row === row_index + 1 &&
              screw.x === column_index
          ) &&
          !usageScrews.find(
            (screw) =>
              screw.label === label &&
              screw.row === row_index + 1 &&
              screw.x === column_index
          )
        const shouldBeDisabled = notLoadedFromSPD && isInOR
        const displayCount = annotation.count

        return (
          <div
            key={`screw-${index}`}
            className={classNames('annotation-box screw', {
              selected:
                isSelected &&
                (isInOR ? displayCount === 1 : displayCount === 0),
              disabled: shouldBeDisabled,
            })}
            style={{
              left: `${scaledCX - scaledRadius}px`,
              top: `${scaledCY - scaledRadius}px`,
              width: `${scaledRadius * 2}px`,
              height: `${scaledRadius * 2}px`,
            }}
          >
            {shouldBeDisabled && (
              <Tooltip
                title="This screw is not loaded from SPD"
                arrow
                placement="top"
              >
                <BlockIcon className="disabled-icon" />
              </Tooltip>
            )}
            <div className="annotation-controls">
              <span
                className={classNames('count-circle', {
                  active: displayCount > 0,
                  disabled: shouldBeDisabled,
                })}
                onClick={
                  shouldBeDisabled ? undefined : () => handleScrewClick(index)
                }
                style={{
                  width: `${Math.min(
                    BASE_CIRCLE_SIZES.screw.size * finalScale,
                    32
                  )}px`,
                  height: `${Math.min(
                    BASE_CIRCLE_SIZES.screw.size * finalScale,
                    32
                  )}px`,
                  fontSize: `${Math.min(
                    BASE_CIRCLE_SIZES.screw.font * textScale,
                    14
                  )}px`,
                  cursor: shouldBeDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                {displayCount}
              </span>
            </div>
          </div>
        )
      })}

      {/* Render Plate Annotations */}
      {plateAnnotations.map((annotation, index) => {
        const { label, img_UL_coords, img_LR_coords } = annotation

        // Calculate scaled coordinates
        const [x1, y1] = img_UL_coords
        const [x2, y2] = img_LR_coords
        const scaledX1 = x1 * dynamicScale
        const scaledY1 = y1 * dynamicScale
        const scaledX2 = x2 * dynamicScale
        const scaledY2 = y2 * dynamicScale
        const width = scaledX2 - scaledX1
        const height = scaledY2 - scaledY1

        // Get current count from trayPlates
        const currentPlate = trayPlates.find(
          (plate) => plate.plateName === label
        )
        const displayCount = currentPlate?.plateCount || 0

        const uniqueId = `plate-${index}`
        const isSelected = selectedAnnotation === uniqueId

        return (
          <div
            key={`plate-${index}`}
            className="annotation-box plate"
            style={{
              left: `${scaledX1}px`,
              top: `${scaledY1}px`,
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div className="annotation-controls">
              {isSelected && (
                <div className="button-container">
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('plate', index, 1)}
                    disabled={shouldPlateIncreaseBeDisabled(label)}
                  >
                    +
                  </button>
                  <span
                    className={`count-circle ${
                      displayCount > 0 ? 'active' : ''
                    } selected`}
                    onClick={() => handleCountCircleClick('plate', index)}
                    style={{
                      width: `${Math.min(
                        BASE_CIRCLE_SIZES.plate.size * finalScale,
                        32
                      )}px`,
                      height: `${Math.min(
                        BASE_CIRCLE_SIZES.plate.size * finalScale,
                        32
                      )}px`,
                      fontSize: `${Math.min(
                        BASE_CIRCLE_SIZES.plate.font * textScale,
                        14
                      )}px`,
                    }}
                  >
                    {displayCount}
                  </span>
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('plate', index, -1)}
                    disabled={displayCount <= 0}
                  >
                    -
                  </button>
                </div>
              )}
              {!isSelected && (
                <span
                  className={`count-circle ${displayCount > 0 ? 'active' : ''}`}
                  onClick={() => handleCountCircleClick('plate', index)}
                  style={{
                    width: `${Math.min(
                      BASE_CIRCLE_SIZES.plate.size * finalScale,
                      32
                    )}px`,
                    height: `${Math.min(
                      BASE_CIRCLE_SIZES.plate.size * finalScale,
                      32
                    )}px`,
                    fontSize: `${Math.min(
                      BASE_CIRCLE_SIZES.plate.font * textScale,
                      14
                    )}px`,
                  }}
                >
                  {displayCount}
                </span>
              )}
            </div>
          </div>
        )
      })}

      {/* Render Drill Annotations */}
      {drillAnnotations.map((annotation, index) => {
        const { label, img_UL_coords, img_LR_coords } = annotation

        // Calculate scaled coordinates
        const [x1, y1] = img_UL_coords
        const [x2, y2] = img_LR_coords
        const scaledX1 = x1 * dynamicScale
        const scaledY1 = y1 * dynamicScale
        const scaledX2 = x2 * dynamicScale
        const scaledY2 = y2 * dynamicScale
        const width = scaledX2 - scaledX1
        const height = scaledY2 - scaledY1

        const currentDrill = trayPlates.find(
          (plate) => plate.plateName === label
        )
        const displayCount = currentDrill?.plateCount || 0

        const uniqueId = `drill-${index}`
        const isSelected = selectedAnnotation === uniqueId

        return (
          <div
            key={`drill-${index}`}
            className="annotation-box drill"
            style={{
              left: `${scaledX1}px`,
              top: `${scaledY1}px`,
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <div className="annotation-controls">
              {isSelected && (
                <div className="button-container">
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('drill', index, 1)}
                    disabled={isInOR}
                  >
                    +
                  </button>
                  <span
                    className={`count-circle ${
                      displayCount > 0 ? 'active' : ''
                    } selected`}
                    onClick={() => handleCountCircleClick('drill', index)}
                    style={{
                      width: `${Math.min(
                        BASE_CIRCLE_SIZES.drill.size * finalScale,
                        32
                      )}px`,
                      height: `${Math.min(
                        BASE_CIRCLE_SIZES.drill.size * finalScale,
                        32
                      )}px`,
                      fontSize: `${Math.min(
                        BASE_CIRCLE_SIZES.drill.font * textScale,
                        14
                      )}px`,
                    }}
                  >
                    {displayCount}
                  </span>
                  <button
                    className="purple-button"
                    onClick={() => handleCountChange('drill', index, -1)}
                    disabled={displayCount <= 0}
                  >
                    -
                  </button>
                </div>
              )}
              {!isSelected && (
                <span
                  className={`count-circle ${displayCount > 0 ? 'active' : ''}`}
                  onClick={() => handleCountCircleClick('drill', index)}
                  style={{
                    width: `${Math.min(
                      BASE_CIRCLE_SIZES.drill.size * finalScale,
                      32
                    )}px`,
                    height: `${Math.min(
                      BASE_CIRCLE_SIZES.drill.size * finalScale,
                      32
                    )}px`,
                    fontSize: `${Math.min(
                      BASE_CIRCLE_SIZES.drill.font * textScale,
                      14
                    )}px`,
                  }}
                >
                  {displayCount}
                </span>
              )}
            </div>
          </div>
        )
      })}

      {isDragging && startPoint && endPoint && (
        <SelectionBox startPoint={startPoint} endPoint={endPoint} />
      )}

      <ConfirmScrewLoadModal
        open={screwLoadModalOpen}
        onClose={handleCancelSelection}
        pendingScrewAsset={pendingScrewAsset}
      />

      <ConfirmScrewSelectionsModal
        open={isMultiSelectConfirmOpen}
        modalText={`Are you sure you want to fill ${selectedScrewIndices.length} screws?`}
        onClose={handleMultiSelectCancel}
        handleConfirm={handleMultiSelectConfirm}
        handleSaveChangesCancellation={handleMultiSelectCancel}
      />
    </div>
  )
}

export default GrayScaleTrayMap
