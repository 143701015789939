import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import { useAutocompleteInputLogic } from './AutocompleteInput.logic'
import { Option } from 'common/types'
import { AutocompleteInputProps } from './AutocompleteInput.types'
import { useEffect, forwardRef } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'

const AutocompleteInput = forwardRef<HTMLDivElement, AutocompleteInputProps>(
  (
    {
      id,
      disabled = false,
      handleChangeFormData,
      label,
      name,
      options,
      placeholder = 'Select',
      required = false,
      value = '',
      onTextInputChange,
      sx,
      variant = 'standard',
      autoSelect = true,
      clearOnBlur,
      autoHighlight = true,
      isLoading = false,
      disableClearable = false,
      dataTestId,
      noOptionsText,
      toolTip,
      getOptionDisabled,
      fullWidth = false,
      addOption = false,
      isEmpty = false,
      handleModalOnClose,
      errorMessage,
      helperText,
      error = false,
      shrink = true,
      showEmail = false,
      freeSolo,
      autoFocus = false,
      ...props
    }: AutocompleteInputProps,
    ref
  ) => {
    const {
      autocompleteStyles,
      renderItem,
      isOpen,
      setIsOpen,
      handleClickAway,
      inputRef,
    } = useAutocompleteInputLogic({
      disabled,
      required,
      variant,
      sx,
      value,
      toolTip,
      getOptionDisabled,
      handleChangeFormData,
      name,
      error,
      autoFocus,
    })

    useEffect(() => {
      if (autoFocus) {
        const timer = setTimeout(() => {
          setIsOpen(true)
          inputRef.current?.focus()
        }, 100)
        return () => clearTimeout(timer)
      }
    }, [autoFocus, setIsOpen, inputRef])

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          ref={ref}
        >
          <Autocomplete
            freeSolo={freeSolo}
            data-testid={dataTestId}
            className={props.className}
            autoSelect={autoSelect}
            disabled={disabled}
            id={id}
            fullWidth={fullWidth}
            options={options as readonly (string | Option)[]}
            value={value as string | Option}
            clearIcon={<ClearIcon fontSize="medium" />}
            getOptionLabel={(option: string | Option): string => {
              if (typeof option === 'object') {
                return option.name
              }
              return option
            }}
            onChange={(_, inputValue) => {
              handleChangeFormData({
                target: { name: name ?? '', value: inputValue ?? '' },
              })
            }}
            sx={autocompleteStyles}
            renderInput={(params) => (
              <TextField
                helperText={helperText}
                required={required}
                error={error}
                data-testid={`${dataTestId}-textfield`}
                {...params}
                inputRef={inputRef}
                label={label}
                placeholder={placeholder}
                variant={variant}
                autoFocus={autoFocus}
                focused={autoFocus}
                onChange={(e) => {
                  if (onTextInputChange) {
                    onTextInputChange({
                      target: {
                        name: name || '',
                        value: e.target.value,
                      },
                    })
                  }
                }}
                autoComplete="off"
                InputLabelProps={{
                  shrink,
                }}
              />
            )}
            {...(toolTip && {
              renderOption: (props, option: string | Option) =>
                renderItem(props, option),
            })}
            {...(showEmail && {
              renderOption: (props, option) => {
                if (
                  typeof option === 'object' &&
                  'name' in option &&
                  typeof option.name === 'string'
                ) {
                  const nameParts = (option as Option).name
                    .split('|')[0]
                    .split(', ')
                  const lastName = nameParts[0]
                    .split(' ')
                    .filter((word) => word !== '')
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(' ')
                  const firstName =
                    nameParts[1] === '-'
                      ? ''
                      : nameParts[1]
                          .split(' ')
                          .filter((word) => word !== '')
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(' ')
                  const name =
                    firstName === '' ? lastName : `${lastName}, ${firstName}`

                  const email = (option as Option).name.split('|')[1]
                  return (
                    <li {...props}>
                      <div>
                        <Typography variant="body1">{name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {email}
                        </Typography>
                      </div>
                    </li>
                  )
                }
              },
            })}
            clearOnBlur={clearOnBlur}
            autoHighlight={autoHighlight}
            loadingText="Loading..."
            loading={isLoading}
            disableClearable={disableClearable}
            noOptionsText={noOptionsText}
            open={isOpen}
            onOpen={() => {
              if (!disabled) {
                setIsOpen(true)
              }
            }}
            onClose={() => setIsOpen(false)}
            popupIcon={disabled ? null : undefined}
          />
          {errorMessage && (
            <Typography
              fontSize={12}
              color="red"
              ml={0.5}
              mt={variant === 'outlined' ? 0 : -2}
            >
              {errorMessage}
            </Typography>
          )}
        </div>
      </ClickAwayListener>
    )
  }
)

export default AutocompleteInput
