import { useEffect, useMemo, useCallback, useState } from 'react'

// MUI
import { Box, Button, CircularProgress } from '@mui/material'

// Components
import PDFSettingsModal from './PDFSettingsModal/PDFSettingsModal'
import ProcedureDetailsReportPDF from './ProcedureDetailsReport/ProcedureDetailsReportPDF'
import Header from 'components/molecules/Header/Header'
import { FilterBar } from 'components/organisms/FilterBar/FilterBar'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { ReadMore, Download } from '@mui/icons-material'

// Utils
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import currencyFormatter from 'lib/utils/currencyFormatter'

// Context
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'

// Services
import useGetUsageReports from 'lib/services/api/reports-service/usageReports/getUsageReports'

// Router
import { useNavigate } from 'react-router-dom'
import { PATIENT_REPORT } from 'app/ROUTES_CONSTANTS'

// Types
import { DefaultFilterParams } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'
import {
  SurgeriesOutput,
  ListSurgeriesInput,
  MappedSurgeries,
  SurgeryDetailsOutput,
} from './PatientReport.types'
import { AssetTypeLabel } from 'common/types'

// Styles
import './PatientReport.scss'

// Other
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { enqueueSnackbar } from 'notistack'
import { headCells } from './constants'
import dayjs from 'lib/dayjs'
const PatientReportsPage = () => {
  const [surgeryId, setSurgeryId] = useState<string | null>(null)
  const {
    beforeDate,
    afterDate,
    filters,
    setFilters,
    setReportData,
    pdfSettings,
    setPDFSettings,
  } = useReportFiltersContext()
  const [pdfModalOpen, setPdfModalOpen] = useState(false)
  const navigate = useNavigate()
  const { getSurgeryDetails, isLoading: isLoadingGetSurgeryDetails } =
    useGetUsageReports()

  const queryParams = useMemo(() => {
    const usageFilters = (filters as DefaultFilterParams) || {}
    const params = {} as ListSurgeriesInput

    if (usageFilters.mrn) params.mrn = usageFilters.mrn
    if (usageFilters.patientName) params.patientName = usageFilters.patientName
    if (usageFilters.surgeonName) params.surgeonName = usageFilters.surgeonName
    if (usageFilters.procedureType)
      params.procedureType = usageFilters.procedureType
    if (usageFilters.department) params.location = usageFilters.department
    if (afterDate)
      params.startDate = afterDate.format(
        'YYYY-MM-DD'
      ) as unknown as dayjs.Dayjs
    if (beforeDate)
      params.endDate = beforeDate.format('YYYY-MM-DD') as unknown as dayjs.Dayjs
    params.pageSize = 1000
    params.status = 'SUBMITTED'

    return params
  }, [filters, beforeDate, afterDate])

  const { listSurgeries, surgeries, isLoading, error } =
    useGetUsageReports(queryParams)

  // Handlers
  const handlePrint = useCallback(
    async (surgeryId: string, details: SurgeryDetailsOutput) => {
      try {
        if (details?.assetGroups?.length > 0) {
          const pdfDoc = pdf(
            <ProcedureDetailsReportPDF surgeryDetails={details} />
          )
          const blob = await pdfDoc.toBlob()
          saveAs(blob, `procedure-report-${surgeryId}.pdf`)
          enqueueSnackbar('PDF generated successfully', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar('No data available for PDF generation', {
            variant: 'error',
          })
        }
      } catch (error) {
        enqueueSnackbar('Error generating PDF', {
          variant: 'error',
        })
        console.error('Error generating PDF:', error)
      }
      setSurgeryId(null)
    },
    []
  )

  const handleDownload = useCallback(
    async (surgeryId: string) => {
      try {
        setSurgeryId(surgeryId)
        const details = await getSurgeryDetails(surgeryId)
        if (details) {
          const assetTypes = pdfSettings.selectedAssetTypes
          const filteredDetails = details.assetGroups
            .map((assetGroup) => ({
              ...assetGroup,
              scans: assetGroup.scans.filter((scan) =>
                assetTypes?.includes(getAssetTypeLabel(scan.assetType))
              ),
            }))
            .filter((group) => group.scans.length > 0)

          await handlePrint(surgeryId, {
            ...details,
            assetGroups: filteredDetails,
          })
        } else {
          enqueueSnackbar('No data available for PDF generation', {
            variant: 'error',
          })
        }
      } catch (error) {
        console.error('Error downloading surgery details:', error)
        enqueueSnackbar('Error retrieving surgery details', {
          variant: 'error',
        })
      }
    },
    [getSurgeryDetails, handlePrint, pdfSettings.selectedAssetTypes]
  )

  const handleAssetTypeChange = (assetTypes: AssetTypeLabel[]) => {
    setPDFSettings({
      ...pdfSettings,
      selectedAssetTypes: assetTypes,
    })
    setPdfModalOpen(false)
  }

  // Lifecycle
  useEffect(() => {
    listSurgeries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (surgeries) {
      setReportData(surgeries)
    }
  }, [surgeries, setReportData])

  useEffect(() => {
    setFilters({
      open: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mappedData: MappedSurgeries[] = surgeries?.map(
    (surgery: SurgeriesOutput) => {
      return {
        date: dayjs(surgery?.procedures[0].dateTime).format('MMM DD, YYYY'),
        patient: `${surgery?.patientLastName}, ${surgery?.patientFirstName}`,
        caseId: surgery?.surgeryId,
        mrn: surgery?.patientMRN || '-',
        surgeon: surgery?.surgeonLastName || '-',
        procedureType: surgery?.procedures[0]?.description || '-',
        totalSurgeryCost: surgery?.totalCost
          ? currencyFormatter.format(Number(surgery?.totalCost))
          : '-',
        download: (
          <Button
            onClick={() => handleDownload(surgery?.surgeryId)}
            disabled={
              isLoadingGetSurgeryDetails && surgeryId === surgery?.surgeryId
            }
          >
            {isLoadingGetSurgeryDetails && surgeryId === surgery?.surgeryId ? (
              <CircularProgress size={20} />
            ) : (
              <Download />
            )}
          </Button>
        ),
        caseDetails: (
          <Button
            onClick={() => {
              navigate(`/${PATIENT_REPORT}/${surgery?.surgeryId}`)
            }}
          >
            <ReadMore />
          </Button>
        ),
      }
    }
  )

  return (
    <Box className="usage-reports-container">
      <Header parent="Reports" title="Patients Report" />
      <FilterBar
        hasPDFSettings={true}
        renderMrnFilter
        renderPatientNameFilter
        renderSurgeonFilter
        renderProcedureTypeFilter
        renderDatePicker
        condenseButtons
        isLoading={isLoading}
        onPDFSettingsClick={() => {
          setPdfModalOpen(true)
        }}
      />
      <Box
        sx={{
          px: 2,
        }}
      >
        <DataTable
          tableHeaders={headCells}
          tableRows={mappedData || []}
          isLoading={isLoading}
          isErrored={!!error}
        />
      </Box>

      <PDFSettingsModal
        open={pdfModalOpen}
        onClose={() => {
          setPdfModalOpen(false)
        }}
        selectedAssetTypes={pdfSettings.selectedAssetTypes || []}
        setSelectedAssetTypes={handleAssetTypeChange}
      />
    </Box>
  )
}

export default PatientReportsPage
