import { useAuth0 } from 'app/Auth'
import { useState } from 'react'
import {
  ListSurgeriesInput,
  SurgeriesOutput,
  SurgeryDetailsOutput,
} from 'views/PatientReport/PatientReport.types'
import { GetUsageReportsResponse } from './types'

const useGetUsageReports = (
  queryParams?: ListSurgeriesInput
): GetUsageReportsResponse => {
  const { getAccessToken } = useAuth0()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL
  const [surgeries, setSurgeries] = useState<SurgeriesOutput[]>([])
  const [surgeryDetails, setSurgeryDetails] = useState<SurgeryDetailsOutput>(
    {} as SurgeryDetailsOutput
  )
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const listSurgeries = async () => {
    if (!REPORTS_URL) {
      setError('API URL is not defined.')
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    let token: string
    try {
      token = await getAccessToken()
    } catch (error) {
      setError('Failed to get access token')
      setIsLoading(false)
      return
    }

    const queryString = Object.entries(queryParams || {})
      .reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        }
        return acc
      }, [] as string[])
      .join('&')

    const urlWithQuery = `${REPORTS_URL}api/v1/reports/surgeries/?${queryString}`

    try {
      const response = await fetch(urlWithQuery, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      const surgeries = data?.surgeries

      setSurgeries(surgeries)
    } catch (err: any) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getSurgeryDetails = async (surgeryId: string) => {
    if (!REPORTS_URL) {
      setError('API URL is not defined.')
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    let token: string
    try {
      token = await getAccessToken()
    } catch (error) {
      setError('Failed to get access token')
      setIsLoading(false)
      return
    }

    const url = `${REPORTS_URL}api/v1/reports/surgeries/${surgeryId}`

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      setSurgeryDetails(data)
      return data
    } catch (err: any) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    listSurgeries,
    getSurgeryDetails,
    surgeries,
    surgeryDetails,
    isLoading,
    error,
  }
}

export default useGetUsageReports
