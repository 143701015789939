import { useEffect, useMemo, useRef, useState } from 'react'
import {
  CustomChangeEvent,
  EPhysiciansTitle,
  ImplantReportEmailInput,
  ImplantReportStatus,
  Option,
  ReferringPhysician,
} from 'common/types'
import {
  formatEnumValue,
  ImplantReportSenderProps,
  ModalProps,
  physicianDetailsFormSchema,
  unFormatEnumValue,
} from './ImplantReportSender.types'

import { useIsMobile } from 'lib/utils/mediaQueries'
import { getZodError } from 'common/utils'
import { enqueueSnackbar } from 'notistack'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'
import { useUser } from 'app/User'
import {
  useAddReferringPhysician,
  useEditReferringPhysician,
  useGetReferringPhysician,
} from 'lib/apollo/hooks'
import { getOrganization } from 'lib/services/restApi/organizations/organizations'

export const useImplantReportSenderLogic = (
  props: ImplantReportSenderProps
) => {
  const { assetGroups, surgeryId, implantReportStatus, provider } = props

  const hasStarted = useRef(true)

  const isMobile = useIsMobile()
  const { orgId } = useUser()
  const { physiciansTitle } = useOrganizationSettings()

  const assetIds = assetGroups.flatMap((item) =>
    item.scans
      .filter(
        (item) =>
          item.assetType === 'biological' ||
          item.assetType === 'non-biological' ||
          item.assetType === 'other-non-biological'
      )
      .map((scan) => scan._id)
  )

  const initialPhysicianDetails: ReferringPhysician = {
    firstName: provider?.firstName || '',
    lastName: provider?.lastName || '',
    email: provider?.emailAddresses[0] || '',
    confirmEmail: '',
  }

  const shareSurgeryReportSubtitle = `Select a report template to send to the ${
    physiciansTitle === EPhysiciansTitle.ReferringPhysicians ? 'referring' : ''
  } physician`

  const [search, setSearch] = useState('')
  const [selectedPhysician, setSelectedPhysician] = useState<
    Option | undefined
  >(
    provider
      ? {
          id: '0',
          name: provider?.lastName,
        }
      : undefined
  )
  const [reports, setReports] = useState<string[]>([])
  const [formErrors, setFormErrors] = useState<Partial<ReferringPhysician>>()
  const [inputModalOpen, setInputModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [mutationSuccess, setMutationSuccess] = useState<boolean>()
  const [isReportSent, setIsReportSent] = useState(false)
  const [viewReportsModalOpen, setViewReportsModalOpen] = useState(false)

  const [openModal, setOpenModal] = useState<ModalProps>({
    open: false,
  })

  const [updatedPhysicianDetails, setUpdatedPhysicianDetails] =
    useState<ReferringPhysician>(initialPhysicianDetails)

  const [implantReportInputs, setImplantReportInputs] =
    useState<ImplantReportEmailInput>({
      referringPhysicianEmail: provider?.emailAddresses[0],
      referringPhysicianLastName: provider?.lastName,
      surgeryId,
      reportType: undefined,
      reportInputs: {
        assetIds,
      },
      submitOverride: false,
    })

  const { data: physicianData, loading: physicianLoading } =
    useGetReferringPhysician({
      lastName: search
        ? search
        : physiciansTitle === EPhysiciansTitle.Physicians
        ? provider?.lastName
        : undefined,
      firstName: search
        ? undefined
        : physiciansTitle === EPhysiciansTitle.Physicians
        ? provider?.firstName
        : undefined,
    })

  const physicians = useMemo(
    () => physicianData?.getPhysician || [],
    [physicianData]
  )

  const [physicianDetails, setPhysicianDetails] = useState<ReferringPhysician>(
    physicians[0]
  )

  const lastNameOptions: Option[] = physicians.map((item) => ({
    name: `${item.lastName}, ${item.firstName}|${item.email}`,
    id: item._id as string,
  }))

  const isReferringInfoValid =
    physicianDetails?.lastName && physicianDetails.email

  const modalHeader =
    openModal.type === 'create'
      ? 'Add Referring Physician'
      : 'Edit Referring Physician'

  const disableEdit =
    physiciansTitle === EPhysiciansTitle.Physicians &&
    selectedPhysician?.name === provider?.lastName

  const [addReferringPhysician, { loading: isAdding }] =
    useAddReferringPhysician()

  const [editReferringPhysician, { loading: isEditing }] =
    useEditReferringPhysician()

  const disabledOption = (option: string | Option) => {
    const opt = typeof option === 'object' ? option.name : option
    const enumType = unFormatEnumValue(opt)
    if (option === 'Other') {
      return false
    } else {
      return !(implantReportStatus as ImplantReportStatus[]).some(
        (status) => status.type === enumType && status.isSent
      )
    }
  }

  const handleSelectPhysician = (e: CustomChangeEvent) => {
    const value = e.target.value as Option
    setSelectedPhysician({
      ...value,
      name: value.name
        .split(',')[0]
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' '),
    })

    const selected = physicians.find((item) => item._id === value.id)

    if (selected) {
      setPhysicianDetails(selected)
      setImplantReportInputs((prev) => ({
        ...prev,
        referringPhysicianEmail: selected.email,
        referringPhysicianLastName: selected.lastName,
      }))
    }
  }

  const handleEditClick = () => {
    setOpenModal({ open: true, type: 'edit' })
    if (physicianDetails) {
      setUpdatedPhysicianDetails({
        ...physicianDetails,
        confirmEmail: physicianDetails.email,
      })
    }
  }

  const handleAddPhysicianClick = () => {
    setOpenModal({
      open: true,
      type: 'create',
    })
    setUpdatedPhysicianDetails((prev) => ({
      ...prev,
      lastName: search,
    }))
  }

  const handleCloseModal = () => {
    setOpenModal({ open: false })
    setUpdatedPhysicianDetails(initialPhysicianDetails)
    setFormErrors({})
  }

  const handleChangeUpdatedPhysicianDetails = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormErrors((prev) => ({ ...prev, [e.target.name]: '' }))
    setUpdatedPhysicianDetails((prev) => {
      if (e.target.name === 'email' || e.target.name === 'confirmEmail') {
        return {
          ...prev,
          [e.target.name]: e.target.value.toLowerCase(),
        }
      }
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleAddPhysician = async () => {
    const result = physicianDetailsFormSchema.safeParse(updatedPhysicianDetails)
    if (!result.success) {
      setFormErrors(getZodError(result.error))
      return
    }

    const { _id, email, firstName, lastName } = updatedPhysicianDetails

    if (openModal.type === 'create') {
      addReferringPhysician({
        variables: {
          physician: {
            email,
            lastName,
            firstName,
          },
        },
      })
        .then((res) => {
          if (res.data?.createPhysician) {
            const result = res.data?.createPhysician
            handleSuccess(result)
          }
        })
        .catch((err: Error) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        })
    }
    if (openModal.type === 'edit' && _id) {
      editReferringPhysician({
        variables: {
          physician: {
            _id,
            email,
            lastName,
            firstName,
          },
        },
      })
        .then((res) => {
          if (res.data?.editPhysician) {
            const result = res.data?.editPhysician
            handleSuccess(result)
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        })
    }
  }

  const handleSuccess = (result: ReferringPhysician) => {
    const action = openModal.type
    const message = `Physician ${
      action === 'create' ? 'added' : 'updated'
    } successfully.`

    setPhysicianDetails(result)
    setUpdatedPhysicianDetails(initialPhysicianDetails)
    setSelectedPhysician({
      id: result._id as string,
      name: result.lastName
        .split(',')[0]
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' '),
    })
    setImplantReportInputs((prev) => ({
      ...prev,
      referringPhysicianEmail: result.email,
      referringPhysicianLastName: result.lastName,
    }))
    setOpenModal({ open: false })
    enqueueSnackbar(message, {
      variant: 'success',
    })
  }

  useEffect(() => {
    if (
      openModal.type === 'edit' &&
      updatedPhysicianDetails.email === physicianDetails.email
    ) {
      setUpdatedPhysicianDetails((prev) => ({
        ...prev,
        confirmEmail: physicianDetails.email,
      }))
    } else if (openModal.type === 'edit') {
      setUpdatedPhysicianDetails((prev) => ({
        ...prev,
        confirmEmail: '',
      }))
    }
  }, [openModal.type, physicianDetails?.email, updatedPhysicianDetails?.email])

  useEffect(() => {
    if (implantReportStatus) {
      setIsReportSent(implantReportStatus.some((status) => status.isSent))
    }
  }, [implantReportStatus])

  useEffect(() => {
    if (orgId) {
      getOrganization(orgId).then(({ success, data }) => {
        if (success && data) {
          setReports(data.reports.map((report) => formatEnumValue(report)))
        }
      })
    }
  }, [orgId])

  useEffect(() => {
    if (
      provider &&
      physicians.length > 0 &&
      search === '' &&
      hasStarted.current
    ) {
      setSelectedPhysician({
        id: physicians[0]._id as string,
        name: physicians[0].lastName
          .split(',')[0]
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' '),
      })
      setPhysicianDetails(physicians[0])
      setImplantReportInputs((prev) => ({
        ...prev,
        referringPhysicianEmail: physicians[0].email,
        referringPhysicianLastName: physicians[0].lastName,
      }))
      hasStarted.current = false
    }
  }, [physicians, provider, search])

  useEffect(() => {
    if (search === '' && !hasStarted.current) {
      setSelectedPhysician(undefined)
      setPhysicianDetails(initialPhysicianDetails)
    }
  }, [search])

  return {
    search,
    reports,
    isMobile,
    assetIds,
    isAdding,
    openModal,
    isEditing,
    isLoading: isAdding || isEditing,
    formErrors,
    modalHeader,
    disableEdit,
    errorMessage,
    isReportSent,
    inputModalOpen,
    mutationSuccess,
    lastNameOptions,
    physiciansTitle,
    physicianLoading,
    physicianDetails,
    selectedPhysician,
    implantReportInputs,
    isReferringInfoValid,
    viewReportsModalOpen,
    updatedPhysicianDetails,
    shareSurgeryReportSubtitle,
    setSearch,
    disabledOption,
    setErrorMessage,
    formatEnumValue,
    handleEditClick,
    handleCloseModal,
    unFormatEnumValue,
    setInputModalOpen,
    setMutationSuccess,
    handleAddPhysician,
    handleSelectPhysician,
    setImplantReportInputs,
    setViewReportsModalOpen,
    handleAddPhysicianClick,
    setUpdatedPhysicianDetails,
    handleChangeUpdatedPhysicianDetails,
  }
}
