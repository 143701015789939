import { useState } from 'react'

import { useProcedureListTableLogic } from 'components/ProcedureList/ProcedureListTable.logic'
import { useGetSurgeriesQuery } from 'lib/apollo/hooks'
import { useDisablePolling } from 'lib/useDisablePolling'
import dayjs from 'lib/dayjs'

import { ItemResponse } from 'common/types'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'

const initialBefore = dayjs().endOf('day').toDate()
const initialAfter = dayjs().startOf('day').toDate()

export const useListOfSurgeries = ({
  traySurgeryId,
}: {
  traySurgeryId: string
}) => {
  const disablePolling = useDisablePolling()
  const [beforeDate, setBeforeDate] = useState(initialBefore)
  const [afterDate, setAfterDate] = useState(initialAfter)

  const { loading, error, data, refetch } = useGetSurgeriesQuery(
    {
      before: beforeDate,
      after: afterDate,
      limit: 0,
    },
    {
      disablePolling,
    }
  )
  const surgeries = data && data.getSurgeries?.results

  const { isNurse, caseInformation, sortedSurgeries } =
    useProcedureListTableLogic({
      events: surgeries,
    })

  const handleBackDay = () => {
    setBeforeDate(dayjs(beforeDate).subtract(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).subtract(1, 'day').toDate())
  }

  const handleForwardDay = () => {
    setBeforeDate(dayjs(beforeDate).add(1, 'day').toDate())
    setAfterDate(dayjs(afterDate).add(1, 'day').toDate())
  }

  const isSurgerySelected = (surgeryId: string, trayData: ItemResponse) => {
    const previousProductDetails = extractTrayJSONData(trayData)

    const selectedSurgeryId = traySurgeryId || previousProductDetails?.surgeryId

    return surgeryId === selectedSurgeryId
  }

  return {
    loading,
    sortedSurgeries,
    caseInformation,
    isNurse,
    handleBackDay,
    handleForwardDay,
    isSurgerySelected,
    beforeDate,
    afterDate,
    error,
    refetch,
  }
}
