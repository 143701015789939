import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Stack,
  Typography,
  Paper,
  Breadcrumbs,
  Link,
} from '@mui/material'
import { ArrowBack, CameraAlt, GridOn } from '@mui/icons-material'

import { useAssignedDigitalTrays } from '../AssignedDigitalTrays/AssignedDigitalTrays.context'
import './TrayDetails.scss'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { useIsMobile } from 'lib/utils/mediaQueries'

const TrayDetails = () => {
  const navigate = useNavigate()
  const { selectedSubTray, selectedTray } = useAssignedDigitalTrays()
  const isMobile = useIsMobile()
  const subTrayDetails = extractSubTrayJSONData(selectedSubTray)

  if (!selectedSubTray || !selectedTray) {
    return (
      <Box className="tray-details-error">
        <Typography variant="h6" color="error">
          No tray selected
        </Typography>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Return to Trays
        </Button>
      </Box>
    )
  }

  return (
    <div className="tray-details-container">
      <Box className="tray-details-header">
        <Button
          className="back-button"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          size={isMobile ? 'small' : 'medium'}
        >
          {isMobile ? 'Back' : 'Back to Trays'}
        </Button>

        {!isMobile && (
          <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
            <Link
              component="button"
              variant="body1"
              onClick={() => navigate(-1)}
              color="inherit"
            >
              Assigned Trays
            </Link>
            <Typography color="text.primary">
              {selectedSubTray.description || selectedTray.description}
            </Typography>
          </Breadcrumbs>
        )}
      </Box>

      <Paper elevation={0} className="tray-details-content">
        <Typography variant={isMobile ? 'h5' : 'h4'} className="tray-title">
          {selectedSubTray.description}
        </Typography>

        <Box className="tray-metadata">
          <Typography variant="body1" color="text.secondary">
            Tray Type: {subTrayDetails?.trayType || 'Unknown'}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Vendor: {selectedSubTray.companyName}
          </Typography>
        </Box>

        <Box className="tray-image-section">
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            className="section-title"
          >
            Tray Image
          </Typography>
          <Paper variant="outlined" className="tray-image-container">
            {subTrayDetails?.trayImg ? (
              <img
                src={subTrayDetails.trayImg}
                alt="Tray"
                className="tray-image"
              />
            ) : (
              <Box className="no-image-placeholder">
                <Typography color="text.secondary">
                  No tray image available
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>

        <Box className="action-section">
          <Typography
            variant={isMobile ? 'subtitle1' : 'h6'}
            className="section-title"
          >
            Document Using:
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            className="action-buttons"
          >
            <Button
              variant="outlined"
              fullWidth
              startIcon={<GridOn />}
              onClick={() =>
                navigate(
                  `../../capture-tray/${selectedTray.id}?mode=image-only`
                )
              }
              size={isMobile ? 'medium' : 'large'}
            >
              Smart Tray
            </Button>
            <Button
              variant="contained"
              fullWidth
              startIcon={<CameraAlt />}
              onClick={() =>
                navigate(`../../capture-tray/${selectedTray.id}?mode=analyze`)
              }
              size={isMobile ? 'medium' : 'large'}
            >
              Smart Vision
            </Button>
          </Stack>
        </Box>
      </Paper>
    </div>
  )
}

export default TrayDetails
