import { Typography } from '@mui/material'
import { ErrorOutline } from '@mui/icons-material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import './AnalysisErrorModal.scss'

interface AnalysisErrorModalProps {
  open: boolean
  onRetry: () => void
  onProceedManually: () => void
}

const AnalysisErrorModal = ({
  open,
  onRetry,
  onProceedManually,
}: AnalysisErrorModalProps) => {
  return (
    <Modal open={open}>
      <div className="analysis-error-modal">
        <div className="header-box">
          <ErrorOutline color="error" />
          <Typography variant="h3">Analysis Failed</Typography>
        </div>

        <Typography variant="body1" className="message">
          The tray analysis was unsuccessful. You can try to capture a new image
          for analysis or proceed with loading the smart tray.
        </Typography>

        <div className="buttons-container">
          <LoadingButton
            variant="outlined"
            mode="cancel"
            onClick={onRetry}
            className="retry-button"
          >
            Recapture & Retry
          </LoadingButton>

          <LoadingButton onClick={onProceedManually}>
            Load Smart Tray
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default AnalysisErrorModal
