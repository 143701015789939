/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'

import { CameraAccess } from './CameraConfig/cameraAccess'
import { getSurgeryScreenHeight } from 'lib/utils/getSurgeryScreenHeight'
import { Camera, Resolution } from '../CaptureTray.types'

const useCaptureCamera = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [image, setImage] = useState<string>('')
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false)
  const [manualAddModalOpen, setManualAddModalOpen] = useState<boolean>(false)
  const [videoHeight, setVideoHeight] = useState<number | string>('70vh')
  const [activeCamera, setActiveCamera] = useState<Camera | null>(null)
  const [cameras, setCameras] = useState<Camera[]>([])
  const [isCameraMenuOpen, setIsCameraMenuOpen] = useState<boolean>(false)
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    'portrait'
  )

  const resolution: Resolution = {
    width: 1920,
    height: 1080,
  }
  const mode = searchParams.get('mode') || 'analyze'

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(
        window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
      )
    }

    window.addEventListener('resize', handleOrientationChange)

    return () => {
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [])

  const handleStopCapture = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop())
      setMediaStream(null)
    }
  }

  const handleToggleCameraMenu = () => {
    setIsCameraMenuOpen((prev) => !prev)
  }

  const handleCloseCameraMenu = () => {
    setIsCameraMenuOpen(false)
  }

  const selectActiveCamera = (cameras: MediaDeviceInfo[]) => {
    if (cameras.length > 1) {
      const backCameras = cameras.filter((cam) =>
        cam.label.toLowerCase().includes('back')
      )

      if (backCameras.length > 0) {
        return backCameras[0]
      }
    }
    return cameras[0]
  }

  const fetchCameraData = async (retryCount: number = 0) => {
    try {
      const fetchedCameras: MediaDeviceInfo[] = await CameraAccess.getCameras()
      if (fetchedCameras.length > 0) {
        const initialCamera = selectActiveCamera(fetchedCameras)
        setCameras(fetchedCameras)
        const stream = await CameraAccess.accessCamera(
          initialCamera.deviceId,
          resolution.width,
          resolution.height
        )
        setMediaStream(stream)
        setActiveCamera(initialCamera)
      } else {
        console.error('No cameras available.')
        if (retryCount < 10) {
          setTimeout(() => fetchCameraData(retryCount + 1), 500)
        } else {
          console.error('Max retries exceeded. Could not access camera.')
        }
      }
    } catch (error) {
      console.error('Error:', error)
      if (retryCount < 10) {
        setTimeout(() => fetchCameraData(retryCount + 1), 500)
      } else {
        console.error('Max retries exceeded. Could not access camera.')
      }
    }
  }

  useEffect(() => {
    fetchCameraData()

    const cleanup = () => {
      handleStopCapture()
    }

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (videoRef.current && mediaStream) {
      videoRef.current.srcObject = mediaStream
    }
  }, [mediaStream])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchCameraData()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const handleDeviceChange = () => {
      fetchCameraData()
    }

    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        handleDeviceChange
      )
    }
  }, [])

  const handleSelectCamera = (camera: MediaDeviceInfo) => {
    try {
      CameraAccess.accessCamera(
        camera.deviceId,
        resolution.width,
        resolution.height
      )
        .then((stream) => {
          setMediaStream(stream)
          setActiveCamera(camera)
          handleCloseCameraMenu()
        })
        .catch((error) => {
          handleCloseCameraMenu()
        })
    } catch (error) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        console.error('Failed to set selected camera:', error)
      }
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setVideoHeight(getSurgeryScreenHeight())
    }

    // Event listener for window resize
    window.addEventListener('resize', handleResize)

    // Initial resize handling
    handleResize()

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleSPDCaptureImage = (skipRotate: boolean = false) => {
    if (!videoRef.current) return

    const { videoWidth, videoHeight } = videoRef.current
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) return

    canvas.width = videoWidth
    canvas.height = videoHeight

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    if (!skipRotate) {
      if (orientation === 'portrait') {
        canvas.width = videoHeight
        canvas.height = videoWidth
        ctx.translate(canvas.width, 0)
        ctx.rotate(Math.PI / 2)
      }
    }

    ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight)

    const capturedImage = canvas.toDataURL('image/png')
    setImage(capturedImage)
    setIsSubmitModalOpen(true)
  }

  const handleUsageCaptureImage = () => {
    if (!videoRef.current) return

    const { videoWidth, videoHeight } = videoRef.current
    const canvas = document.createElement('canvas')

    canvas.width = videoWidth
    canvas.height = videoHeight

    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight)

      const capturedImage = canvas.toDataURL('image/png')
      setImage(capturedImage)
      setIsSubmitModalOpen(true)
    }
  }

  const handleManualAddClick = () => {
    setManualAddModalOpen((prev) => !prev)
  }

  const handleSubmitModalClose = () => {
    setIsSubmitModalOpen(false)
  }

  const handleSkipClick = () => {
    navigate('../tray-visualization')
  }

  return {
    videoRef,
    mediaStream,
    videoHeight,
    orientation,
    isCameraMenuOpen,
    isSubmitModalOpen,
    cameras,
    activeCamera,
    handleSelectCamera,
    handleToggleCameraMenu,
    handleManualAddClick,
    handleSubmitModalClose,
    handleSPDCaptureImage,
    handleUsageCaptureImage,
    image,
    setImage,
    manualAddModalOpen,
    fetchCameraData,
    selectActiveCamera,
    mode,
    handleSkipClick,
  }
}

export default useCaptureCamera
