import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { UndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useContext, useEffect, useState } from 'react'
import { TSelectOldExplantsModalProps } from './SelectOldExplantsModal.types'
import { Scan } from 'common/types'

export const useSelectOldImplantsModalLogic = (
  props: TSelectOldExplantsModalProps
) => {
  const {
    oldImplants: fetchedOldImplants,
    onClose,
    onSetSelectedOldImplants,
    onSetIsDispositionModalOpen,
  } = props

  const { handleChangeAssetType } = useAssetType()

  const { addUndocumentedScan, isLoadingUndocumentedList } = useContext(
    UndocumentedAssetsContext
  )

  const [oldImplants, setOldImplants] = useState<Scan[]>(fetchedOldImplants)

  const goToScanning = oldImplants.every((implant) => !implant.isSelected)
  const areAllSelected = oldImplants.every((implant) => implant.isSelected)
  const primaryButtonText = isLoadingUndocumentedList
    ? 'Saving'
    : goToScanning
    ? 'Skip'
    : 'Document'

  const cardStyles = (index: number) => ({
    my: 1,
    display: 'block',
    borderColor: oldImplants[index].isSelected ? 'success.main' : 'grey.300',
    bgcolor: oldImplants[index].isSelected ? 'success.lightest' : 'white',
  })

  const handleClose = () => {
    setOldImplants(
      fetchedOldImplants.map((item) => ({
        ...item,
        isSelected: false,
      }))
    )
    onClose()
  }

  const handleSelectAll = () => {
    setOldImplants(
      fetchedOldImplants.map((item) => ({
        ...item,
        isSelected: !areAllSelected,
      }))
    )
  }

  const handleUndocumentedScanCardClick = (index: number) => () => {
    setOldImplants((prev) => {
      const updatedImplants = [...prev]
      updatedImplants[index] = {
        ...updatedImplants[index],
        isSelected: !updatedImplants[index].isSelected,
      }
      onSetSelectedOldImplants(
        updatedImplants
          .filter((item) => item.isSelected)
          .map((scan) => ({
            ...scan,
            implantStatus: 'EXPLANTED',
            originalImplantAssetId: scan._id,
            implantSite: scan.implantSite,
          }))
      )
      return updatedImplants
    })
  }

  const handleSubmit = () => {
    if (goToScanning) {
      handleChangeAssetType('EXPLANTED')
    } else {
      onSetIsDispositionModalOpen(true)
    }
  }

  useEffect(() => {
    if (oldImplants.length === 0) {
      setOldImplants(fetchedOldImplants)
    }
  }, [fetchedOldImplants, oldImplants.length])

  return {
    oldImplants,
    areAllSelected,
    primaryButtonText,
    isLoadingUndocumentedList,
    cardStyles,
    handleClose,
    handleSubmit,
    handleSelectAll,
    handleUndocumentedScanCardClick,
  }
}
