/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAssignedDigitalTrays } from '../../AssignedDigitalTrays.context'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useBetterIdLookupQuery, useUploadAssetMedia } from 'lib/apollo/hooks'
import { bidSearchResponseToResults } from 'lib/utils/betterid'
import { enqueueSnackbar } from 'notistack'

import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { BetterIdLookupQuery, BetterIdResult } from 'common/types'
import { UsagePlateWithDescription } from '../TrayProducts.types'
import {
  PlateKey,
  plateDescriptionMap,
} from 'views/SPDLayout/SPD/TrayMap/TrayMap.types'
import { ZimmerCurvedRodsPlateKey } from 'components/organisms/ZimmerRodTrayMap/ZimmerRodTrayMap.types'
import { ZimmerTransverseConnectorsPlateKey } from 'components/organisms/ZimmerTransverseConnectorsTrayMap/ZimmerTransverseConnectors.types'
import { ZimmerStandardClosureTopsPlateKey } from 'components/organisms/ZimmerStandardClosureTopsTrayMap/ZimmerStandardClosureTops.types'
import { QrCode2 } from '@mui/icons-material'
import { Typography } from '@mui/material'
import TrayProduct from './TrayProduct'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'

export const useTrayProducts = () => {
  const navigate = useNavigate()

  const { addUndocumentedScan, deleteScans, undocumentedScans } =
    useUndocumentedAssetsContext()
  const { usageScrews, selectedTray, usagePlates, selectedSubTray } =
    useAssignedDigitalTrays()
  const [uploadFiles] = useUploadAssetMedia()

  const [codeValue, setCodeValue] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [wastedScrews, setWastedScrews] = useState<BetterIDTrayScrew[]>([])
  const [implantedScrews, setImplantedScrews] = useState<BetterIDTrayScrew[]>(
    []
  )
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [surgeryPlateIndex, setSurgeryPlateIndex] = useState<number>(-1)
  const [dtmUndocumentedScanIds, setDtmUndocumentedScanIds] = useState<
    string[]
  >([])
  const [surgeryUsagePlates, setSurgeryUsagePlates] = useState<
    UsagePlateWithDescription[]
  >([])
  const [allScanIds, setAllScanIds] = useState<string[]>([])
  const [isProcessingComplete, setIsProcessingComplete] =
    useState<boolean>(false)

  const subTrayDetails = extractSubTrayJSONData(selectedSubTray)
  const trayImg = subTrayDetails?.trayImg

  useEffect(() => {
    if (undocumentedScans.length > 0) {
      const dtmUndocumentedScanIds = undocumentedScans
        .filter((scan) => scan.isDTMScrew)
        .map((scan) => scan._id)
      setDtmUndocumentedScanIds(dtmUndocumentedScanIds)
    }
  }, [undocumentedScans])

  useBetterIdLookupQuery(codeValue, {
    skip: !isSearching,
    onCompleted: (data) => {
      handleLookupCompletion(data)
    },
  })

  useEffect(() => {
    setWastedScrews(usageScrews?.filter((trayProduct) => trayProduct.wasted))
    setImplantedScrews(
      usageScrews?.filter((trayProduct) => !trayProduct.wasted)
    )
  }, [usageScrews])

  useEffect(() => {
    if (shouldSearchForScrews()) {
      setCodeValue(usageScrews[currentIndex]?.deviceId as string)
      setIsSearching(true)
    } else if (isFinished && shouldSearchForPlates()) {
      setCodeValue(surgeryUsagePlates[surgeryPlateIndex]?.deviceId as string)
      setIsSearching(true)
    }
  }, [currentIndex, isFinished, surgeryPlateIndex])

  useEffect(() => {
    const newSurgeryUsagePlates = usagePlates.map((usagePlate) => ({
      ...usagePlate,
      description: findPlateDescriptionByKey(usagePlate.plateName as PlateKey),
    }))
    setSurgeryUsagePlates(newSurgeryUsagePlates)
  }, [usagePlates.length])

  useEffect(() => {
    // When processing is complete and we have scan IDs, upload the tray image
    if (isProcessingComplete && allScanIds.length > 0 && trayImg) {
      uploadTrayImage(allScanIds)
      setIsProcessingComplete(false) // Reset for next batch if needed
      setAllScanIds([]) // Clear scan IDs
      navigate(`../asset/batch/undocumented`)
    }
  }, [isProcessingComplete, allScanIds])

  const handleSubmit = () => {
    if (undocumentedScans.length > 0) {
      deleteScans(dtmUndocumentedScanIds)
    }

    if (usageScrews.length > 0) {
      setCurrentIndex(0)
      setIsSearching(true)
    } else {
      setIsFinished(true)
      setSurgeryPlateIndex(0)
    }
  }

  const handleLookupCompletion = async (data: BetterIdLookupQuery) => {
    setIsSearching(false)
    const selectedResults = bidSearchResponseToResults(data.betterIdLookup)
    let newScanIds: string[] = []

    for (const selectedResult of selectedResults) {
      try {
        const addedScan = await addUndocumentedScan({
          variables: {
            data: [createScanData(selectedResult)],
          },
        })

        if (!addedScan.data?.addUndocumentedScan[0]?._id) {
          console.error('Failed to add undocumented scan - no ID returned', {
            selectedResult,
          })
          enqueueSnackbar('Failed to process scan data', { variant: 'error' })
          continue
        }

        newScanIds.push(addedScan.data.addUndocumentedScan[0]._id)
      } catch (error) {
        console.error('Failed to process scan:', {
          error,
          deviceId: selectedResult.deviceId,
        })
        enqueueSnackbar(
          'Failed to process scan. Please try again or contact support if the issue persists.',
          { variant: 'error' }
        )
      }
    }

    // Accumulate scan IDs
    setAllScanIds((prev) => [...prev, ...newScanIds])

    updateIndices()
    checkNavigationConditions()
  }

  const createScanData = (selectedResult: BetterIdResult) => ({
    bidAssetId: selectedResult.id,
    bidCompanyId: selectedResult.companyId,
    catalogNumber: selectedResult.catalogNumber,
    deviceDescription: selectedResult.deviceDescription,
    deviceId: selectedResult.deviceId,
    deviceCount: selectedResult.deviceCount,
    count: 1,
    pkgQuantity: selectedResult.pkgQuantity,
    expirationDate: selectedResult.expirationDate,
    lotBatch: selectedResult.lotBatch,
    companyName: selectedResult.company?.name,
    manufacturingDate: selectedResult.manufacturingDate,
    versionModelNumber: selectedResult.versionModelNumber,
    serialNumber: selectedResult.serialNumber,
    sizeText: selectedResult.sizeText,
    sizeString: selectedResult.sizeString,
    udi: selectedResult.udi,
    issuingAgency: selectedResult.issuingAgency,
    secondaryDeviceIdType: selectedResult.secondaryDeviceIdType,
    assetType: 'non-biological',
    isDTMScrew: true,
    dtmScrewData: createDtmScrewData(),
  })

  const createDtmScrewData = () => ({
    column: isFinished ? null : usageScrews[currentIndex].x,
    row: isFinished ? null : usageScrews[currentIndex].row,
    label: isFinished ? null : usageScrews[currentIndex].label,
    wasted: isFinished
      ? surgeryUsagePlates[surgeryPlateIndex].wasted
      : usageScrews[currentIndex].wasted,
    trayId: selectedTray?.id,
    size: isFinished ? null : usageScrews[currentIndex].column,
  })

  const updateIndices = () => {
    if (!isFinished) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
    } else {
      setSurgeryPlateIndex((prevIndex) => prevIndex + 1)
    }
  }

  const checkNavigationConditions = () => {
    if (currentIndex === usageScrews.length - 1 && !isFinished) {
      setSurgeryPlateIndex(0)
      setIsFinished(true)

      if (surgeryUsagePlates.length === 0) {
        setIsSearching(false)
        setIsProcessingComplete(true)
      }
    }

    if (
      (surgeryUsagePlates.length === 0 && isFinished) ||
      (surgeryUsagePlates.length > 0 &&
        surgeryPlateIndex === surgeryUsagePlates.length - 1)
    ) {
      setIsSearching(false)
      setIsProcessingComplete(true)
    }
  }

  const shouldSearchForScrews = () =>
    usageScrews.length - 1 >= currentIndex && currentIndex >= 0

  const shouldSearchForPlates = () =>
    surgeryUsagePlates.length - 1 >= surgeryPlateIndex

  const findPlateDescriptionByKey = (
    plateName:
      | PlateKey
      | ZimmerCurvedRodsPlateKey
      | ZimmerTransverseConnectorsPlateKey
      | ZimmerStandardClosureTopsPlateKey
  ) => {
    return plateDescriptionMap[plateName as PlateKey]
  }

  const renderScrewData = (
    screws: BetterIDTrayScrew[],
    emptyMessage: string
  ) => (
    <>
      {screws.map((product: BetterIDTrayScrew, _index: number) => (
        <TrayProduct key={`${product.deviceId}${_index}`} {...product} />
      ))}
      {screws.length === 0 && (
        <Typography variant="body2" className="empty-data_text">
          {emptyMessage}
        </Typography>
      )}
    </>
  )

  const renderUsagePlates = (
    usagePlates: UsagePlateWithDescription[],
    emptyMessage: string
  ) => {
    return (
      <>
        {usagePlates.map((usagePlate) => (
          <div key={usagePlate.plateName} className="usage-plate_container">
            <QrCode2 className="qr-code" />
            <div className="content">
              <Typography variant="subtitle2">
                Manufacturer:{' '}
                {usagePlate?.plateName?.includes('connector')
                  ? 'Zimmer Biomet'
                  : 'Stryker'}
              </Typography>
              <Typography variant="subtitle2">
                Device ID: {usagePlate.deviceId}
              </Typography>
              <Typography variant="subtitle2">
                {usagePlate.wasted ? 'Wasted' : 'Implanted'}
              </Typography>
            </div>
          </div>
        ))}
        {usagePlates.length === 0 && (
          <Typography variant="body2" className="empty-data_text">
            {emptyMessage}
          </Typography>
        )}
      </>
    )
  }

  const uploadTrayImage = async (scanIds: string[]) => {
    const trayImgUrl = subTrayDetails?.trayImg
    
    if (!trayImgUrl) {
      console.warn('No tray image available')
      return
    }

    try {
      const response = await fetch(trayImgUrl)
      if (!response.ok) {
        throw new Error(`Failed to fetch tray image: ${response.statusText}`)
      }

      const blob = await response.blob()
      const base64Content = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result as string)
        reader.onerror = () =>
          reject(new Error('Failed to convert image to base64'))
        reader.readAsDataURL(blob)
      })

      const uploadResponse = await uploadFiles({
        variables: {
          scanIds,
          files: [
            {
              filename: `tray-image-${Date.now()}.jpg`,
              mimetype: 'image/jpeg',
              encoding: 'base64',
              content: base64Content.split(',')[1],
            },
          ],
        },
      })

      if (!uploadResponse.data?.uploadAssetMedia?.files) {
        throw new Error('No files returned from upload')
      }
    } catch (error) {
      console.error('Failed to attach tray image:', { error, scanIds })
      enqueueSnackbar(
        "Scans were processed but failed to attach tray image. This won't affect functionality.",
        { variant: 'warning' }
      )
    }
  }

  return {
    implantedScrews,
    wastedScrews,
    handleSubmit,
    isSearching,
    surgeryUsagePlates,
    renderScrewData,
    renderUsagePlates,
  }
}
