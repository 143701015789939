import { useSendAssetSmsEmailMutation } from 'lib/apollo/hooks'
import { useEffect, useState } from 'react'
import { TSenderButtonProps } from './SenderButton.types'
import { enqueueSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { transformDate } from 'common/utils'

export const useSenderButtonLogic = (props: TSenderButtonProps) => {
  const { surgeryId } = useParams() as { surgeryId: string }
  const { assetDetails, rep, isSms, receivedReps } = props

  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false)
  const [isAlreadySentAlertOpen, setIsAlreadySentAlertOpen] = useState(false)

  const { sendAssetSmsEmail, data, loading, error } =
    useSendAssetSmsEmailMutation(surgeryId)

  const handleSendToRep = () => {
    if (
      !isAlreadySentAlertOpen &&
      !isErrorDialogOpen &&
      ((!isSms && receivedReps.emails.includes(rep.email)) ||
        (isSms && receivedReps.mobiles.includes(rep.mobile)))
    ) {
      setIsAlreadySentAlertOpen(true)
      return
    }

    const { assetManufacturer, ...filteredAssetDetails } = assetDetails

    sendAssetSmsEmail({
      variables: {
        isSms,
        surgeryId,
        rep: {
          id: rep.id,
          firstName: rep.firstName,
          lastName: rep.lastName,
          email: rep.email,
          mobile: rep.mobile,
          bidCompanyId: rep.bidCompanyId,
          companyName: rep.companyName,
          inviteCode: rep.inviteCode,
          invitePending: rep.invitePending,
          accessLevel: rep.accessLevel,
        },
        assetDetails: {
          ...filteredAssetDetails,
          expirationDate: transformDate(assetDetails.expirationDate || ''),
        },
      },
    })
  }

  useEffect(() => {
    if (error || (data && !data.success)) {
      setIsAlreadySentAlertOpen(false)
      setErrorDialogOpen(true)
    }
    if (data?.success) {
      setIsAlreadySentAlertOpen(false)
      setErrorDialogOpen(false)
      enqueueSnackbar(
        `Successfully sent product to ${isSms ? rep.mobile : rep.email}`,
        { variant: 'success' }
      )
    }
  }, [data, data?.success, error, isSms, loading, rep.email, rep.mobile])

  return {
    isErrorDialogOpen,
    loading,
    isAlreadySentAlertOpen,
    setIsAlreadySentAlertOpen,
    setErrorDialogOpen,
    handleSendToRep,
  }
}
