import { useNavigate } from "react-router-dom"
import { useAssignedDigitalTrays } from "../AssignedDigitalTrays.context"
import { extractSubTrayJSONData } from "lib/utils/extractSubTrayJSONData"
import { TrayComponentProps } from "./TrayComponent.types"
import { SubItem } from "common/types"

const useTrayComponentLogic = ({
  parentTray,
  subTray,
}: TrayComponentProps) => {
  const navigate = useNavigate()
  const { setSelectedTray, setSelectedSubTray } = useAssignedDigitalTrays()
  const subTrayDetails = extractSubTrayJSONData(subTray)

  const screws = subTrayDetails?.screws || []
  const plates = subTrayDetails?.plates || []

  const areThereAssets = screws.length > 0 || plates.length > 0
  const areThereScrews = screws.length > 0
  const areTherePlates = plates.length > 0

  const handleTrayClick = () => {
    const updatedProductDetails = JSON.stringify({
      ...subTrayDetails,
      screws: subTrayDetails?.screws || [],
      plates: subTrayDetails?.plates || [],
    })

    setSelectedSubTray({
      ...subTray,
      description: subTray.description || '',
      productDetails: updatedProductDetails,
    } as SubItem)
    setSelectedTray(parentTray)

    navigate(`tray-details/${subTray.id}`)
  }

  return {
    handleTrayClick,
    areThereAssets,
    areThereScrews,
    areTherePlates,
    subTrayDetails,
    screws,
    plates,
  }
}

export default useTrayComponentLogic
