import { Typography } from '@mui/material'
import { Portal } from '@mui/material'
import { Info } from '@mui/icons-material'
import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'
import './ConfirmScrewSelectionsModal.scss'

interface ConfirmScrewSelectionsModalProps {
  open: boolean
  modalText: string
  onClose: () => void
  handleConfirm: () => void
  handleSaveChangesCancellation: () => void
}

const ConfirmScrewSelectionsModal = ({
  open,
  modalText,
  onClose,
  handleConfirm,
  handleSaveChangesCancellation,
}: ConfirmScrewSelectionsModalProps) => {
  return (
    <Portal container={document.body}>
      <Modal
        open={open}
        onClose={onClose}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }}
        sx={{ zIndex: 20000, isolation: 'isolate' }}
      >
        <div className="confirm-screw-selections-modal floating">
          <div className="header-box">
            <Info />
            <Typography variant="h3">Confirm Selections</Typography>
          </div>

          <Typography variant="body1" className="description">
            {modalText}
          </Typography>

          <div className="button-container">
            <LoadingButton
              variant="outlined"
              onClick={handleSaveChangesCancellation}
            >
              Cancel
            </LoadingButton>
            <LoadingButton onClick={handleConfirm}>Fill Screws</LoadingButton>
          </div>
        </div>
      </Modal>
    </Portal>
  )
}

export default ConfirmScrewSelectionsModal
