// Components
import { CircularProgress, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import LandingIcon from './LandingIcon/LandingIcon'

// Types
import { MainIconFlowState } from 'common/types'

// Logic
import { useMainIconLandingLogic } from './MainIconLanding.logic'

// Styles
import classNames from 'classnames'
import './MainIconLanding.scss'

// Other
import { Helmet } from 'react-helmet'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { MainIconLandingProps } from './MainIconLanding.types'
import { FC } from 'react'
import { SelectOldExplantsModal } from './SelectOldExplantsModal/SelectOldExplantsModal'
import { BatchDispositionModal } from 'components/BatchDispositionModal/BatchDispositionModal'
import { useNavigate } from 'react-router-dom'

const MainIconLanding: FC<MainIconLandingProps> = ({
  surgery,
  derivedSurgeryStatus,
  setOnMainIconLanding,
}) => {
  const {
    icons,
    loading,
    isMobile,
    oldImplants,
    twoColumnsClass,
    loadingOldImplants,
    selectedOldImplants,
    loadingBatchAddAssets,
    isDispositionModalOpen,
    selectOldExplantsModalOpen,
    showConsumablesAutopopulate,
    handleExplants,
    populateConsumables,
    handleChangeAssetType,
    handleAutoExplantSave,
    setSelectedOldImplants,
    handleSaveUploadedMedia,
    setIsDispositionModalOpen,
    setSelectOldExplantsModalOpen,
  } = useMainIconLandingLogic({ surgery, derivedSurgeryStatus })
  const navigate = useNavigate()

  const isPreApproval = derivedSurgeryStatus === 'PRE_APPROVAL'

  return (
    <div
      className={classNames('main-icon-landing_wrapper', {
        'is-mobile': isMobile,
      })}
    >
      {loadingOldImplants && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}

      <Helmet>
        <title>Home</title>
      </Helmet>

      <Typography variant="h1" align="center">
        Select Product Type
      </Typography>

      {isPreApproval && (
        <Alert
          severity="warning"
          sx={{
            marginX: 2,
            '& a': {
              color: 'inherit',
              textDecoration: 'underline',
            },
            maxWidth: '800px',
          }}
        >
          This procedure is in a <strong>pre-approval</strong> state. Changes
          cannot be made until approval is completed or canceled. Please proceed
          to the{' '}
          <button
            className="pre-approval-link"
            onClick={() => {
              setOnMainIconLanding?.(false)
              navigate(`/surgeries/${surgery?._id}/list`)
            }}
          >
            Documented List
          </button>{' '}
          to complete the approval process.
        </Alert>
      )}

      <div className={`icons-container ${twoColumnsClass}`}>
        {Object.entries(icons).map(
          ([
            key,
            { IconComponent, label, dataTestId, isVisible, isDisabled },
          ]) => {
            if (isVisible === false) return null

            const handleClick = () => {
              if (label === 'Explant') {
                handleExplants()
              } else {
                handleChangeAssetType(
                  label?.toUpperCase() as MainIconFlowState['assetTypeLabel']
                )
              }
            }

            return (
              <LandingIcon
                key={key}
                isMobile={isMobile}
                onClick={handleClick}
                isDisabled={isDisabled}
                icon={IconComponent}
                label={label}
                dataTestId={dataTestId}
              />
            )
          }
        )}
      </div>

      <CustomModal
        header="Consumables Autopopulate"
        fitContentWidth={!isMobile}
        open={showConsumablesAutopopulate}
        isLoadingState={loading}
        primaryButtonText="YES"
        secondaryButtonText="NO"
        onCloseTakeSecondaryButtonAction={false}
        secondaryButtonAction={() => populateConsumables(false)}
        handleOnSubmit={() => populateConsumables(true)}
      >
        <Typography>
          A preference card was found for this surgery, Would you like to add
          all the consumables from it?
        </Typography>
      </CustomModal>

      {selectOldExplantsModalOpen && (
        <SelectOldExplantsModal
          isLoading={loadingOldImplants}
          oldImplants={oldImplants}
          open={selectOldExplantsModalOpen}
          onClose={() => setSelectOldExplantsModalOpen(false)}
          onSetIsDispositionModalOpen={setIsDispositionModalOpen}
          onSetSelectedOldImplants={setSelectedOldImplants}
        />
      )}

      {isDispositionModalOpen && (
        <BatchDispositionModal
          isAutoExplant
          isOpen={isDispositionModalOpen}
          scan={selectedOldImplants[0]}
          selectedScans={selectedOldImplants}
          assetType="explanted"
          dispositionStatus="EXPLANTED"
          isManualAddition={false}
          activeTray={surgery?.activeAssetTray || undefined}
          assetTrays={surgery?.assetTrays || []}
          onAutoExplantSave={handleAutoExplantSave}
          onSaveMedia={handleSaveUploadedMedia}
          onClose={() => {
            setIsDispositionModalOpen(false)
          }}
          isSubmitting={loadingBatchAddAssets}
          setIsTrayScannerOpen={() => {}}
        />
      )}
    </div>
  )
}

export default MainIconLanding
