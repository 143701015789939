import { AssetIdType } from 'common/types'
import {
  ASSOCIATED_PRODUCT_DESCRIPTORS,
  NON_ASSOCIATED_DESCRIPTIONS,
  RF_CONSUMABLE_DESCRIPTORS,
  SPONGE_DESCRIPTORS,
  TParseAssetIdentifiersArgs,
} from './parseAssetIdentifiers.types'

const isMatch = (descriptors: string[], target?: string) => {
  if (!target) return false

  const caseInsensitive = descriptors.map(
    (descriptor) => new RegExp(`\\b${descriptor}\\b`, 'i')
  )

  return caseInsensitive.some((regex) => regex.test(target))
}

export const parseAssetIdentifiers = ({
  deviceDescription,
  deviceCount,
  idType,
  assetType,
  gmdnPTDefinition,
}: TParseAssetIdentifiersArgs) => {
  const isRfMultipackConsumable =
    isMatch(RF_CONSUMABLE_DESCRIPTORS, deviceDescription ?? undefined) ||
    isMatch(RF_CONSUMABLE_DESCRIPTORS, gmdnPTDefinition ?? undefined) ||
    (['lap', 'laparotomy'].some((descriptor) =>
      deviceDescription?.toLowerCase().includes(descriptor)
    ) &&
      SPONGE_DESCRIPTORS.some((spongeDesc) =>
        deviceDescription?.toLowerCase().includes(spongeDesc)
      ))

  const containsAssist = gmdnPTDefinition?.includes('assist') || false

  const isMultipackAsset = deviceCount > 1 || idType === AssetIdType.UNIT_OF_USE

  const isMultipackHardware =
    isMultipackAsset &&
    assetType === 'non-biological' &&
    !isRfMultipackConsumable

  const isSpongeConsumable =
    isMatch(SPONGE_DESCRIPTORS, deviceDescription ?? undefined) &&
    !isRfMultipackConsumable

  // is a multipack consumable and NOT an rf multipack consumable or sponge
  const isMultipackConsumable =
    isMultipackAsset &&
    assetType === 'consumable' &&
    !isRfMultipackConsumable &&
    !isSpongeConsumable

  const isMultipackNonConsumable =
    isMultipackAsset &&
    assetType !== 'consumable' &&
    !isRfMultipackConsumable &&
    !isSpongeConsumable

  const matchesNonAssociated = isMatch(
    NON_ASSOCIATED_DESCRIPTIONS,
    deviceDescription ?? undefined
  )
  const matchesAssociated = isMatch(
    ASSOCIATED_PRODUCT_DESCRIPTORS,
    deviceDescription ?? undefined
  )

  const containsReusableAndImplant =
    isMatch(['reusable'], deviceDescription ?? undefined) &&
    isMatch(['implant'], deviceDescription ?? undefined)

  // Final logic for determining if the product is associated
  const isAssociatedProduct =
    (matchesAssociated &&
      assetType === 'non-biological' &&
      (!matchesNonAssociated || containsReusableAndImplant)) ||
    (containsAssist && assetType === 'non-biological')

  return {
    isMultipackConsumable,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackHardware,
    isMultipackNonConsumable,
    isMultipackAsset,
    isAssociatedProduct,
  }
}
