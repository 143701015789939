import { useEffect, useState } from 'react'
import {
  ImplantStatus,
  Procedures,
  Provider,
  Location,
  Patient,
  AssetData,
  Scan,
  AssetTypeLabel,
} from 'common/types'
import { useReportFiltersContext } from 'lib/context/ReportFiltersContext/ReportFiltersContext'
import _ from 'lodash'
import { SurgeriesOutput } from 'views/PatientReport/PatientReport.types'
import { getReadableImplantStatus } from 'lib/utils/getReadableImplantStatus'
import { AssetReportOutput } from 'views/AssetsReports/AssetsReports.types'
import { ReportFiltersContextProps } from 'lib/context/ReportFiltersContext/ReportFiltersContext.types'

export const useFilterMenuLogic = () => {
  const { filters, setFilters, reportData } = useReportFiltersContext()

  const [assetType, setAssetType] = useState<AssetTypeLabel | undefined>(
    filters.assetType as AssetTypeLabel
  )
  const [mrn, setMrn] = useState<Patient['idMR'] | undefined>(filters?.mrn)

  const [patientName, setPatientName] = useState<
    Patient['lastName'] | Patient['firstName'] | undefined
  >(filters?.patientName)

  const [surgeonName, setSurgeonName] = useState<
    Provider['lastName'] | Provider['firstName'] | undefined
  >(filters?.surgeonName)

  const [procedureType, setProcedureType] = useState<
    Procedures['description'] | undefined
  >(filters?.procedureType)

  const [department, setDepartment] = useState<
    Location['department'] | undefined
  >(filters?.department)

  const [deviceDescription, setDeviceDescription] = useState<
    AssetData['deviceDescription'] | Scan['deviceDescription'] | undefined
  >(filters?.deviceDescription)

  const [implantStatus, setImplantStatus] = useState<
    Scan['implantStatus'] | undefined
  >(filters?.disposition)

  const [surgeonList, setSurgeonList] = useState<Provider['lastName'][]>([])

  const [procedureList, setProcedureList] = useState<
    Procedures['description'][]
  >([])

  const [deptList, setDeptList] = useState<Location['department'][]>([])

  const dispositionsList = _.uniq(
    ((reportData as AssetReportOutput[]) || [])
      ?.map((item: AssetReportOutput) =>
        getReadableImplantStatus(
          item.assets?.[0]?.implantStatus as ImplantStatus
        )
      )
      .filter((status) => status != null)
  )

  const getProceduresList = (
    reportData: SurgeriesOutput[] | AssetReportOutput[]
  ) => {
    if (!reportData || reportData.length === 0) {
      return []
    }

    let descriptions = (reportData as SurgeriesOutput[])
      .map((item) => item.procedures?.[0]?.description)
      .filter(Boolean)

    if (descriptions.length === 0) {
      descriptions = (reportData as AssetReportOutput[])
        .map((item) => item.surgery?.procedures?.[0]?.description)
        .filter(Boolean)
    }

    return _.uniq(descriptions)
  }

  const getSurgeonNameList = (
    reportData: SurgeriesOutput[] | AssetReportOutput[]
  ) => {
    if (!reportData || reportData.length === 0) {
      return []
    }

    let surgeonNames = (reportData as SurgeriesOutput[])
      .map((item) => item.surgeonLastName)
      .filter(Boolean)

    if (surgeonNames.length === 0) {
      surgeonNames = (reportData as AssetReportOutput[])
        .map((item) => item.surgeon?.lastName)
        .filter((name): name is string => name !== undefined)
    }

    return _.uniq(surgeonNames)
  }

  const getLocationList = (
    reportData: ReportFiltersContextProps['reportData']
  ) => {
    let locationList = (reportData as SurgeriesOutput[])
      .map((item) => item.location)
      .filter(Boolean)

    if (locationList.length === 0) {
      locationList = (reportData as AssetReportOutput[])
        .map((item) => item.surgery?.visit?.location?.department)
        .filter(Boolean)
    }

    return _.uniq(locationList)
  }

  useEffect(() => {
    const updatedSurgeonList = getSurgeonNameList(reportData)
    setSurgeonList(updatedSurgeonList)

    const updatedProcedureList = getProceduresList(reportData)
    setProcedureList(updatedProcedureList)

    const locationList = getLocationList(reportData)
    setDeptList(locationList)
  }, [reportData])

  const handleSearchMrn = (value: SurgeriesOutput['patientMRN']) => {
    setMrn(value)
  }

  const handleSearchPatientName = (value: string | undefined) => {
    setPatientName(value)
  }

  const handleSearchDeviceDescription = (value: string) => {
    setDeviceDescription(value)
  }

  const handleOnSubmit = () => {
    setFilters({
      open: false,
      mrn: mrn,
      patientName: patientName,
      surgeonName: surgeonName,
      procedureType: procedureType,
      department: department,
      assetType: assetType,
      deviceDescription: deviceDescription,
      disposition: implantStatus,
    })
  }

  return {
    assetType,
    setAssetType,
    mrn,
    patientName,
    surgeonName,
    setSurgeonName,
    procedureType,
    setProcedureType,
    department,
    setDepartment,
    handleSearchMrn,
    surgeonList,
    procedureList,
    deptList,
    handleSearchPatientName,
    handleOnSubmit,
    handleSearchDeviceDescription,
    implantStatus,
    setImplantStatus,
    dispositionsList,
    deviceDescription,
  }
}
