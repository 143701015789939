// Components
import TextInput from 'components/molecules/TextInput/TextInput'
import { DateInput } from 'components/mui'

// Types
import {
  AddMissingFieldsModalProps,
  AddMissingFieldsValues,
} from './AddMissingFieldsModal.types'

// Services
import { useAddMissingFields } from 'lib/apollo/hooks'

// Other
import { enqueueSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

const AddMissingFieldsModalLogic = ({
  assetId,
  fields,
  onClose,
}: AddMissingFieldsModalProps) => {
  const [addMissingFields, { loading: isLoading }] = useAddMissingFields()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddMissingFieldsValues>({
    defaultValues: {
      lotNumber: null,
      serialNumber: null,
      expirationDate: null,
    },
    mode: 'onChange',
  })

  const onSubmit = (data: AddMissingFieldsValues) => {
    const filteredFields = fields
      .map((item) => ({
        fieldName: item,
        value: data[item as keyof AddMissingFieldsValues],
      }))
      .filter((field) => field.value !== null)

    addMissingFields({
      variables: {
        assetId,
        fields: filteredFields,
      },
    })
      .then((res) => {
        if (res.data?.addMissingFields.success) {
          onClose?.({}, 'backdropClick')
          enqueueSnackbar('Fields added successfully.', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(
            res.errors?.[0].message ||
              'An error occurred while adding the missing fields.',
            {
              variant: 'error',
            }
          )
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          err.message || 'An error occurred while adding the missing fields.',
          {
            variant: 'error',
          }
        )
      })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const renderField = (field: string) => {
    switch (field) {
      case 'serialNumber':
        return (
          <Controller
            key="serialNumber"
            name="serialNumber"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: 'Serial Number has to be longer that 2 characters.',
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <TextInput
                {...field}
                value={value}
                handleChangeFormData={onChange}
                variant="outlined"
                placeholder="Serial Number"
                label="Serial Number"
                error={!!errors.serialNumber}
                helperText={errors.serialNumber?.message}
                shrink
                disabled={isLoading}
              />
            )}
          />
        )
      case 'lotNumber':
        return (
          <Controller
            key="lotNumber"
            name="lotNumber"
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: 'Lot Number has to be longer that 2 characters.',
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <TextInput
                {...field}
                value={value}
                handleChangeFormData={onChange}
                variant="outlined"
                placeholder="Lot Number"
                label="Lot Number"
                error={!!errors.lotNumber}
                helperText={errors.lotNumber?.message}
                shrink
                disabled={isLoading}
              />
            )}
          />
        )
      case 'expirationDate':
        return (
          <Controller
            key="expirationDate"
            name="expirationDate"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <DateInput
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                handleChangeFormData={onChange}
                label="Expiration Date"
                value={value}
                error={!!errors.expirationDate}
                variant="outlined"
                helperText={errors.expirationDate?.message}
                fullWidth
                disabled={isLoading}
                sx={{
                  backgroundColor: 'white',
                  mt: 1,
                }}
                {...field}
              />
            )}
          />
        )
      default:
        return null
    }
  }

  return {
    isLoading,
    renderField,
    handleOnSubmit,
  }
}

export default AddMissingFieldsModalLogic
