import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import {
  DispositionData,
  MULTIPACK_DISPOSITION_LIST,
  WASTED_REASON_LIST,
  DISPOSITION_LIST,
} from 'common/disposition'
import { AssetData, Scan } from 'common/types'
import { useEditDocumentedListContext } from 'lib/context/EditDocumentedListContext/EditDocumentedListContext'
import {
  EditDispositionModalLogicProps,
  EditDocumentedScanInput,
} from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import { ValidationErrorMap } from 'components/BatchDispositionModal/BatchDispositionModal.types'
import { useDispositionValidationSchema } from './EditDispositionModal.validation'
import { getZodError } from 'common/utils'
import {
  useDeleteMediaFiles,
  useGetSurgeryQuery,
  useUploadAssetMedia,
} from 'lib/apollo/hooks'
import { useParams } from 'react-router-dom'
import { MediaFile } from '../MediaUpload/MediaUpload.types'
import { enqueueSnackbar } from 'notistack'

export const useEditDispositionModalLogic = (
  props: EditDispositionModalLogicProps
) => {
  const {
    assetTray,
    isMultipack,
    isMultipackHardware,
    isManualAddition,
    isSponge,
  } = props
  const {
    scans,
    isLoading,
    foundScanRef,
    scanStateMap,
    assetGroupId,
    isEditModalOpen,
    isSelectModalOpen,
    consumableAssetGroup,
    setScans,
    handleReset,
    scanIsMatching,
    setScanStateMap,
    setAssetGroupId,
    submitDisposition,
    setIsEditModalOpen,
    isSavingEditedAsset,
    setIsSelectModalOpen,
    setConsumableAssetGroup,
  } = useEditDocumentedListContext()
  const samplePrepNoteRef = useRef<HTMLInputElement>(null)

  const { surgeryId } = useParams()

  const { refetch: refetchSurgery } = useGetSurgeryQuery(surgeryId, {
    disablePolling: true,
  })

  const [toAddMediaFiles, setToAddMediaFiles] = useState<MediaFile[]>([])
  const [toDeleteMediaFiles, setToDeleteMediaFiles] = useState<string[]>([])

  const [deleteFiles, { loading: isLoadingDeleteFiles }] = useDeleteMediaFiles({
    surgeryId: surgeryId ?? '',
  })

  const [uploadFiles, { loading: isLoadingUploadFiles }] = useUploadAssetMedia()

  const { dispositionSchema } = useDispositionValidationSchema()

  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  // handles multiple scans with the same disposition
  const scanToUseInForm = foundScanRef.current ? foundScanRef.current : scans[0]

  const selectIsSelectedId = () => {
    const selectedEntry = Object.entries(scanStateMap).find(
      ([, value]) => value.isSelected
    )

    return selectedEntry ? selectedEntry[0] : undefined
  }

  const selectedAssetId =
    scans.length > 1 ? selectIsSelectedId() : scans[0]?._id

  // Memoize initialFormState to prevent re-initialization on every render
  const initialFormState = useMemo(
    () => ({
      siteLocation: scanToUseInForm?.siteLocation,
      implantStatus: scanToUseInForm?.implantStatus,
      assetType:
        scanToUseInForm?.assetType || consumableAssetGroup?.scans[0].assetType,
      implantSite: scanToUseInForm?.implantSite || undefined,
      wastedReason:
        (scanToUseInForm?.wastedReason?.toUpperCase() as DispositionData['wastedReason']) ||
        (consumableAssetGroup?.wastedReason as DispositionData['wastedReason']) ||
        undefined,
      assetTray: assetTray || '',
      explantedReason: scanToUseInForm?.explantedReason,
      explantedReasonNote: scanToUseInForm?.explantedReasonNote,
      count:
        isSponge && consumableAssetGroup
          ? consumableAssetGroup?.total / consumableAssetGroup?.deviceCount
          : consumableAssetGroup?.total || scanToUseInForm?.count,
      wastedConsumablesCount: consumableAssetGroup?.wasted || 0,
      samplePreparation: scanToUseInForm?.samplePreparation || 'NONE_REQUIRED',
      samplePreparationNote: scanToUseInForm?.samplePreparationNote || '',
      temperatureOk: scanToUseInForm?.temperatureOk || 'NA',
      packagingOk: scanToUseInForm?.packagingOk || 'NA',
      implantMemo: scanToUseInForm?.implantMemo || '',
    }),
    [
      scanToUseInForm?.siteLocation,
      scanToUseInForm?.implantStatus,
      scanToUseInForm?.assetType,
      scanToUseInForm?.implantSite,
      scanToUseInForm?.wastedReason,
      scanToUseInForm?.explantedReason,
      scanToUseInForm?.explantedReasonNote,
      scanToUseInForm?.count,
      scanToUseInForm?.samplePreparation,
      scanToUseInForm?.samplePreparationNote,
      scanToUseInForm?.temperatureOk,
      scanToUseInForm?.packagingOk,
      scanToUseInForm?.implantMemo,
      consumableAssetGroup,
      assetTray,
      isSponge,
    ]
  )

  // States
  const [formState, setFormState] =
    useState<Partial<DispositionData>>(initialFormState)
  const [isMediaActionTriggered, setIsMediaActionTriggered] = useState(false)

  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>(
    {}
  )

  // Variables
  const isSiteLocationEnabled =
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    (formState.implantSite === 'Other' || formState.implantSite === 'Mouth')

  const isConsumable = formState.assetType === 'consumable'
  const isBiological = formState.assetType === 'biological'

  const isImplantedOrExplanted =
    formState.implantStatus === 'IMPLANTED' ||
    formState.implantStatus === 'EXPLANTED'

  const isNotWastedOrAssociated =
    formState.implantStatus !== 'WASTED' &&
    formState.implantStatus !== 'ASSOCIATED_ASSET'

  const renderImplantSites =
    !isConsumable &&
    isImplantedOrExplanted &&
    !!formState.implantStatus &&
    isNotWastedOrAssociated

  const isLoadingMedia = isLoadingUploadFiles || isLoadingDeleteFiles
  // Check if any of the form inputs have changed from the initial values
  const areInputsUnchanged = useMemo(() => {
    return (
      JSON.stringify(formState) === JSON.stringify(initialFormState) &&
      !isMediaActionTriggered &&
      toAddMediaFiles.length === 0 &&
      toDeleteMediaFiles.length === 0 &&
      !isLoadingMedia
    )
  }, [
    formState,
    initialFormState,
    isLoadingMedia,
    isMediaActionTriggered,
    toAddMediaFiles.length,
    toDeleteMediaFiles.length,
  ])

  const implantStatusLabel =
    (isMultipackHardware ? MULTIPACK_DISPOSITION_LIST : DISPOSITION_LIST).find(
      (disposition) => disposition.id === formState?.implantStatus
    )?.name ?? ''

  const wastedReasonLabel = WASTED_REASON_LIST.find(
    (reason) => reason.id === formState?.wastedReason
  )?.name

  const explantedReasonLabel = WASTED_REASON_LIST.find(
    (reason) => reason.id === formState?.explantedReason
  )?.name

  // Handlers
  const handleEditOnOpen = (
    agId: AssetData['_id'],
    scansToEdit?: Scan[],
    scanIds?: Scan['_id'],
    consumableAssetGroup?: AssetData
  ) => {
    setAssetGroupId(agId)

    const isMultipleAssets = scanIds?.includes(',') || false
    if (!isMultipleAssets && scansToEdit) {
      setScans(scansToEdit)
      setIsEditModalOpen(true)
    } else if (scansToEdit) {
      const scanIdsArray = scanIds?.split(',')
      const filteredScans: Scan[] = scansToEdit?.filter((scan) =>
        scanIdsArray?.includes(scan._id)
      )
      setScans(filteredScans)
      setIsSelectModalOpen(true)
    } else if (consumableAssetGroup) {
      setConsumableAssetGroup(consumableAssetGroup)
      setIsEditModalOpen(true)
    }
  }

  const toggleSelection = (scanId: string) => {
    setScanStateMap((s) => ({
      ...s,
      [scanId]: {
        ...s[scanId],
        isSelected: !s[scanId]?.isSelected,
      },
    }))
  }

  const handleChangeFormData = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string> | any
  ) => {
    let { name, value } = e.target

    if (name === 'count' || name === 'wastedConsumablesCount') {
      if (/\D/.test(value)) return
    }

    if (
      formState.implantSite === 'Mouth' &&
      name === 'siteLocation' &&
      value.length > 4
    ) {
      return
    }

    const resetValues: Partial<DispositionData> = {}

    if (name === 'wastedConsumablesCount' && value === '0') {
      resetValues.wastedReason = undefined
    }

    if (
      name === 'implantStatus' &&
      scanToUseInForm?.assetType === 'biological' &&
      (value === 'WASTED' || value === 'EXPLANTED')
    ) {
      resetValues.packagingOk = 'NA'
      resetValues.temperatureOk = 'NA'
      resetValues.samplePreparation = 'NONE_REQUIRED'
      resetValues.samplePreparationNote = undefined
      resetValues.implantMemo = undefined
    }

    if (name === 'assetType') {
      resetValues.assetType = value
      resetValues.implantStatus = undefined
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
      if (value === 'consumable') {
        resetValues.count = scans[0]?.count || consumableAssetGroup?.total
        resetValues.wastedConsumablesCount =
          scans.length > 0 ? 0 : consumableAssetGroup?.wasted || 0
        resetValues.wastedReason = (
          scans.length > 0
            ? scans[0]?.wastedReason || undefined
            : consumableAssetGroup?.wastedReason
        ) as DispositionData['wastedReason']
      }
    }

    if (name === 'implantStatus') {
      resetValues.implantStatus = value
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
      resetValues.samplePreparationNote = null
    }

    if (name === 'implantSite') {
      resetValues.implantSite = value
      resetValues.siteLocation = undefined
      if (!isManualAddition) {
        resetValues.explantedReason = null
        resetValues.explantedReasonNote = null
      }
      resetValues.siteLocation = undefined
    }

    if (name === 'explantedReason') {
      resetValues.explantedReason = value
      resetValues.explantedReasonNote = null
    }

    if (name === 'wastedReason') {
      resetValues.wastedReason = value
    }

    if (name === 'samplePreparation') {
      resetValues.samplePreparation = value
      resetValues.samplePreparationNote = null
    }

    if (name === 'samplePreparationNote' && value === '') {
      value = null
    }

    if (name === 'implantMemo' && value === '') {
      value = null
    }

    setFormState((prev: Partial<DispositionData>) => {
      return {
        ...prev,
        ...resetValues,
        [name]: value,
      }
    })
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (e) {
      e.preventDefault()

      const testFormState = {
        ...formState,
        count: String(formState.count),
        wastedConsumablesCount: String(formState.wastedConsumablesCount),
      }
      const result = dispositionSchema.safeParse(testFormState)

      if (!result.success) {
        setValidationErrors(getZodError(result.error))
        return
      }

      const deviceId = scanToUseInForm?.deviceId
      const scanId = scanToUseInForm?._id || consumableAssetGroup?.scans[0]._id

      const scanObject = {
        deviceId: deviceId,
        assetGroupId: assetGroupId || consumableAssetGroup?._id,
        scanId: scanId,
        isMultipack: isMultipack || isMultipackHardware,
      }

      if (scanObject) {
        submitDisposition(
          result.data,
          scanObject as EditDocumentedScanInput,
          isSponge
        ).then((result) => {
          if (result) {
            const promises = []
            if (toAddMediaFiles.length > 0) {
              promises.push(uploadImages(toAddMediaFiles))
            }
            if (toDeleteMediaFiles.length > 0) {
              promises.push(deleteImages(toDeleteMediaFiles))
            }
            if (promises.length === 0) {
              refetchSurgery()
              handleReset()
              setIsEditModalOpen(false)
              setIsMediaActionTriggered(true)
              enqueueSnackbar('Product Edited Successfully', {
                variant: 'success',
              })
            } else {
              Promise.all(promises).then((results) => {
                let uploadSuccess = true
                let deleteSuccess = true
                if (
                  toAddMediaFiles.length > 0 &&
                  toDeleteMediaFiles.length > 0
                ) {
                  uploadSuccess = results[0]
                  deleteSuccess = results[1]
                } else if (toAddMediaFiles.length > 0) {
                  uploadSuccess = results[0]
                } else if (toDeleteMediaFiles.length > 0) {
                  deleteSuccess = results[0]
                }
                if (uploadSuccess && deleteSuccess) {
                  refetchSurgery()
                  handleReset()
                  setIsEditModalOpen(false)
                  setIsMediaActionTriggered(true)
                  enqueueSnackbar('Product Edited Successfully', {
                    variant: 'success',
                  })
                } else {
                  enqueueSnackbar('Error saving / deleting images', {
                    variant: 'error',
                  })
                }
              })
            }
          } else {
            enqueueSnackbar('Error submitting disposition', {
              variant: 'error',
            })
          }
        })
      }
    }
  }

  // Lifecycle methods
  useEffect(() => {
    setValidationErrors({})
  }, [formState])

  const handleOnAddMedia = (mediaFiles: MediaFile[]) => {
    setToAddMediaFiles((prev) => [...prev, ...mediaFiles])
  }

  const handleOnDeleteMedia = (fileName: string) => {
    if (toAddMediaFiles.some((file) => file.filename === fileName)) {
      setToAddMediaFiles((prev) =>
        prev.filter((file) => file.filename !== fileName)
      )
    } else {
      setToDeleteMediaFiles((prev) => [...prev, fileName])
    }
  }

  const uploadImages = async (mediaFiles: MediaFile[]): Promise<boolean> => {
    let success = true
    try {
      const response = await uploadFiles({
        variables: {
          files: mediaFiles.map((file) => {
            const { action, ...rest } = file
            return rest
          }),
          assetId: selectedAssetId,
        },
      })

      const uploadedFiles = response.data?.uploadAssetMedia?.files ?? []
      if (uploadedFiles.length === 0) {
        enqueueSnackbar(
          'Something went wrong while uploading images. Please try again.',
          {
            variant: 'error',
          }
        )
        success = false
      }
    } catch (error) {
      enqueueSnackbar(
        'Something went wrong while uploading images. Please try again.',
        {
          variant: 'error',
        }
      )
      success = false
    }
    return success
  }

  const deleteImages = async (fileNames: string[]): Promise<boolean> => {
    let success = true

    try {
      await deleteFiles({
        variables: {
          assetId: selectedAssetId,
          filenames: fileNames,
        },
      })
    } catch (error) {
      success = false
      enqueueSnackbar(
        'Something went wrong while deleting images. Please try again.',
        {
          variant: 'error',
        }
      )
    }
    return success
  }

  const autoFocus = {
    implantSite: false,
    wastedReason: false,
    implantedReason: false,
    implantedReasonNote: false,
    explantedReason: false,
    siteLocation: false,
    implantStatus: false,
    assetType: false,
    samplePreparation: false,
    samplePreparationNote: false,
  }

  if (!formState.assetType) {
    autoFocus.assetType = true
  } else if (!formState.implantStatus) {
    autoFocus.implantStatus = true
  } else if (
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    !formState.implantSite
  ) {
    autoFocus.implantSite = true
    autoFocus.implantStatus = false
  } else if (formState.implantStatus === 'WASTED' && !formState.wastedReason) {
    autoFocus.wastedReason = true
  } else if (
    formState.implantSite &&
    (formState.implantSite === 'Mouth' || formState.implantSite === 'Other') &&
    !formState.siteLocation
  ) {
    autoFocus.siteLocation = true
    autoFocus.implantSite = false
  } else if (
    formState.implantStatus === 'EXPLANTED' &&
    formState.implantSite &&
    !formState.explantedReason
  ) {
    autoFocus.explantedReason = true
  } else if (
    formState.implantStatus === 'EXPLANTED' &&
    formState.implantSite &&
    (formState.siteLocation === 'Mouth' ||
      formState.siteLocation === 'Other') &&
    !formState.explantedReason
  ) {
    autoFocus.explantedReason = true
  } else if (
    formState.implantStatus === 'IMPLANTED' &&
    !(formState.implantSite === 'Mouth' || formState.implantSite === 'Other') &&
    !formState.samplePreparation
  ) {
    autoFocus.samplePreparation = true
    autoFocus.siteLocation = false
  } else if (
    formState.samplePreparation &&
    formState.samplePreparation === 'OTHER' &&
    !formState.samplePreparationNote
  ) {
    autoFocus.samplePreparation = false
    autoFocus.samplePreparationNote = true
    autoFocus.siteLocation = false
    autoFocus.implantSite = false
    autoFocus.implantStatus = false
    autoFocus.assetType = false
  } else {
    autoFocus.samplePreparationNote = false
    autoFocus.samplePreparation = false
    autoFocus.siteLocation = false
    autoFocus.implantSite = false
    autoFocus.implantStatus = false
    autoFocus.assetType = false
  }

  useEffect(() => {
    if (autoFocus.samplePreparationNote && samplePrepNoteRef.current) {
      setTimeout(() => {
        samplePrepNoteRef.current?.focus()
      }, 100)
    }
  }, [autoFocus.samplePreparationNote])

  return {
    handleEditOnOpen,
    toggleSelection,
    isSelectModalOpen,
    setIsEditModalOpen,
    setIsSelectModalOpen,
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    isSavingEditedAsset,
    renderImplantSites,
    isConsumable,
    setScanStateMap,
    isEditModalOpen,
    handleReset,
    scanStateMap,
    scans,
    selectedAssetGroupId: assetGroupId || consumableAssetGroup?._id || '',
    scanIsMatching,
    isSiteLocationEnabled,
    selectedAssetId,
    consumableAssetGroup,
    isLoading,
    areInputsUnchanged,
    implantStatusLabel,
    wastedReasonLabel,
    explantedReasonLabel,
    isMediaActionTriggered,
    setIsMediaActionTriggered,
    validationErrors,
    isBiological,
    handleOnAddMedia,
    handleOnDeleteMedia,
    isLoadingMedia,
    autoFocus,
    samplePrepNoteRef,
  }
}
