// Dayjs
import dayjs from 'lib/dayjs'

// Types
import { AssetData, Patient, Scan } from 'common/types'

// PDF
import { Page, Document, View, Text } from '@react-pdf/renderer'

// Utils
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'

// Components
import { HeadCell } from 'components/molecules/DataTable/TableHeader'

// Types
import {
  AssetDetailsRowsData,
  SurgeryDetailsOutput,
} from '../PatientReport.types'
import { COLUMN_WIDTHS, HEAD_CELLS } from './constants'

// Styles
import { tableStyles, cardStyles, mainStyles } from './styles'

const getColumnStyle = (columnId: string) => ({
  ...tableStyles.cell,
  ...(COLUMN_WIDTHS[columnId]
    ? {
        width: COLUMN_WIDTHS[columnId],
        marginRight: 8,
      }
    : { ...tableStyles.cellDefault, marginRight: 8 }),
})

const ProcedureDetailsReportPDF = ({
  surgeryDetails,
}: {
  surgeryDetails: SurgeryDetailsOutput
}) => {
  const dateFormat = 'MMM DD, YYYY'

  const procedureDate: SurgeryDetailsOutput['visitDate'] = dayjs(
    surgeryDetails?.visitDate
  ).format('hh:mm A , MMM DD, YYYY')
  const caseNumber: SurgeryDetailsOutput['surgicalCase']['number'] =
    surgeryDetails?.surgicalCase?.number
  const procedureType: SurgeryDetailsOutput['procedureType'] =
    surgeryDetails?.procedureType
  const surgeon: SurgeryDetailsOutput['surgeon']['lastName'] =
    surgeryDetails?.surgeon?.lastName

  const patientName = `${
    surgeryDetails?.patient?.lastName === undefined
      ? ''
      : surgeryDetails?.patient?.lastName
  }, ${
    surgeryDetails?.patient?.firstName === undefined
      ? ''
      : surgeryDetails?.patient?.firstName
  }`
  const patientDOB: Patient['dateOfBirth'] = dayjs(
    surgeryDetails?.patient?.dateOfBirth
  ).format(dateFormat)
  const patientMRN: Patient['idMR'] = surgeryDetails?.patient?.idMR

  const formatLongNumbers = (udi: string) => {
    if (!udi || udi === '-') return '-'

    // If UDI contains parentheses, format them with a new line
    let formattedUDI = udi.replace(/(\(\d+\))/g, '$1\n')

    // Break UDI into new lines every 15 characters
    return formattedUDI.replace(/(.{18})/g, '$1\n')
  }

  const mappedData: AssetDetailsRowsData[] =
    surgeryDetails?.assetGroups?.flatMap((assetGroup: AssetData) =>
      assetGroup.scans.map((scan: Scan) => ({
        serialNumber: formatLongNumbers(scan.serialNumber || '-'),
        catalogNumber: formatLongNumbers(scan.catalogNumber || '-'),
        udi: formatLongNumbers(scan.udi || '-'),
        lotNumber: formatLongNumbers(scan.lotBatch || '-'),
        deviceDescription: scan.deviceDescription || '-',
        companyName: scan.companyName,
        quantity: scan.count,
        assetType: getAssetTypeLabel(scan.assetType),
        implantSite: scan.implantSite || '-',
      }))
    )

  const chunkArrayWithDifferentSizes = (
    array: AssetDetailsRowsData[]
  ): AssetDetailsRowsData[][] => {
    return array.reduce(
      (resultArray: AssetDetailsRowsData[][], item, index) => {
        // For the first 7 items, put them in the first chunk
        if (index < 7) {
          if (!resultArray[0]) resultArray[0] = []
          resultArray[0].push(item)
        } else {
          // For remaining items, create chunks of 10
          const chunkIndex = Math.floor((index - 7) / 10) + 1
          if (!resultArray[chunkIndex]) resultArray[chunkIndex] = []
          resultArray[chunkIndex].push(item)
        }
        return resultArray
      },
      []
    )
  }

  const TableChunk = ({
    headers,
    rows,
  }: {
    headers: HeadCell[]
    rows: AssetDetailsRowsData[]
  }) => (
    <View style={tableStyles.container}>
      <View style={tableStyles.headerRow}>
        {headers.map((header) => (
          <Text key={header.id} style={getColumnStyle(header.id as string)}>
            {header.label}
          </Text>
        ))}
      </View>

      {rows.map((row, index) => (
        <View
          key={index}
          style={index % 2 === 0 ? tableStyles.rowEven : tableStyles.rowOdd}
        >
          {headers.map((header) => {
            const cellValue = row[header.id as keyof typeof row] ?? '-'
            return (
              <Text key={header.id} style={getColumnStyle(header.id as string)}>
                {String(cellValue)}
              </Text>
            )
          })}
        </View>
      ))}
    </View>
  )

  const DetailRow = ({
    label,
    value,
  }: {
    label: string
    value: string | number
  }) => (
    <View style={cardStyles.row}>
      <Text style={cardStyles.label}>{label}:</Text>
      <Text style={cardStyles.value}>{value}</Text>
    </View>
  )

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={mainStyles.page}>
        <View style={mainStyles.headerContainer}>
          <Text style={mainStyles.headerTitle}>Procedure Implant Record</Text>
          <View style={mainStyles.headerCaseWrapper}>
            <Text style={mainStyles.headerCaseLabel}>Case Number: </Text>
            <Text style={mainStyles.headerCaseValue}>#{caseNumber}</Text>
          </View>
        </View>

        <View style={mainStyles.detailsGrid}>
          <View style={mainStyles.procedureDetailsSection}>
            <View style={cardStyles.container}>
              <Text style={cardStyles.title}>Procedure Details</Text>
              <DetailRow label="Procedure Date" value={procedureDate} />
              <DetailRow label="Procedure Name" value={procedureType || '-'} />
              Procedure Details
              <DetailRow label="Surgeon" value={surgeon} />
            </View>
          </View>

          <View style={mainStyles.patientDetailsSection}>
            <View style={cardStyles.container}>
              <Text style={cardStyles.title}>Patient Details</Text>
              <DetailRow
                label="Patient MRN"
                value={`#${patientMRN || 'XXXXXX'}`}
              />
              <DetailRow label="Patient Name" value={patientName} />
              <DetailRow label="Date of Birth" value={patientDOB} />
            </View>
          </View>
        </View>

        <View>
          {chunkArrayWithDifferentSizes(mappedData)?.map((chunk, index) => (
            <TableChunk
              key={index}
              headers={HEAD_CELLS}
              rows={chunk as AssetDetailsRowsData[]}
            />
          ))}
        </View>
      </Page>
    </Document>
  )
}

export default ProcedureDetailsReportPDF
