import { EXPLANTED_REASON_LIST } from 'common/disposition'
import { facility } from 'common/facilityMigration'

const { assetTypes, wastedReasons } = facility

const samplePreparationTypeOptions = [
  { id: 'NONE_REQUIRED', name: 'None Required' },
  {
    id: 'PER_MANUFACTURERS_GUIDELINES',
    name: 'Per Manufacturers Guidelines',
  },
  { id: 'OTHER', name: 'Other' },
]

const finalDispositionCaster = (value: string) => {
  if (value === 'IMPLANTED') return 'Implanted'
  if (value === 'TEMPORARY_IN_OUT') return 'Temporary In/Out'
  if (value === 'WASTED') return 'Wasted'
  if (value === 'EXPLANTED') return 'Explanted'
  if (value === 'UNUSED') return 'Unused Product'
  if (value === 'ASSOCIATED_ASSET') return 'Utilized associated product'
  if (value === 'NOT_PRESENT') return 'Not Present'
}

const samplePreparationCaster = (value: string) => {
  if (value === 'NONE_REQUIRED') return 'None Required'
  if (value === 'PER_MANUFACTURERS_GUIDELINES')
    return 'Per Manufacturers Guidelines'
  if (value === 'OTHER') return 'Other'
}

const wastedReasonCaster = (value: string) =>
  wastedReasons.find((reason) => reason.value === value)?.label

const explantedReasonCaster = (value: string) =>
  EXPLANTED_REASON_LIST.find((reason) => reason.id === value)?.name

export {
  assetTypes,
  wastedReasons,
  samplePreparationTypeOptions,
  finalDispositionCaster,
  samplePreparationCaster,
  wastedReasonCaster,
  explantedReasonCaster,
}
