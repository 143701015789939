import { AssetType } from 'common/disposition'
import {
  AssetIdType,
  BetterIdAsset,
  Scan,
  UndocumentedScan,
} from 'common/types'

export type TParseAssetIdentifiersArgs = {
  deviceDescription?:
    | Scan['deviceDescription']
    | UndocumentedScan['deviceDescription']
  deviceCount: Scan['count'] | UndocumentedScan['count']
  idType?: AssetIdType | null
  assetType: AssetType | null | string
  gmdnPTDefinition?: BetterIdAsset['gmdnPTDefinition']
}

export const SPONGE_DESCRIPTORS = ['sponge', 'sponges']

export const RF_CONSUMABLE_DESCRIPTORS = [
  'rf',
  'x-ray',
  'xray',
  'detectable',
  'radiofrequency',
]

export const ASSOCIATED_PRODUCT_DESCRIPTORS = [
  'cutting guide',
  'drill bit',
  'tap',
  'model',
  'screw driver',
  'splint',
  'battery',
  'j notch',
  'hex',
  'twist',
  'marking guide',
  'drill',
  'reusable',
  'guide wire',
  'reamer',
  'template',
  'guide',
  'trial',
  'trails',
]

export const NON_ASSOCIATED_DESCRIPTIONS = ['drill free', 'implant']
