import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { AssetType } from 'common/disposition'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { isDatePast } from 'lib/utils/isDatePast'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import './AssetDetailsCard.scss'
import classNames from 'classnames'
import {
  AssetDetailsList,
  AssetDetailsProps,
  TRecordSenderAssetDetails,
} from './AssetDetailsCard.types'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useUser } from 'app/User'
import { useGetRepsLazyQuery } from 'lib/apollo/hooks'
import { ShareProduct } from 'components/ShareProduct/ShareProduct'

export const AssetDetailsCard = ({
  startComponent,
  endComponent,
  children,
  assetDetails,
  isExpireAccepted = false,
  assetDeleteLoading,
  assetType,
  isExplanted = false,
  renderButton = false,
  renderUsedCounts = false,
  isDisabled,
  dataTestId,
  isDTMScrew,
  dtmScrewData,
  isProcedureSubmitted,
  printMode,
  isAutopopulated,
  assetId,
  hasAddMissingFields = false,
  assetSelectHandle,
  handleAddMissingFields,
  authorizedReps,
}: AssetDetailsProps) => {
  const flags = useFlags()
  const { pathname } = useLocation()
  const isMobile = useIsMobile()
  const { user } = useUser()
  const { isRep, isNurseAdmin, isNurse } = useUser()
  const isRepAuthorized = user?.id ? authorizedReps?.includes(user.id) : false
  const showAddMissingFields = isNurseAdmin || isNurse || isRepAuthorized
  const repCanEdit = flags?.repCanEdit ?? false
  const disallowSelection = isRep && !repCanEdit ? true : false

  const [showShareProduct, setShowShareProduct] = useState(false)

  const filteredAssetDetails: TRecordSenderAssetDetails = {
    assetId: assetId as string,
    assetManufacturer: assetDetails?.assetManufacturer?.value || '',
    assetTitle: assetDetails?.assetTitle?.value || '',
    deviceId: assetDetails?.deviceId?.value || '',
    lotNumber: assetDetails?.lotNumber?.value || '',
    modelNumber: assetDetails?.modelNumber?.value || '',
    expirationDate: assetDetails?.expirationDate?.value as string,
    deviceCount: assetDetails?.deviceCount?.value || 0,
    usedCount: assetDetails?.usedCount?.value || 0,
    isExplanted,
  }

  const setShowShareProductButton =
    useLocation().pathname.endsWith('list') &&
    isProcedureSubmitted &&
    flags.shareImplantableOthersWithRep &&
    !isRep &&
    (assetType === 'other-non-biological' || isExplanted)

  const [getReps, { data, loading, error }] = useGetRepsLazyQuery({
    bidCompanyId: assetDetails.bidCompanyId?.value as number,
  })
  const reps = loading ? [] : data?.getReps?.data || []

  const {
    isMultipackAsset,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackHardware,
  } = parseAssetIdentifiers({
    deviceDescription: assetDetails.assetTitle.value,
    deviceCount: assetDetails.deviceCount.value,
    assetType: assetType,
  })

  const DISPLAY_FIELD_MAP: Partial<Record<keyof AssetDetailsList, string>> = {
    assetTypeLabel: 'Type',
    assetManufacturer: 'Manufacturer',
    deviceId: 'Device ID',
    assetTrayId: 'Inventory Location ID',
    modelNumber: 'Model Number',
    catalogNumber: 'Catalog Number',
    lotNumber: 'Lot Number',
    serialNumber: 'Serial Number',
    expirationDate: 'Expiration Date',
    deviceCount: 'Device Count',
    usedCount:
      isRfMultipackConsumable || isSpongeConsumable || isMultipackHardware
        ? 'Multipacks Used'
        : 'Used Count',
  }

  const editableFields =
    assetType === 'biological'
      ? ['lotNumber', 'serialNumber', 'expirationDate']
      : ['expirationDate']

  const missingFields = Object.entries(DISPLAY_FIELD_MAP).reduce<string[]>(
    (acc, [key]) => {
      if (
        editableFields.includes(key) &&
        assetDetails[key as keyof AssetDetailsList]?.value === null
      ) {
        acc.push(key)
      }
      return acc
    },
    []
  )

  function handleFieldDisplay(
    assetType: AssetType,
    isDTMScrew: boolean = false
  ) {
    const commonFields = [
      'assetTitle',
      'assetManufacturer',
      'deviceId',
      'assetTrayId',
      'catalogNumber',
      'modelNumber',
    ]

    if (!isDTMScrew) {
      commonFields.push('expirationDate')
    }

    let additionalFields: string[] = []

    if (!isDTMScrew) {
      additionalFields =
        assetType === 'biological'
          ? ['modelNumber', 'catalogNumber', 'lotNumber', 'serialNumber']
          : []
      if (isMultipackAsset) {
        additionalFields.push('deviceCount')
      }
      if (renderUsedCounts) {
        additionalFields.push('usedCount')
      }
    }

    return commonFields.concat(additionalFields)
  }

  const { isDateExpired, expDate } = useMemo(() => {
    const expirationDateValue = assetDetails['expirationDate'].value
    return isDatePast({ date: expirationDateValue })
  }, [assetDetails])
  assetDetails['expirationDate'].value = expDate

  const assetDetailComponent = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexGrow="1"
    >
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          wordBreak: 'break-word',
          maxWidth: '360px',
        }}
        color="text.primary"
        align="left"
      >
        {assetDetails.assetSizeText.value
          ? `${
              assetDetails?.assetTitle?.value?.includes(
                assetDetails.assetSizeText.value
              )
                ? assetDetails.assetTitle.value
                : `${assetDetails.assetTitle.value} ${assetDetails.assetSizeText.value}`
            }`
          : `${assetDetails.assetTitle.value}`}
      </Typography>

      {assetDetails.implantSite && (
        <Typography
          variant="h6"
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            maxWidth: '360px',
            textDecoration: 'underline',
          }}
          color="text.primary"
          align="left"
        >
          {`Existing Implant Site: ${assetDetails.implantSite.value}`}
        </Typography>
      )}
      
      {assetDetails.siteLocation && (
        <Typography
          variant="h6"
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            maxWidth: '360px',
            textDecoration: 'underline',
          }}
          color="text.primary"
          align="left"
        >
          {`Site Location: ${assetDetails.siteLocation.value}`}
        </Typography>
      )}

      {Object.entries(DISPLAY_FIELD_MAP).map(([key, value]) => {
        const fieldsToDisplay = handleFieldDisplay(assetType, isDTMScrew)

        if (isExplanted && assetDetails['assetTypeLabel'].value) {
          fieldsToDisplay.unshift('assetTypeLabel')
        }

        if (key === 'assetTrayId' && assetDetails[key].value === null) {
          return null
        }

        if (
          isAutopopulated &&
          (!assetDetails[key as keyof AssetDetailsList]?.value ||
            key === 'expirationDate')
        ) {
          return null
        }

        if (fieldsToDisplay?.includes(key)) {
          return (
            <Box display="flex" flexDirection="row" flexGrow="1" key={key}>
              <Typography
                key={key}
                variant="body2"
                fontSize={12}
                color="text.secondary"
                sx={{
                  mr: 0.5,
                }}
              >
                {value}:
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                component="span"
                color={
                  key === 'expirationDate' && isDateExpired
                    ? isExpireAccepted
                      ? 'orange'
                      : 'red'
                    : 'text.secondary'
                }
              >
                {assetDetails[key as keyof AssetDetailsList]?.value ||
                  `${value} not found`}
              </Typography>
            </Box>
          )
        }

        return null
      })}

      {isDTMScrew && dtmScrewData && (
        <>
          {dtmScrewData.label && (
            <Box display="flex" flexDirection="row" flexGrow="1">
              <Typography
                variant="body2"
                fontSize={12}
                color="text.secondary"
                sx={{
                  mr: 0.5,
                }}
              >
                Location:
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                component="span"
                color="text.secondary"
              >
                {dtmScrewData?.label}, row: {dtmScrewData?.row}, size:{' '}
                {dtmScrewData?.size}
              </Typography>
            </Box>
          )}

          {!pathname.includes('list') && (
            <Stack
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              sx={{ borderTop: '1px solid #D5D5D5', mt: '15px', pt: '10px' }}
            >
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#000000"
                textAlign="left"
              >
                {dtmScrewData?.wasted ? 'Wasted' : 'Implanted'}
              </Typography>
            </Stack>
          )}
        </>
      )}

      {hasAddMissingFields &&
      missingFields.length > 0 &&
      showAddMissingFields ? (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleAddMissingFields?.(missingFields, assetId)}
        >
          Add missing information
        </Button>
      ) : null}
    </Box>
  )

  return (
    <>
      <ButtonBase
        aria-label="asset-details-card-grid-container"
        className={classNames('asset-details-card-container', {
          'is-mobile': isMobile,
          'has-reps': showShareProduct,
        })}
        style={{
          cursor: disallowSelection
            ? 'auto'
            : !renderButton || isDisabled
            ? 'auto'
            : 'pointer',
        }}
        onClick={() => {
          if (!disallowSelection && !(assetDeleteLoading || isDisabled)) {
            assetSelectHandle && assetSelectHandle()
          }
        }}
      >
        <Box
          aria-label="asset-details-card-box-row"
          className="asset-details-card-box-row"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { sx: 'flex-start', sm: 'center' },
          }}
        >
          <div className="left-side">
            {!assetDetails.implantSite && startComponent && (
              <div>{startComponent}</div>
            )}

            <div aria-label="asset-details-card-details-grid-item">
              {renderButton ? (
                <div data-testid={`${dataTestId}-button`}>
                  {assetDetailComponent}
                </div>
              ) : (
                assetDetailComponent
              )}
            </div>
          </div>

          {endComponent && <div>{endComponent}</div>}

          {!printMode && setShowShareProductButton && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Button
                onClick={() => {
                  setShowShareProduct((prev) => {
                    if (prev === false) {
                      getReps()
                    }
                    return !prev
                  })
                }}
              >
                Share
              </Button>
            </div>
          )}
        </Box>

        {children && (
          <div className="asset-card-children">
            <Divider variant="middle" sx={{ m: 1 }} />
            <div>{children}</div>
          </div>
        )}
      </ButtonBase>

      {!printMode && showShareProduct && (
        <ShareProduct
          assetDetails={filteredAssetDetails}
          error={!!error}
          loading={loading}
          reps={reps}
        />
      )}
    </>
  )
}
