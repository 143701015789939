import { Mail, Sms } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import AlertDialog from 'components/AlertDialog/AlertDialog'
import { TSenderButtonProps } from './SenderButton.types'
import { FC } from 'react'
import { useSenderButtonLogic } from './SenderButton.logic'

const SenderButton: FC<TSenderButtonProps> = (props) => {
  const { isSms, disabled } = props

  const {
    loading,
    isErrorDialogOpen,
    isAlreadySentAlertOpen,
    handleSendToRep,
    setErrorDialogOpen,
    setIsAlreadySentAlertOpen,
  } = useSenderButtonLogic(props)

  const sharedAlertProps = {
    primaryButtonAction: handleSendToRep,
    isPrimaryLoading: loading,
    isPrimaryDisabled: disabled,
  }

  return (
    <>
      <AlertDialog
        {...sharedAlertProps}
        isOpen={isErrorDialogOpen}
        mode="error"
        title="Failed to send product"
        description={`There was an error when attempting to send the record to the rep via ${
          isSms ? 'sms' : 'email'
        }. Please try again later.`}
        position={{
          bottom: '40%',
        }}
        positionAboveBottomNavigation
        primaryButtonText="Retry Send"
        secondaryButtonAction={() => setErrorDialogOpen(false)}
        secondaryButtonText="Cancel"
        zIndex={6}
      />

      <AlertDialog
        {...sharedAlertProps}
        isOpen={isAlreadySentAlertOpen}
        mode="primary"
        title={isSms ? 'Sms Already Sent' : 'Email Already Sent'}
        description="This product has already been sent to the rep. Are you sure you want to send it again?"
        primaryButtonText="Send Again"
        secondaryButtonAction={() => setIsAlreadySentAlertOpen(false)}
        zIndex={7}
      />

      <IconButton
        onClick={handleSendToRep}
        disabled={disabled || loading}
        color="primary"
        size="small"
      >
        {loading ? <CircularProgress size={20} /> : isSms ? <Sms /> : <Mail />}
      </IconButton>
    </>
  )
}

export default SenderButton
